import { createContext } from 'react'

const SocketContext = createContext({
  markets: [],
  klines: {
    symbol: '',
    data: [],
  },
  allKlines: {},
  orderBooks: [],
  trades: {},
  markupId: null,
})

export default SocketContext
