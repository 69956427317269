import * as Yup from 'yup'

export const EmailLoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      // regex pattern
      /^(?=.*[a-z])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Password length must be 8, at least one uppercase letter, one lowercase letter, one special character and one number',
    ),
})
