import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Activity.module.sass'
import Icon from '../../components/Icon'
import Actions from '../../components/Actions'
import Calendar from '../../components/Calendar'
import Export from '../../components/Export'
import Dropdown from '../../components/Dropdown'
import Withdrawel from './Withdrawel'
import Deposit from './Deposit'
import Transfer from './Transfer'
import Orders from './Orders'
import Converts from './Converts'
import AllTransaction from './AllTransaction'

const navigation = [
  'All type',
  'Withdrawals',
  'Deposit',
  'Orders',
  'Converts',
  'Transfer',
]


const Activity = () => {
  const [email, setEmail] = useState('')
  const [activeIndex, setActiveIndex] = useState(0)

  const [sorting, setSorting] = useState(navigation[0])

  const handleSubmit = () => {
    alert()
  }

  return (
    <div className={styles.activity}>
      <div className={cn('container', styles.container)}>
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={styles.nav}>
              {navigation.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={navigation}
              />
            </div>
            <form
              className={styles.form}
              action=""
              onSubmit={() => handleSubmit()}
            >
              <input
                className={styles.input}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="search"
                placeholder="Search"
                required
              />
              <button className={styles.result}>
                <Icon name="search" size="20" />
              </button>
            </form>
            <Calendar className={styles.calendar} />
          </div>
          <div className={styles.line}>
            <h4 className={cn('h4', styles.title)}>Activity</h4>
            <Export className={styles.export} />
          </div>


{
  activeIndex === 0 && <AllTransaction/>
}
{
  activeIndex === 1 && <Withdrawel/>
}  
{
  activeIndex === 2 && <Deposit/>
}
{
  activeIndex === 3 && <Orders/>
}
{
  activeIndex === 4 && <Converts/>
}
{
  activeIndex === 5 && <Transfer/>
}

        </div>
        <Actions className={styles.actions} />
      </div>
    </div>
  )
}

export default Activity
