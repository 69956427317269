import React, { useState } from 'react'
import cn from 'classnames'
import styles from '../Kyc.module.sass'
import Dropdown from '../../../components/Dropdown'
import TextInput from '../../../components/TextInput'
import img from '../../../../src/assets/crypto/Upload.png'
import Breadcrumbs from 'components/Breadcrumbs'

const optionsCategory = []

const UploadKyc = () => {
  const [category, setCategory] = useState(optionsCategory[0])

  const [selectedFile, setSelectedFile] = useState(null)

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    setSelectedFile(file)

    // Display the image preview
    const reader = new FileReader()
    reader.onload = function (e) {
      document.getElementById('file-image').src = e.target.result
    }
    reader.readAsDataURL(file)
  }

  const breadcrumbs = [
    {
      title: 'Kyc',
      url: '/kyc',
    },
    {
      title: 'KYC Upload',
    },
  ]
  return (
    <>
      <div className={cn('section-bg section-mb0', styles.section)}>
      <div style={{ marginLeft:'50px' }}>
<Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
</div>
        <div style={{  width: '100%', marginTop:'80px' }}  className={styles.variant}>
          <div style={{  marginTop: '80px', }} className={styles.cards}>
            <span className={styles.inner_pending} style={{ width: '300px' }}>
              <div
                className={styles.icon}
                style={{ backgroundColor: '#9757D7' }}
              >
                <h3>1</h3>

                {/* <div className={styles.number}>
                        <h3>2</h3>
                      </div> */}
                {/* <Icon name="candlesticks" size="20" /> */}
              </div>
              <span className={styles.text}>CO Unverified</span>
            </span>

            <span
              className={styles.inner}
              style={{ width: '300px', marginTop: '50px' }}
            >
              <div
                className={styles.icon}
                style={{ backgroundColor: '#9757D7' }}
              >
                <h3>2</h3>

                {/* <div className={styles.number}>
                        <h3>2</h3>
                      </div> */}
                {/* <Icon name="candlesticks" size="20" /> */}
              </div>
              <span className={styles.text}>C1 Basic Verification</span>
            </span>

            <span
              className={styles.inner}
              style={{ width: '300px', marginTop: '50px' }}
            >
              <div
                className={styles.icon}
                style={{ backgroundColor: '#9757D7' }}
              >
                <h3>3</h3>

                {/* <div className={styles.number}>
                        <h3>2</h3>
                      </div> */}
                {/* <Icon name="candlesticks" size="20" /> */}
              </div>
              <span className={styles.text}>C2 Primary Verification</span>
            </span>

            <span
              className={styles.inner}
              style={{ width: '300px', marginTop: '50px' }}
            >
              <div
                className={styles.icon}
                style={{ backgroundColor: '#9757D7' }}
              >
                <h3>4</h3>

                {/* <div className={styles.number}>
                        <h3>2</h3>
                      </div> */}
                {/* <Icon name="candlesticks" size="20" /> */}
              </div>
              <span className={styles.text}>C3 Advanced Verification</span>{' '}
            </span>
          </div>
          <div  className={styles.cards} style={{ width:'1200px' }}>
            <form className={styles.form} action="" style={{ width: '100%' }} >
              <div className={styles.fieldset}>
                <div className={styles.field}>
                  <div className={styles.label}>Basic Verification (KYC)</div>
                </div>

                <div className={styles.field}>
                  <Dropdown
                    className={styles.dropdown}
                    label="Nationality or region"
                    value={category}
                    setValue={setCategory}
                    options={optionsCategory}
                  />
                </div>
                <TextInput
                  className={styles.field}
                  label="First Name"
                  name="subject"
                  type="text"
                  required
                />
                <TextInput
                  className={styles.field}
                  label="Last Name"
                  name="subject"
                  type="text"
                  required
                />
                <TextInput
                  className={styles.field}
                  label="ID Type"
                  name="subject"
                  type="text"
                  required
                />
                <TextInput
                  className={styles.field}
                  label="Id Number"
                  name="subject"
                  type="text"
                  required
                />
                <TextInput
                  className={styles.field}
                  label="Id Number"
                  name="subject"
                  type="date"
                  required
                />
                <div className={styles.btns}>
                  <button className={cn('button-small', styles.button)}>
                    Submit For Verification
                  </button>
                </div>
              </div>
              <div className={styles.imgContainer} style={{ width: '30%' }}>
                  {/* Upload */}
                  <div id="file-upload-form" className={styles.uploader}>
                    <input
                      id="file-upload"
                      type="file"
                      name="fileUpload"
                      accept="image/*"
                      onChange={handleFileChange}
                    />

                    <label
                      htmlFor="file-upload"
                      style={{ marginBottom: '10px' }}
                    >
                      <img
                        id="file-image"
                        src={img}
                        alt="Preview"
                        className={styles.hidden}
                        style={{
                          width: '50px',
                          height: '50px',
                          marginTop: '35px',
                        }}
                      />
                      <div></div>
                    </label>
                    <h6 style={{ fontSize: '11px', color: ' #B1B5C3' }}>
                  Please click to upload a photo of your ID with your ID number
                </h6>
                <h6
                  style={{
                    fontSize: '10px',
                    paddingTop: '7px',
                    color: ' #B1B5C3',
                  }}
                >
                  Please upload images in PNG or JPG formats, and ensure the
                  file size is less than 5MB
                </h6>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default UploadKyc
