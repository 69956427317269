import {
  ChevronDown,
  ChevronUp,
} from 'feather-icons-react/build/IconComponents'
import styles from './Action.module.sass'
import { useState } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

const TPSLPrice = ({ label, isMarket, marketLabel }) => {
  const { t } = useTranslation()
  const [market, setMarket] = useState(false)
  const [price, setPrice] = useState()

  const handleChangePriceIncreament = () => {
    const oldPrice = price ?? 0
    setPrice(oldPrice + 0.1)
  }

  const handleChangePriceDecreament = () => {
    const oldPrice = price ?? 0
    setPrice(oldPrice - 0.1)
  }

  return (
    <>
      <label
        className={cn('fieldNumber', styles.fieldNumber)}
        onClick={() => setMarket(false)}
      >
        <div className={styles.justifyDiv}>
          <div className={cn('label', styles.label)}>{t(label)}</div>
          {!market && (
            <input
              className={styles.input}
              type="number"
              name="total"
              value={price?.toFixed(1)}
            />
          )}
          <div className={styles.currency}>
            {market ? t(marketLabel) : t('USDT')}
          </div>
        </div>
        <div className={cn('arrowButtons', styles.arrowButtons)}>
          <button
            type="button"
            className={cn('arrowUpButton', styles.arrowUpButton)}
            onClick={handleChangePriceIncreament}
          >
            <ChevronUp size="20" />
          </button>
          <button
            type="button"
            className={cn('arrowDownButton', styles.arrowDownButton)}
            onClick={handleChangePriceDecreament}
          >
            <ChevronDown size="20" />
          </button>
        </div>
      </label>
      {isMarket && (
        <label
          className={market ? styles.active : styles.field}
          onClick={() => setMarket(!market)}
        >
          {marketLabel}
        </label>
      )}
    </>
  )
}

export default TPSLPrice
