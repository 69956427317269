import Home from 'screens/Home'
import LearnCrypto from 'screens/LearnCrypto'
import Market from 'screens/Market'
import LearnCryptoDetails from 'screens/LearnCryptoDetails'
import Contact from 'screens/Contact'
import Notifications from 'screens/Notifications'
import Activity from 'screens/Activity'
import WalletOverview from 'screens/WalletOverview'
import WalletOverViewDetails from 'screens/WalletOverviewDetails'
import WalletMargin from 'screens/WalletMargin'
import FiatAndSpot from 'screens/FiatAndSpot'
import ProfileInfo from 'screens/ProfileInfo'
import DepositFiat from 'screens/DepositFiat'
import BuyCrypto from 'screens/BuyCrypto'
import SellCrypto from 'screens/SellCrypto'
import Referrals from 'screens/Referrals'
import SessionsAndLoginHistory from 'screens/SessionsAndLoginHistory'
import TwoFa from 'screens/TwoFa'
import PageList from 'screens/PageList'
import nonAuthRoutes from './nonAuthRoutes'
import Spot from 'screens/Exchange/Spot'
import Futures from 'screens/Exchange/Futures'
import Margin from 'screens/Exchange/Margin'
import ReferralsPrograms from 'screens/Reward/ReferralProgram'
import TaskCentre from 'screens/Reward/TaskCentre'
import Affiliate from 'screens/Reward/Affiliate'
import PrivacyPolicy from 'screens/PrivacyPolicy'
import Disclaimer from 'screens/Disclaimer'
import UserAgreement from 'screens/UserAgreement'
import AboutUs from 'screens/AboutUs'
import FutureFees from 'screens/FutureFees'
import SpotFees from 'screens/SpotFees'
import HelpCenter from 'screens/HelpCenter'
import ClientActivities from 'screens/ClientActivities'
import Kyc from 'screens/Kyc'
import UploadKyc from 'screens/Kyc/Upload Kyc/UploadKyc'

export default [
  {
    path: '/home',
    component: Home,
    isAuth: false,
    headerHide: false,
    footerHide: false,
    headerWide: true,
    stickyHeader: true,
  },
  //profile
  {
    path: '/market',
    component: Market,
    isAuth: false,
    headerHide: false,
    footerHide: false,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/learn-crypto',
    component: LearnCrypto,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/learn-crypto-details',
    component: LearnCryptoDetails,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/contact',
    component: Contact,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/notifications',
    component: Notifications,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/activity',
    component: Activity,
    isAuth: true,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/exchange',
    component: Spot,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: false,
  },
  {
    path: '/exchange/futures',
    component: Futures,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: false,
  },
  {
    path: '/exchange/margin',
    component: Margin,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: false,
  },
  {
    path: '/reward/referral-program',
    component: ReferralsPrograms,
    isAuth: false,
    headerHide: false,
    footerHide: false,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/reward/task-centre',
    component: TaskCentre,
    isAuth: false,
    headerHide: false,
    footerHide: false,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/reward/affiliate',
    component: Affiliate,
    isAuth: false,
    headerHide: false,
    footerHide: false,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/dashboard',
    component: WalletOverview,
    isAuth: true,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/wallet/:id',
    component: WalletOverViewDetails,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/wallet-margin',
    component: WalletMargin,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/fiat-and-spot',
    component: FiatAndSpot,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/profile',
    component: ProfileInfo,
    isAuth: true,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/deposit',
    component: DepositFiat,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/buy-crypto',
    component: BuyCrypto,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/sell-crypto',
    component: SellCrypto,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/referrals',
    component: Referrals,
    isAuth: true,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/referrals',
    component: Referrals,
    isAuth: true,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/kyc',
    component: Kyc,
    isAuth: true,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/kyc-upload',
    component: UploadKyc,
    isAuth: true,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/client-activities',
    component: ClientActivities,
    isAuth: true,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/sessions-and-login-history',
    component: SessionsAndLoginHistory,
    isAuth: true,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/2fa',
    component: TwoFa,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/pagelist',
    component: PageList,
    isAuth: false,
    headerHide: false,
    footerHide: true,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/privacy',
    component: PrivacyPolicy,
    isAuth: false,
    headerHide: false,
    footerHide: false,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/disclaimer',
    component: Disclaimer,
    isAuth: false,
    headerHide: false,
    footerHide: false,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/about-us',
    component: AboutUs,
    isAuth: false,
    headerHide: false,
    footerHide: false,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/help',
    component: HelpCenter,
    isAuth: false,
    headerHide: false,
    footerHide: false,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/user-agreement',
    component: UserAgreement,
    isAuth: false,
    headerHide: false,
    footerHide: false,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/future-fees',
    component: FutureFees,
    isAuth: false,
    headerHide: false,
    footerHide: false,
    headerWide: true,
    stickyHeader: true,
  },
  {
    path: '/spot-fees',
    component: SpotFees,
    isAuth: false,
    headerHide: false,
    footerHide: false,
    headerWide: true,
    stickyHeader: true,
  },
  // this route should be at the end of all other routes
  ...nonAuthRoutes,
]
