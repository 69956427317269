import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import styles from './Actions.module.sass'
import Icon from '../../../../components/Icon'
import Limit from './Limit'
import TPSL from './TPSL'
import Market from './Market'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SocketContext from 'context/context'
import { fetchWallets } from 'store/actions'

const navigation = ['Limit', 'Market', 'TP/SL']

const Actions = () => {
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState(0)
  const [visibleAction, setVisibleAction] = useState(false)
  const [visible, setVisible] = useState(false)

  
  const [price, setPrice] = useState()
  const [amount, setAmount] = useState()
  const [totalPrice, setTotalPrice] = useState()
  

  const dispatch = useDispatch()
  const [priceSymbol, setPriceSymbol] = useState('USDT')
  const [amountSymbol, setAmountSymbol] = useState('BTC')
  const { state } = useContext(SocketContext)
  const { klines } = state
  
  const { allWallets } = useSelector((state) => state?.walletReducer)

  useEffect(() => {
    let priceSymbol = klines?.symbol?.split('-')?.[0]
    let amountSymbol = klines?.symbol?.split('-')?.[0]
    setPriceSymbol(priceSymbol)
    setAmountSymbol(amountSymbol)
  }, [klines])

  const { selectedLayout } = useSelector(
    (state) => state?.crypto?.layoutReducer,
  )

  const handleClickBuy = () => {
    setVisibleAction(true)
    setVisible(true)
  }

  const handleClickSell = () => {
    setVisibleAction(false)
    setVisible(true)
  }

  const loadWallets = () => {
    dispatch(fetchWallets())
  }

  useEffect(() => {
    loadWallets({})
  }, [])

  const getWalletBalance = (asset) => {
    const wallet = allWallets?.trading?.filter(wallet => wallet?.asset === asset)?.[0]
    return wallet ? wallet?.amount : 0
  }

  return (
    <div className={cn('spot', styles.actions)}>
      <div className={styles.head}>
        <div className={cn('nav', styles.nav)}>
          {navigation.map((x, index) => (
            <button
              className={cn('link', styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {t(x)}
            </button>
          ))}
        </div>
        {selectedLayout === 'Standard' && (
          <div className={cn('info', styles.info)}>
            {t('Crypto trading tutorial')}{' '}
            <Link to="/learn-crypto">
              Learn now <Icon name="arrow-right" size="20" />
            </Link>
          </div>
        )}
      </div>
      <div className={cn(styles.wrapper, { [styles.show]: visible })}>
        {activeIndex === 0 && (
          <Limit
            contentBuy={`${getWalletBalance(priceSymbol)} ${priceSymbol}`}
            contentSell={`${getWalletBalance(amountSymbol)} ${amountSymbol}`}
            visible={visibleAction}
            setValue={setVisible}
          />
        )}
        {activeIndex === 1 && (
          <Market
            contentBuy={`${getWalletBalance(priceSymbol)} ${priceSymbol}`}
            contentSell={`${getWalletBalance(amountSymbol)} ${amountSymbol}`}
            amount={amount}
            limit
            totalPrice={totalPrice}
            visible={visibleAction}
            setValue={setVisible}
          />
        )}
        {activeIndex === 2 && (
          <TPSL
            contentBuy={`${getWalletBalance(priceSymbol)} ${priceSymbol}`}
            contentSell={`${getWalletBalance(amountSymbol)} ${amountSymbol}`}
            conditionalOrOCO
            triggerPrice
            marketOrder
            limit
            totalPrice={totalPrice}
            visible={visibleAction}
            setValue={setVisible}
          />
        )}
      </div>
      <div className={styles.btns}>
        <button
          className={cn('button-green button-small', styles.button)}
          onClick={() => handleClickBuy()}
        >
          {t('Buy')}
        </button>
        <button
          className={cn('button-red button-small', styles.button)}
          onClick={() => handleClickSell()}
        >
          {t('Sell')}
        </button>
      </div>
    </div>
  )
}

export default Actions
