import React, { useEffect, useState } from 'react'
import Profile from '../../components/Profile'
import TwoFaEnabledDisabled from './TwoFaEnabledDisabled'

const breadcrumbs = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: '2FA',
  },
]

const TwoFa = () => {
  return (
    <Profile title="2FA" breadcrumbs={breadcrumbs}>
      <TwoFaEnabledDisabled />
      {/* {clientData && clientData.settings && clientData.settings.twoFactorAuthEnabled ? (
      ) : (
        <TwoFaEnabled goDisabled={() => setDisabled(true)} />
      )} */}
    </Profile>
  )
}

export default TwoFa
