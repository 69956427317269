import StandardLayout from './StandardLayout'
import AdvancedLeftLayout from './AdvancedLeftLayout'
import { useSelector } from 'react-redux'

const Exchange = ({ children }) => {
  const { selectedLayout } = useSelector(
    (state) => state?.crypto?.layoutReducer,
  )

  if (selectedLayout === 'Standard')
    return <StandardLayout>{children}</StandardLayout>
  else return <AdvancedLeftLayout>{children}</AdvancedLeftLayout>
}

export default Exchange
