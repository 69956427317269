import { useTranslation } from 'react-i18next'
import Main from './Main'
import { useRef } from 'react'
import Download from 'screens/Home/Download'
import Popular from './Popular'
import Cards from './Main/Cards'
import WhyChooseFinitix from './WhyChooseUs'

const AboutUs = () => {
  const { t } = useTranslation()
  const scrollToRef = useRef(null)

  return (
    <div className="container">
      <div className="section">
        <Main scrollToRef={scrollToRef} />
        <Cards />
        <WhyChooseFinitix />
        <Popular classSection="section-bg section-mb0" />
        <Download />

        {/* <OurBonds />
        <OurGoals />
        <YourPowerStone />
        <WithTeam />
        <PreventsYouFrom />
        <JoinUs /> */}
      </div>
    </div>
  )
}

export default AboutUs
