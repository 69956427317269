import Form from './Form'

const TPSL = ({
  amount,
  contentBuy,
  contentSell,
  conditionalOrOCO,
  marketOrder,
  limit,
  visible,
  setValue,
  triggerPrice,
}) => {
  return (
    <div>
      <Form
        contentBuy={contentBuy}
        conditionalOrOCO={true}
        contentSell={contentSell}
        visible={visible}
        setValue={setValue}
      />
    </div>
  )
}

export default TPSL
