import Banner from './Banner'

const HelpCenter = () => {
  return (
    <div className="container">
      <Banner />
    </div>
  )
}

export default HelpCenter
