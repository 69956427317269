import cn from 'classnames'
import styles from './Cards.module.sass'

const items = [
  {
    title: '$20M+',
    subtitle: 'Transaction Volume',
  },
  {
    title: '764M+',
    subtitle: 'Active Global Users',
  },
  {
    title: '20+',
    subtitle: 'Countries',
  },
]

const Cards = ({ className }) => {
  return (
    <div className={cn('section', styles.cards)}>
      {items.map((x, index) => (
        <div className={styles.cardItemContainer} key={index}>
          <div className={styles.cardItem}>
            <div className="h2">{x.title}</div>
            <div className={styles.subtitle}>{x.subtitle}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Cards
