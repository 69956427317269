import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Kyc.module.sass'
import { Link } from 'react-router-dom'
import check from '../../../src/assets/crypto/check (3).svg'
import close from '../../../src/assets/crypto/x (1).svg'
import Breadcrumbs from 'components/Breadcrumbs'
import useDarkMode from 'use-dark-mode'
const Kyc = () => {
  const [topic, setTopic] = useState(true)

  const breadcrumbs = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'KYC',
    },
  ]

  const darkMode = useDarkMode(false)
  
  const customStyle = {
    backgroundColor: darkMode.value ? '#353945' : '#E6E8EC',
    padding: '20px 20px',
    borderRadius: '30px',
  }
  const styleRadio = {
    backgroundColor: !darkMode.value ? '#B1B5C3' : '',
  }
  return (
    <div className={cn('section-bg section-mb0', styles.section)}>
      <div style={{ marginLeft: '70px' }}>
        <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
        <div className={styles.labels}>Upload Kyc</div>
      </div>

              <div className={styles.variants}>
                {/* <label className={styles.radio}>
                  <input
                    className={styles.input}
                    type="radio"
                    name="topic"
                    onChange={() => setTopic(true)}
                    checked={topic}
                  />
                  <span className={styles.inner}>
                    <div
                      className={styles.icon}
                      style={{ backgroundColor: '#3772FF' }}
                    >
                     <h3>1</h3>
                    </div>
                    <span className={styles.text}>Wallet</span>
                  </span>
                </label> */}
                <label
                  className={styles.card}
                  style={customStyle}
                >
                  <label className={styles.radio}>
                    <input
                      className={styles.input}
                      type="radio"
                      name="topic"
                      onChange={() => setTopic(false)}
                      checked={!topic}
                    />
                    <span className={styles.inner_pending} >
                      <div
                        className={styles.icon}
                        style={{ backgroundColor: '#9757D7' }}
                      >
                        <h3>1</h3>

                        {/* <div className={styles.number}>
                        <h3>2</h3>
                      </div> */}
                        {/* <Icon name="candlesticks" size="20" /> */}
                      </div>
                      <span className={styles.text}>CO Unverified</span>
                    </span>
                    <div>
                      <div style={{ display: 'flex', marginTop: '20px' }}>
                        <img src={check} alt="image" />
                        <div style={{ marginLeft: '20px' }}>
                          <h4>Withdrawel</h4>
                          <h5>20 BTC</h5>
                        </div>
                      </div>

                      <div style={{ display: 'flex', marginTop: '40px' }}>
                        <img src={check} alt="image" />
                        <div style={{ marginLeft: '20px' }}>
                          <h4>Deposit</h4>
                        </div>
                      </div>

                      <div>
                        <div style={{ display: 'flex', marginTop: '40px' }}>
                          <img src={close} alt="image" />
                          <div style={{ marginLeft: '20px' }}>
                            <h4>Buy</h4>
                          </div>
                        </div>

                        <div style={{ marginLeft: '40px' }}>
                          <div style={{ display: 'flex', marginTop: '10px' }}>
                            <img src={close} alt="image" />
                            <div style={{ marginLeft: '15px' }}>
                              <h5>
                                Purehase Crypto Through third party payment
                              </h5>
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: '5px' }}>
                            <img src={close} alt="image" />
                            <div style={{ marginLeft: '15px' }}>
                              <h5>Coinw Card</h5>
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: '5px' }}>
                            <img src={close} alt="image" />
                            <div style={{ marginLeft: '15px' }}>
                              <h5>P2PBuy</h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ display: 'flex', marginTop: '40px' }}>
                        <img src={check} alt="image" />
                        <div style={{ marginLeft: '20px' }}>
                          <h4>Trade</h4>
                        </div>
                      </div>
                    </div>
                  </label>
                </label>

                <label
                  className={styles.card}
                  style={customStyle}

                >
                  <label
                    className={styles.radio}
                    style={{ backgroundColor: '#58BD7D' }}
                  >
                    <input
                      className={styles.input}
                      type="radio"
                      name="topic"
                      onChange={() => setTopic(false)}
                      checked={!topic}
                    />
                    <span  style={styleRadio} className={styles.inner}>
                      <div
                        className={styles.icon}
                        style={{ backgroundColor: '#9757D7' }}
                      >
                        <h3>2</h3>

                        {/* <div className={styles.number}>
                        <h3>2</h3>
                      </div> */}
                        {/* <Icon name="candlesticks" size="20" /> */}
                      </div>
                      <span className={styles.text}>C1 Basic Verification</span>
                    </span>
                    <div>
                      <div style={{ display: 'flex', marginTop: '20px' }}>
                        <img src={check} alt="image" />
                        <div style={{ marginLeft: '20px' }}>
                          <h4>Withdrawel</h4>
                          <h5>20 BTC</h5>
                        </div>
                      </div>

                      <div style={{ display: 'flex', marginTop: '40px' }}>
                        <img src={check} alt="image" />
                        <div style={{ marginLeft: '20px' }}>
                          <h4>Deposit</h4>
                        </div>
                      </div>

                      <div>
                        <div style={{ display: 'flex', marginTop: '40px' }}>
                          <img src={check} alt="image" />
                          <div style={{ marginLeft: '20px' }}>
                            <h4>Buy</h4>
                          </div>
                        </div>

                        <div style={{ marginLeft: '40px' }}>
                          <div style={{ display: 'flex', marginTop: '10px' }}>
                            <img src={check} alt="image" />
                            <div style={{ marginLeft: '15px' }}>
                              <h5>
                                Purehase Crypto Through third party payment
                              </h5>
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: '5px' }}>
                            <img src={close} alt="image" />
                            <div style={{ marginLeft: '15px' }}>
                              <h5>Coinw Card</h5>
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: '5px' }}>
                            <img src={close} alt="image" />
                            <div style={{ marginLeft: '15px' }}>
                              <h5>P2PBuy</h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ display: 'flex', marginTop: '40px' }}>
                        <img src={check} alt="image" />
                        <div style={{ marginLeft: '20px' }}>
                          <h4>Trade</h4>
                        </div>
                      </div>
                    </div>
                    <div className={styles.centerBtn}>
                      <Link
                        className={cn('button', styles.button)}
                        to="/kyc-upload"
                      >
                        Verify now
                      </Link>
                    </div>
                  </label>
                </label>

                <label
                  className={styles.card}
                  style={customStyle}

                >
                  <label
                    className={styles.radio}
                    style={{ backgroundColor: '#58BD7D' }}
                  >
                    <input
                      className={styles.input}
                      type="radio"
                      name="topic"
                      onChange={() => setTopic(false)}
                      checked={!topic}
                    />
                    <span  style={styleRadio} className={styles.inner}>
                      <div
                        className={styles.icon}
                        style={{ backgroundColor: '#9757D7' }}
                      >
                        <h3>3</h3>
                      </div>
                      <span className={styles.text}>
                        C2 Primary Verification
                      </span>
                    </span>
                    <div>
                      <div style={{ display: 'flex', marginTop: '20px' }}>
                        <img src={check} alt="image" />
                        <div style={{ marginLeft: '20px' }}>
                          <h4>Withdrawel</h4>
                          <h5>20 BTC</h5>
                        </div>
                      </div>

                      <div style={{ display: 'flex', marginTop: '40px' }}>
                        <img src={check} alt="image" />
                        <div style={{ marginLeft: '20px' }}>
                          <h4>Deposit</h4>
                        </div>
                      </div>

                      <div>
                        <div style={{ display: 'flex', marginTop: '40px' }}>
                          <img src={check} alt="image" />
                          <div style={{ marginLeft: '20px' }}>
                            <h4>Buy</h4>
                          </div>
                        </div>

                        <div style={{ marginLeft: '40px' }}>
                          <div style={{ display: 'flex', marginTop: '10px' }}>
                            <img src={check} alt="image" />
                            <div style={{ marginLeft: '15px' }}>
                              <h5>
                                Purehase Crypto Through third party payment
                              </h5>
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: '5px' }}>
                            <img src={check} alt="image" />
                            <div style={{ marginLeft: '15px' }}>
                              <h5>Coinw Card</h5>
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: '5px' }}>
                            <img src={check} alt="image" />
                            <div style={{ marginLeft: '15px' }}>
                              <h5>P2PBuy</h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ display: 'flex', marginTop: '40px' }}>
                        <img src={check} alt="image" />
                        <div style={{ marginLeft: '20px' }}>
                          <h4>Trade</h4>
                        </div>
                      </div>
                    </div>
                    <div className={styles.centerBtn}>
                      <Link
                        className={cn('button', styles.button)}
                        to="/kyc-upload"
                      >
                        Verify now
                      </Link>
                    </div>
                  </label>
                </label>

                <label
                  className={styles.card}
                  style={customStyle}

                >
                  <label
                    className={styles.radio}
                    style={{ backgroundColor: '#58BD7D' }}
                  >
                    <input
                      className={styles.input}
                      type="radio"
                      name="topic"
                      onChange={() => setTopic(false)}
                      checked={!topic}
                    />
                    <span  style={styleRadio} className={styles.inner}>
                      <div
                        className={styles.icon}
                        style={{ backgroundColor: '#9757D7' }}
                      >
                        <h3>4</h3>

                        {/* <div className={styles.number}>
                        <h3>2</h3>
                      </div> */}
                        {/* <Icon name="candlesticks" size="20" /> */}
                      </div>
                      <span className={styles.text}>
                        C3 Advanced Verification
                      </span>
                    </span>
                    <div>
                      <div style={{ display: 'flex', marginTop: '20px' }}>
                        <img src={check} alt="image" />
                        <div style={{ marginLeft: '20px' }}>
                          <h4>Withdrawel</h4>
                          <h5>20 BTC</h5>
                        </div>
                      </div>

                      <div style={{ display: 'flex', marginTop: '40px' }}>
                        <img src={check} alt="image" />
                        <div style={{ marginLeft: '20px' }}>
                          <h4>Deposit</h4>
                        </div>
                      </div>

                      <div>
                        <div style={{ display: 'flex', marginTop: '40px' }}>
                          <img src={check} alt="image" />
                          <div style={{ marginLeft: '20px' }}>
                            <h4>Buy</h4>
                          </div>
                        </div>

                        <div style={{ marginLeft: '40px' }}>
                          <div style={{ display: 'flex', marginTop: '10px' }}>
                            <img src={check} alt="image" />
                            <div style={{ marginLeft: '15px' }}>
                              <h5>
                                Purehase Crypto Through third party payment
                              </h5>
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: '5px' }}>
                            <img src={check} alt="image" />
                            <div style={{ marginLeft: '15px' }}>
                              <h5>Coinw Card</h5>
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: '5px' }}>
                            <img src={check} alt="image" />
                            <div style={{ marginLeft: '15px' }}>
                              <h5>P2PBuy</h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ display: 'flex', marginTop: '40px' }}>
                        <img src={check} alt="image" />
                        <div style={{ marginLeft: '20px' }}>
                          <h4>Trade</h4>
                        </div>
                      </div>
                    </div>
                  </label>
                </label>
              </div>
    </div>
  )
}

export default Kyc
