import ScrollButton from 'components/ScrollButton'
import styles from './Banner.module.sass'
import Icon from 'components/Icon'

const Banner = () => {
  return (
    <div className={styles.main}>
      <div className={styles.contentContainer}>
        <div className={styles.info}>SAVE YOUR TIME WITH STACKS</div>
        <div className="h2">How can we help</div>
        <div>A creative agency that lead and inspire</div>
        <div className={styles.searchContainer}>
          <input
            className={styles.inputText}
            type="text"
            placeholder="Search anything"
          />
          <button className={styles.arrowButton}>
            <Icon className={styles.icon} name="arrow-bottom" size="24" />
          </button>
        </div>
      </div>
      <img
        className={styles.image}
        src="/images/content/crypto/new-crypto-images/help-banner.png"
      />
    </div>
  )
}

export default Banner
