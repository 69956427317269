import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './ClientActivities.module.sass'
import Profile from '../../components/Profile'
import { Link } from 'react-router-dom'
import { fetchClientActivities } from 'apis/profile'
import Loader from 'components/Loader'
import { useSelector } from 'react-redux'
import moment from 'moment/moment'

const breadcrumbs = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Client Activities',
  },
]

const ClientActivities = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [clientActivitiesData, setClientActivitiesData] = useState([])
  const [hasNextPage, setHasNextPage] = useState(false)
  const [isDataFetching, setIsDataFetching] = useState(false)
  const { clientData } = useSelector((state) => state?.Profile)
  function handleGetMoreData (){
    setCurrentPage(prevState => prevState + 1)
  }
  async function getData(){
    try {
      setIsDataFetching(true)
      let queries = {
        page: currentPage,
        limit:  10,
        customerId: clientData?._id,
        disallowedTypes: 'LOGIN'
      }
      const response = await fetchClientActivities( queries )
      setClientActivitiesData(prevState => [...prevState, ...response?.docs])
      setHasNextPage(response?.hasNextPage)
      setIsDataFetching(false)
      
    } catch (error) {
      setIsDataFetching(false)
      console.log(error)
    }
  }
  useEffect(()=>{
    if (clientData?._id){
      getData()
    }
  }, [clientData, currentPage])
  return (
    <Profile title="Client Activities" breadcrumbs={breadcrumbs}>
      <div className={styles.section}>
        <div className={styles.title}>Client Activities</div>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Date / time</div>
            <div className={styles.col}>Message</div>
          </div>
          {clientActivitiesData && clientActivitiesData?.map((activity, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>
                <div className={styles.content}>{moment(activity?.createdAt).format('DD-MM-YYYY HH:mm:ss')}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.content}>{activity.type}</div>
              </div>
            </div>
          ))}
        </div>
        {
          hasNextPage &&
            <div className={styles.btns}>
              
              <button
                type="submit"
                disabled={isDataFetching}
                className={cn('button-stroke', styles.button)}
                onClick={handleGetMoreData}
              >
                {isDataFetching ? <Loader /> : 'Load More' }
              </button>
            </div>
        }
      </div>
    </Profile>
  )
}

export default ClientActivities
