import qs from 'qs'
import * as axiosHelper from './api_helper'

export const fetchAllLastTrades = async () => {
  const data = await axiosHelper.get('/publicTrade/last-trades')
  if (data.isError) {
    return data
  }
  return data.result
}
