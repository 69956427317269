import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import cn from 'classnames'
import styles from './Page.module.sass'
import Header from '../Header'
import Footer from '../Footer'
import { useTranslation } from 'react-i18next'
import { Languages } from 'i18n'

const Page = ({
  headerHide,
  stickyHeader,
  children,
  footerHide,
  headerWide,
}) => {
  const { pathname } = useLocation()
  const { i18n } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
    clearAllBodyScrollLocks()
  }, [pathname])

  return (
    <>
      <div className={cn(styles.page, Languages[i18n.language].direction)}>
        {!headerHide && (
          <Header headerWide={headerWide} stickyHeader={stickyHeader} />
        )}
        <div className={stickyHeader ? styles.innerStickyHeader : styles.inner}>
          {children}
        </div>
        {!footerHide && <Footer />}
      </div>
    </>
  )
}

export default Page
