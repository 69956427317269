import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_ERROR,
  FETCH_NOTIFICATIONS_SUCCESS,
  MARK_NOTIFICATIONS_READ,
  MARK_NOTIFICATIONS_READ_ERROR,
  MARK_NOTIFICATIONS_READ_SUCCESS,
  RECEIVED_NOTIFICATION,
} from './actionTypes'
import { toast } from 'react-toastify'

export const showErrorNotification = (message = '', duration = 1000) => {
  //parameter message should be type string only
  return toast.error(message, {
    position: 'top-right',
    autoClose: duration,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    role: 'alert',
  })
}

export const showSuccessNotification = (message = '', duration = 1000) => {
  //parameter message should be type string only
  return toast.success(message, {
    position: 'top-right',
    autoClose: duration,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    role: 'alert',
  })
}

export const fetchNotifications = (params = {}) => {
  return {
    type: FETCH_NOTIFICATIONS,
    payload: params,
  }
}

export const fetchNotificationsSuccess = (payload) => {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload,
  }
}

export const fetchNotificationsFailed = (error) => {
  return {
    type: FETCH_NOTIFICATIONS_ERROR,
    payload: error,
  }
}

export const markNotificationRead = (params = {}) => {
  return {
    type: MARK_NOTIFICATIONS_READ,
    payload: params,
  }
}

export const markNotificationReadSuccess = (payload) => {
  return {
    type: MARK_NOTIFICATIONS_READ_SUCCESS,
    payload,
  }
}

export const markNotificationReadFailed = (error) => {
  return {
    type: MARK_NOTIFICATIONS_READ_ERROR,
    payload: error,
  }
}

export const receivedNotification = (payload) => {
  return {
    type: RECEIVED_NOTIFICATION,
    payload,
  }
}

export const NO_ACCESS_MESSAGE = 'No permissions to access this resource'
