import TextInput from 'components/TextInput'
import React from 'react'

export default function FromikTextInput(props) {
  const { errClassName, ...rest } = props
  return (
    <>
      <TextInput {...rest} />
      {props.error && props.touched && (
        <div className={errClassName}>{props.error}</div>
      )}
    </>
  )
}
