import React from 'react'
import cn from 'classnames'
import styles from './Popular.module.sass'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import Card from './Card'
import Icon from 'components/Icon'

const items = [
  {
    title: 'Mohamed Rashad',
    content: 'Cheif Executive Officer',
    image: '/images/content/crypto/new-crypto-images/rashad.jpeg',
    image2x: '/images/content/crypto/new-crypto-images/rashad.jpeg',
  },
  {
    title: 'Mahmoud Farouk',
    content: 'Cheif Executive Officer',
    image: '/images/content/crypto/new-crypto-images/farouk.jpeg',
    image2x: '/images/content/crypto/new-crypto-images/farouk.jpeg',
  },
  {
    title: 'Mohamed Ahamed',
    content: 'Cheif Executive Officer',
    image: '/images/content/crypto/new-crypto-images/rashad.jpeg',
    image2x: '/images/content/crypto/new-crypto-images/rashad.jpeg',
  },
]

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
)

const Popular = ({ classSection }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="18" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="18" />
            </SlickArrow>
          ),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: false,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="18" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="18" />
            </SlickArrow>
          ),
        },
      },
    ],
  }

  return (
    <div className={cn(styles.section)}>
      <div className={cn('container', styles.container)}>
        <div className={styles.head}>
          <h2 className={cn('h2', styles.title)}>Meet FiniticX Team</h2>
          <div className={styles.info}>
            We{"'"}ve got everything you need to start trading.
          </div>
          <div className={styles.btns}>
            <Link className={cn('button', styles.button)} to="/contact">
              Join Affiliate Program{' '}
            </Link>
          </div>
        </div>
        <div className={styles.wrapper}>
          <Slider className="popular-slider" {...settings}>
            {items.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default Popular
