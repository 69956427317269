import {
  MARKETS_FETCH,
  MARKETS_FETCH_SUCCESSFUL,
  MARKETS_FETCH_FAILED,
  UPDATE_FAVORITE,
  UPDATE_FAVORITE_SUCCESS,
  UPDATE_FAVORITE_FAIL,
} from './actionTypes'

export const fetchMarkets = (params = {}) => {
  return {
    type: MARKETS_FETCH,
    payload: params,
  }
}

export const fetchMarketsSuccessful = (markets) => {
  return {
    type: MARKETS_FETCH_SUCCESSFUL,
    payload: markets,
  }
}

export const fetchMarketsFailed = (markets) => {
  return {
    type: MARKETS_FETCH_FAILED,
    payload: markets,
  }
}
export const updateFevourite = (id, params = {}) => {
  return {
    type: UPDATE_FAVORITE,
    payload: {
      id,
      ...params,
    },
  }
}

export const updateFevouriteSuccess = (data) => {
  return {
    type: UPDATE_FAVORITE_SUCCESS,
    payload: data,
  }
}
export const updateFevouriteFail = (data) => {
  return {
    type: UPDATE_FAVORITE_FAIL,
    payload: data,
  }
}
