import TextArea from 'components/TextArea'

export default function FromikTextArea(props) {
  const { errClassName, ...rest } = props
  return (
    <>
      <TextArea {...rest} />
      {props.error && props.touched && (
        <div className={errClassName}>{props.error}</div>
      )}
    </>
  )
}
