import React from 'react'
import cn from 'classnames'
import styles from './Popular.module.sass'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import Icon from '../Icon'
import Card from './Card'

const items = [
  {
    title: 'Launch Your Exchange',
    content:
      'Transform your crypto exchange vision into reality with FiniticX. Our comprehensive SaaS solutions turn complex ideas into user-friendly platforms, attracting and empowering users for maximum market impact.',
    button: 'Buy crypto',
    image: '/images/content/crypto/new-crypto-images/buy-sell-crypto.png',
    image2x: '/images/content/crypto/new-crypto-images/buy-sell-crypto.png',
    url: '/buy-crypto',
  },
  {
    title: 'Optimize Operations',
    content:
      "Streamline your exchange's operations with FiniticX's robust back-end systems. We deliver sophisticated operational strategies that are easy to implement, ensuring a smooth experience for you and your customers.",
    button: 'Trade now',
    image: '/images/content/crypto/new-crypto-images/trade-assets.png',
    image2x: '/images/content/crypto/new-crypto-images/trade-assets.png',
    url: '/exchange',
  },
  {
    title: 'Expand Your Expertise',
    content:
      "Unlock the full potential of blockchain with FiniticX's educational resources. We equip you with knowledge and strategies to grow your exchange, helping you capture the most lucrative market opportunities.",
    button: 'Learn now',
    image: '/images/content/crypto/new-crypto-images/learn-crypto.png',
    image2x: '/images/content/crypto/new-crypto-images/learn-crypto.png',
    url: '/learn-crypto',
  },
]

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
)

const Popular = ({ classSection }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="18" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="18" />
            </SlickArrow>
          ),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          infinite: false,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="18" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="18" />
            </SlickArrow>
          ),
        },
      },
    ],
  }

  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn('container', styles.container)}>
        <div className={styles.head}>
          <h2 className={cn('h2', styles.title)}>
            Empower Your Crypto Exchange Business in Moments
          </h2>
          <div className={styles.info}>
            We provide all the tools and expertise you need to launch and grow
            your exchange.{'"'}
          </div>
        </div>
        <div className={styles.wrapper}>
          <Slider className="popular-slider" {...settings}>
            {items.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </Slider>
        </div>
        <div className={styles.btns}>
          <Link className={cn('button', styles.button)} to="/contact">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Popular
