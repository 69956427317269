import { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Settings.module.sass'
import OutsideClickHandler from 'react-outside-click-handler'
import Icon from '../../Icon'
import { useTranslation } from 'react-i18next'

const language = [
  {
    title: 'English',
    flag: 'EN',
  },
  {
    title: 'Vietnamese',
    flag: 'VN',
  },
  {
    title: 'Russia',
    flag: 'RU',
  },
  {
    title: 'Arebic',
    flag: 'AR',
  },
]

const currencies = ['USD', 'RUB', 'EUR', 'JPY', 'BTC']

const Settings = ({ className }) => {
  const { t, i18n } = useTranslation()
  const currentLanguage = localStorage.getItem('I18N_LANGUAGE')
  const [visible, setVisible] = useState(false)
  const [languageCurrency, setLanguageCurrency] = useState({
    language: 'EN',
    currency: 'USD',
  })

  const handleChangeLanguage = (lang) => {
    setLanguageCurrency({ ...languageCurrency, language: lang })
    i18n.changeLanguage(lang.toLowerCase())
    localStorage.setItem('I18N_LANGUAGE', lang.toLowerCase())
  }

  const handleChangeCurrency = (currency) => {
    setLanguageCurrency({ ...languageCurrency, currency: currency })
  }

  useEffect(() => {
    setLanguageCurrency({
      ...languageCurrency,
      language: currentLanguage.toString().toUpperCase(),
    })
  }, [currentLanguage])

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(className, styles.settings, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          {languageCurrency.language}/{languageCurrency.currency}
          <Icon name="arrow-down" size="16" />
        </button>
        <div className={styles.body}>
          <div className={styles.row}>
            <div className={cn('col', styles.col)}>
              <div className={styles.category}>{t('Language')}</div>
              <div className={styles.menu}>
                {language.map((x, index) => (
                  <div
                    className={cn(styles.language, {
                      [styles.active]: x.flag === languageCurrency.language,
                    })}
                    key={index}
                    onClick={() => handleChangeLanguage(x.flag)}
                  >
                    <span className={styles.flag}>{t(x.flag)}</span>
                    {t(x.title)}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.category}>{t('Currency')}</div>
              <div className={styles.menu}>
                {currencies.map((x, index) => (
                  <div
                    className={cn(styles.currency, {
                      [styles.active]: x === languageCurrency.currency,
                    })}
                    key={index}
                    onClick={() => {
                      handleChangeCurrency(x)
                    }}
                  >
                    {t(x)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default Settings
