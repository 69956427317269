import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Notifications.module.sass'
import Icon from '../../components/Icon'
import Item from './Item'
import Filters from './Filters'
import Actions from '../../components/Actions'
import { useSelector, useDispatch } from 'react-redux'
import * as rdd from 'react-device-detect'
import {
  subscribeUserToPush,
  unsubscribeFromPushNotification,
} from 'serviceWorkerRegistration'
import {
  subscribePushNotification,
  unsubscribePushNotifications,
} from 'store/general/subscriptions/actions'
import { Setting } from './Setting'

const items = [
  {
    title: 'Login attempted from new IP',
    content:
      "Hello, you've reset the Google Authentication on your account successfully. Your old security items have expired and new security items have now been enabled.",
    date: '2021-03-10 20:19:15',
    icon: 'lightning',
  },
  {
    title: 'Login attempted from new IP',
    content:
      "Hello, you've reset the Google Authentication on your account successfully. Your old security items have expired and new security items have now been enabled.",
    date: '2021-03-10 20:19:15',
    icon: 'wallet',
  },
  {
    title: 'Login attempted from new IP',
    content:
      "Hello, you've reset the Google Authentication on your account successfully. Your old security items have expired and new security items have now been enabled.",
    date: '2021-03-10 20:19:15',
    icon: 'coin',
  },
  {
    title: 'Login attempted from new IP',
    content:
      "Hello, you've reset the Google Authentication on your account successfully. Your old security items have expired and new security items have now been enabled.",
    date: '2021-03-10 20:19:15',
    icon: 'lightning',
  },
  {
    title: 'Login attempted from new IP',
    content:
      "Hello, you've reset the Google Authentication on your account successfully. Your old security items have expired and new security items have now been enabled.",
    date: '2021-03-10 20:19:15',
    icon: 'wallet',
  },
  {
    title: 'Login attempted from new IP',
    content:
      "Hello, you've reset the Google Authentication on your account successfully. Your old security items have expired and new security items have now been enabled.",
    date: '2021-03-10 20:19:15',
    icon: 'coin',
  },
]

const filters = [
  'Security',
  'Wallet',
  'Trade',
  'Deposit',
  'Transfer',
  'Withdrawals',
  'News',
]

const Notifications = () => {
  const [visible, setVisible] = useState(0)
  const [selectedFilters, setSelectedFilters] = useState([])
  const { subscriptionLoading, settings } = useSelector((state) => ({
    subscriptionLoading: state.subscriptionsReducer.loading,
    settings: state.Profile.clientData.settings || {},
  }))
  const dispatch = useDispatch()

  const subAndSaveSubscription = async (e) => {
    if (settings.localAndClientPushNotifications) {
      const unsubData = await unsubscribeFromPushNotification()
      dispatch(unsubscribePushNotifications(unsubData.endpoint))
    } else {
      e.preventDefault()
      let subs = await subscribeUserToPush()
      subs = JSON.parse(JSON.stringify(subs))
      const data = {
        endpoint: subs.endpoint,
        expirationTime: subs.expirationTime,
        keys: subs.keys,
        deviceDetails: {
          browserName: rdd?.browserName || 'Not Found',
          browserVersion: rdd?.browserVersion || 'Not Found',
          deviceType: rdd?.deviceType || 'Not Found',
          osName: rdd?.osName || 'Not Found',
          osVersion: rdd?.osVersion || 'Not Found',
          mobileVendor: rdd?.mobileVendor || 'Not Found',
          mobileModel: rdd?.mobileModel || 'Not Found',
          userAgent: rdd?.getUA || 'Not Found',
          engineName: rdd?.engineName || 'Not Found',
          engineVersion: rdd?.engineVersion || 'Not Found',
          fullBrowserVersion: rdd?.fullBrowserVersion || 'Not Found',
        },
      }
      dispatch(subscribePushNotification(data))
    }
  }

  const getEnableDisableButton = (buttonAction = () => {}, loading = false) => {
    let buttonText = settings.localAndClientPushNotifications
      ? 'Disable'
      : 'Enable'
    let buttonClass = settings.localAndClientPushNotifications
      ? 'button-red'
      : 'button-green'
    return (
      <button
        onClick={buttonAction}
        disabled={loading}
        className={cn(buttonClass, styles.button)}
      >
        {buttonText}
      </button>
    )
  }

  const [ notifactionTab, setNotificationTab ] = useState("")

 const customStyleSetting = {
       backgroundColor: notifactionTab === "setting" ? '#58BD7D' : ''
 }
   
  return (
    <div className={styles.notifications}>
      <div className={cn('container', styles.container)}>
        <div className={styles.body}>
          <div className={styles.top}>
            <h4 className={cn('h4', styles.title)}>
              Notifications{' '}
            </h4>
              {getEnableDisableButton(
                subAndSaveSubscription,
                subscriptionLoading,
              )}
            <button className={cn('button-stroke button-small', styles.button)} onClick={()=> setNotificationTab("notification")}>
              Mark all as read
            </button>
            <button className={cn('button-stroke button-small', styles.button)} onClick={()=> setNotificationTab("notification")}>
              Clear all
            </button>
            <button className={cn('button-stroke button-small', styles.button)} onClick={()=> setNotificationTab("setting")} style={customStyleSetting}>
              Settings
            </button>
          </div>

{
  notifactionTab === 'setting' ? 
  <div>
   <Setting/>
  </div>
  : 
  <div className={styles.row}>
            <button
              className={cn('button-small', styles.toggle, {
                [styles.active]: visible,
              })}
              onClick={() => setVisible(!visible)}
            >
              Advanced filter
            </button>
            <Filters
              className={cn(styles.filters, { [styles.active]: visible })}
              filters={filters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
            <div className={styles.wrapper}>
              <div className={styles.list}>
                {items.map((x, index) => (
                  <Item
                    className={styles.item}
                    item={x}
                    key={index}
                    index={index}
                  />
                ))}
              </div>
              <div className={styles.btns}>
                <button
                  className={cn('button-stroke button-small', styles.button)}
                >
                  <span>Load more</span>
                  <Icon name="calendar" size="16" />
                </button>
              </div>
            </div>
   </div>
}

        </div>
        <Actions className={styles.actions} />
      </div>
    </div>
  )
}

export default Notifications
