import React from 'react'
import styles from './AppLoader.module.sass'

function AppLoader() {
  return (
    <React.Fragment>
      <div className={styles.loader}>
        <div className={styles.glow} />
        <div className={styles.glow} />
        <div className={styles.glow} />
        <div className={styles.glow} />
        <div className={styles.glow} />
      </div>
    </React.Fragment>
  )
}

export default AppLoader
