import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import './i18n'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import * as serviceWorker from 'serviceWorkerRegistration'
import AppStore from './store'
import AppLoader from 'components/AppLoader'


ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={AppStore.store}>
    <PersistGate loading={null} persistor={AppStore.persistor}>
      <ToastContainer />
      <Suspense fallback={<AppLoader />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </PersistGate>
  </Provider>,
)

serviceWorker.register()
