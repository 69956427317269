import React from 'react'
import cn from 'classnames'
import styles from './Banner.module.sass'
import { useTranslation } from 'react-i18next'

const EarnLikeBanner = () => {
  const { t } = useTranslation()

  return (
    <div className={'container'}>
      <div className={cn('section', styles.banner)}>
        <div className={styles.content}>
          <div className={cn('h2', styles.title)}>
            {t('Scale Your Crypto Exchange with FiniticX!')}
          </div>
          <div className={styles.subTitle}>
            {t(
              'Join the FiniticX Partnership Program today and leverage our complete end-to-end solutions to grow your exchange. Benefit from our robust technology, expert marketing, and operational excellence. Partner with us and enjoy competitive advantages in the crypto market.',
            )}
            {/* <b> {t('50% commission')} </b>
            {t('on each referral')} */}
          </div>
          <button className={styles.button}>{t('Become a Partner')}</button>
        </div>
        <img
          className={styles.img}
          src="/images/content/crypto/new-crypto-images/earn-like-never-banner.png"
        />
      </div>
    </div>
  )
}

export default EarnLikeBanner
