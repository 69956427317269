import cn from 'classnames'
import styles from './WhyChooseFinitix.module.sass'
import { useState } from 'react'

const accordionData = [
  {
    title: 'Comprehensive Solutions',
    content:
      'From start-up to scale-up, FiniticX delivers complete crypto exchange services.',
  },
  {
    title: 'Cutting-Edge Tech',
    content:
      'Advanced, secure, and user-friendly platforms for all your trading needs.',
  },
  {
    title: 'Strategic Growth',
    content:
      'Tailored marketing and operational support to propel your exchange forward.',
  },
  {
    title: 'Global Reach',
    content: "Join a network that's expanding the crypto community worldwide.",
  },
  {
    title: 'Reliable Support',
    content:
      "Continuous assistance to keep your platform at the industry's forefront.",
  },
]

const WhyChooseFinitix = () => {
  const [visible, setVisible] = useState(0)
  const [isExpand, setIsExpand] = useState(false)

  const handleToggleExpand = (val) => {
    if (val !== isExpand) setIsExpand(val)
    else setIsExpand(null)
  }

  return (
    <div className="section">
      <div className={styles.whyChooseFinitixContainer}>
        <div>
          <img
            className={styles.image}
            src="/images/content/crypto/new-crypto-images/why-choose-finitix.png"
          />
        </div>
        <div className={styles.content}>
          <div className={cn('h4', styles.title)}>Why Choose FiniticX?</div>
          {accordionData.map((item, index) => (
            <div key={index} className={styles.accordion}>
              <div
                className={cn(
                  { [styles.active]: isExpand === index },
                  styles.accordionSummary,
                )}
                onClick={() => handleToggleExpand(index)}
              >
                <div className={styles.accordionTitle}>{item?.title}</div>
                <div
                  className={cn({ [styles.active]: visible }, styles.button)}
                >
                  {isExpand === index ? '-' : '+'}
                </div>
              </div>
              {isExpand === index && (
                <div className={styles.accordionDetails}>{item?.content}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default WhyChooseFinitix
