import { useFormik } from 'formik'
import React from 'react'
import Form from './Form'

const Limit = ({
  contentBuy,
  contentSell,
  price,
  amount,
  visible,
  setValue,
}) => {
  const limitForm = useFormik({
    initialValues: {
      price: 0,
      amount: 0,
    },
    onSubmit: async (values) => {
      console.log('BuyBtcValues', values)
    },
  })

  return (
    <form>
      <Form
        contentBuy={contentBuy}
        contentSell={contentSell}
        amount={amount}
        price={price}
        visible={visible}
        setValue={setValue}
        total={true}
        takeProfit={true}
        stopLoss={true}
        handleChange={limitForm.handleChange}
      />
    </form>
  )
}

export default Limit
