export const EDIT_PROFILE = 'EDIT_PROFILE'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_ERROR = 'PROFILE_ERROR'
export const RESET_PROFILE_FLAG = 'RESET_PROFILE_FLAG'
export const FETCH_PROFILE_START = 'FETCH_PROFILE_START'
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS'

export const SUBMIT_IND_PROFILE_START = 'SUBMIT_IND_PROFILE_START'
export const SUBMIT_IND_PROFILE_END = 'SUBMIT_IND_PROFILE_END'

export const CONVERT_PROFILE_REQUESTED = 'CONVERT_PROFILE_REQUESTED'
export const CONVERT_PROFILE_SUCCESS = 'CONVERT_PROFILE_SUCCESS'
export const CONVERT_PROFILE_FAIL = 'CONVERT_PROFILE_FAIL'

export const UPLOAD_PROFILE_AVATAR_START = 'UPLOAD_PROFILE_AVATAR_START'
export const UPLOAD_PROFILE_AVATAR_END = 'UPLOAD_PROFILE_AVATAR_END'
export const DELETE_PROFILE_AVATAR_START = 'DELETE_PROFILE_AVATAR_START'
export const DELETE_PROFILE_AVATAR_END = 'DELETE_PROFILE_AVATAR_END'

export const UPDATE_PROFILE_JOURNEY = 'UPDATE_PROFILE_JOURNEY'
export const UPDATE_PUSH_NOTIFICATION_OPTION = 'UPDATE_PUSH_NOTIFICATION_OPTION'

export const UPDATE_PROFILE_SETTINGS = 'UPDATE_PROFILE_SETTINGS'
export const UPDATE_PROFILE_SETTINGS_SUCCESS = 'UPDATE_PROFILE_SETTINGS_SUCCESS'
export const UPDATE_PROFILE_SETTINGS_FAIL = 'UPDATE_PROFILE_SETTINGS_FAIL'
