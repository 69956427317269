import { socket } from './index'

const updateMarkets = (currentMarkets, newData) => {
  newData.forEach((market) => {
    const exists = currentMarkets.find((x) => x.instanceId === market.instanceId)
    if (!exists) currentMarkets.push(market)
  })
  let updatedData = currentMarkets
  updatedData = updatedData.map((market) => {
    const found = newData.find((x) => x.instanceId === market.instanceId)
    return {
      ...market,
      ...found,
    }
  })
  return updatedData
}

const updateKlines = (currentKlines, newKline) => {
  if (currentKlines.symbol !== newKline.symbol) return currentKlines
  currentKlines.data.push(newKline)
  return currentKlines
}

const updateAllKlines = (currentAllKlines, newKline) => {
  let currentAllKlinesData = { ...currentAllKlines }
  const exists = currentAllKlinesData[`${newKline.symbol}`]
  if (!exists) currentAllKlinesData[`${newKline.symbol}`] = []
  let currentAllKlinesDataArray = [
    ...currentAllKlinesData[`${newKline.symbol}`],
  ]

  currentAllKlinesDataArray?.reverse()

  if (currentAllKlinesDataArray?.length >= 20) {
    currentAllKlinesDataArray.shift()
    currentAllKlinesDataArray?.push(newKline)
  } else {
    currentAllKlinesDataArray?.push(newKline)
  }
  currentAllKlinesDataArray?.reverse()
  currentAllKlinesData[`${newKline.symbol}`] = currentAllKlinesDataArray
  return currentAllKlinesData
}

const updateOrderBooks = (currentOrderBooks, newData) => {
  const exists = currentOrderBooks.find((x) => x.instanceId === newData.instanceId)
  if (!exists) currentOrderBooks.push(newData)
  let updatedData = currentOrderBooks
  updatedData = updatedData.map((ob) => {
    const found = newData.instanceId === ob.instanceId
    if (found) {
      return {
        ...ob,
        ...newData,
      }
    } else {
      return ob
    }
  })

  return updatedData
}

const updateTrades = (currentTrades, newData) => {
  let currectTradesData = { ...currentTrades }
  const exists = currectTradesData[`${newData.symbol}`]
  if (!exists) currectTradesData[`${newData.symbol}`] = []
  let currectTradesDataArray = [...currectTradesData[`${newData.symbol}`]]

  currectTradesDataArray?.reverse()

  if (currectTradesDataArray?.length >= 20) {
    currectTradesDataArray.shift()
    currectTradesDataArray?.push(newData)
  } else {
    currectTradesDataArray?.push(newData)
  }
  currectTradesDataArray?.reverse()
  currectTradesData[`${newData.symbol}`] = currectTradesDataArray
  return currectTradesData
}


export const updateAllTrades = (currentTrades, newData = []) => {
  let currectTradesData = { ...currentTrades }
  newData?.forEach(item=>{
    const exists = currectTradesData[`${item.symbol}`]
    if (!exists) currectTradesData[`${item.symbol}`] = []
    let currectTradesDataArray = [...currectTradesData[`${item.symbol}`]]
    
    if (currectTradesDataArray?.length <= 20){
      currectTradesDataArray?.push(item)
    }
    
    currectTradesDataArray?.sort((a, b)=> b?.timestamp - a?.timestamp)
    currectTradesData[`${item.symbol}`] = currectTradesDataArray
  })
  return currectTradesData
}

export const socketEvents = ({ state, setState }) => {
  if (state.markupId) {
    socket.on('pricing_64395106ffb0ac62511116c7', () => {})
  }
  // const pricingEvent = state.markupId
  //   ? `pricing_${state.markupId}`
  //   : 'pricing_64395106ffb0ac62511116c7'
  // socket.on(pricingEvent, (msg) => {
  //   setState((state) => {
  //     const message = JSON.parse(msg)
  //     const markets = updateMarkets(state.markets, message)
  //     return {
  //       ...state,
  //       markets,
  //     }
  //   })
  // })
  const pricingEvent = 'pricing'
  socket.on(pricingEvent, (msg) => {
    setState((state) => {
      const message = JSON.parse(msg)
      const markets = updateMarkets(state.markets, message)
      return {
        ...state,
        markets,
      }
    })
  })
  const klineEvent = state.markupId ? `kline_${state.markupId}` : 'kline'

  socket.on(klineEvent, (msg) => {
    setState((state) => {
      const message = JSON.parse(msg)
      // chart accepts kline times in secs
      message.time /= 1000
      const klines = updateKlines(state.klines, message)
      const allKlines = updateAllKlines(state.allKlines, message)
      return {
        ...state,
        klines,
        allKlines,
      }
    })
  })

  socket.on('orderbook', (msg) => {
    setState((state) => {
      const message = JSON.parse(msg)
      const orderBooks = updateOrderBooks(state.orderBooks, message)
      return {
        ...state,
        orderBooks,
      }
    })
  })
  socket.on('trade', (msg) => {
    setState((state) => {
      const message = JSON.parse(msg)
      const trades = updateTrades(state.trades, message)
      return {
        ...state,
        trades,
      }
    })
  })
}
