import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Table.module.sass'
import Dropdown from '../../../components/Dropdown'
import { getOrdersStart } from 'store/crypto/orders/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

const navigation = ['Open Orders', 'Closed Orders']

const Table = () => {
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState(0)
  const location = useLocation() 
  const [navigation, setNavigation] = useState(['Open Orders', 'Closed Orders'])
  const [sorting, setSorting] = useState(navigation[0])
  const dispatch = useDispatch()
  const [sizePerPage] = useState(10)
  const {
    orders: orderHistory,
  } = useSelector((state) => state.crypto.orders)

  const formatDate = (date) => {
    const d = new Date(date)
    return `${d.toLocaleDateString()}, ${d.toLocaleTimeString()}`
  }

  const loadHistory = (page, limit, orderStatus) => {
    dispatch(
      getOrdersStart({
        limit: limit,
        status: activeIndex === 0 ? 'open' : 'closed',
      }),
    )
  }

  useEffect(() => {
    loadHistory(1, sizePerPage, '')
  }, [ activeIndex])
  useEffect(()=>{
    const locationRoutes = location?.pathname?.split('/')
    if (locationRoutes?.includes('margin') || locationRoutes?.includes('futures')){
      setNavigation(prevState => [...prevState, 'Open Positions', 'Position History'])
    }
  }, [location])
  return (
    <div className={styles.inner}>
      <Dropdown
        className={styles.dropdown}
        value={sorting}
        setValue={setSorting}
        options={navigation}
      />
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn('orders-tab', styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {t(x)}
          </button>
        ))}
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">{t('Symbol')}</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">{t('Date')} </div>
          </div>
          <div className={styles.col}>
            <div className="sorting">{t('Type')} </div>
          </div>
          <div className={styles.col}>
            <div className="sorting">{t('Side')}</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">{t('Amount')}</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">{t('Tp')}</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">{t('Sl')}</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">{t('Price')}</div>
          </div>
        </div>
        {orderHistory?.map((x, index) => (
          <div className={styles.row} key={index}>
            {/* <div className={styles.col}>{x.time}</div>
            <div className={styles.col}>
              {x.positive && (
                <div className={styles.positive}>{x.positive}</div>
              )}
              {x.negative && (
                <div className={styles.negative}>{x.negative}</div>
              )}
            </div> */}
            {/* <div className={styles.col}>{x.amount}</div> */}
            <div className={styles.col}>{x.symbol}</div>
            <div className={styles.col}>{formatDate(x.createdAt)}</div>
            <div className={styles.col}>{x.type}</div>
            <div className={styles.col}>{x.side}</div>
            <div className={styles.col}>{x.amount?.$numberDecimal ?? x.amount}</div>
            <div className={styles.col}>{x.paramsData?.tp}</div>
            <div className={styles.col}>{x.paramsData?.sl}</div>
            <div className={styles.col}>{x.price?.$numberDecimal ?? x.price}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Table
