import React from 'react'
import Main from './Main'
import Article from './Article'
import Catalog from './Catalog'

const LearnCrypto = () => {
  return (
    <>
      <Main />
      <Article />
      <Catalog />
    </>
  )
}

export default LearnCrypto
