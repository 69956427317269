import { useRef, useState } from 'react'
import cn from 'classnames'
import styles from './AdjustLeverage.module.sass'
import TextInput from '../TextInput'
import { useTranslation } from 'react-i18next'

const fromDirectionOptions = ['Margin', 'Fiat and Spot']
const toDirectionOptions = ['Fiat and Spot', 'Margin']
const coinOptions = ['ETH Ethereum', 'BTC Bitcoin', 'TRX Tron']

const AdjustLeverage = ({ currency, leverageType, buySell, leverages }) => {
  const { t } = useTranslation()
  const [selectedLeverage, setSelectedLeverage] = useState(1)

  const handleChangeLeverage = (value) => {
    setSelectedLeverage(value)
  }

  const leveragesContainerRef = useRef(null)

  const handleScroll = (scrollOffset) => {
    if (leveragesContainerRef.current) {
      leveragesContainerRef.current.scrollLeft += scrollOffset
    }
  }

  return (
    <div className={styles.adjustLeverageContainer}>
      <div className={cn('h4', styles.title)}>{t('Adjust leverage')}</div>
      <div className={styles.details}>
        <div>icon</div>
        <div className={styles.currency}>{currency}</div>
        <div className={styles.badge}>
          <div className={styles.currency}>{leverageType}</div>
        </div>
        <div
          className={buySell === 'Buy' ? styles.badgeGreen : styles.badgeRed}
        >
          <h6>{t(buySell)}</h6>
        </div>
      </div>
      <TextInput
        className={styles.field}
        name="amount"
        type="text"
        onChange={(e) => handleChangeLeverage(Number(e.target.value))}
        value={selectedLeverage}
      />
      <div className={styles.leveragesContainer} ref={leveragesContainerRef}>
        {leverages.map((item, index) => (
          <div
            key={index}
            className={
              selectedLeverage === item
                ? styles.selectedLeverage
                : styles.leverage
            }
            onClick={() => handleChangeLeverage(item)}
          >
            {item}x
          </div>
        ))}
      </div>

      <p>
        Max borrowing at current leverage <b> 0 BTC or 0 USDT</b> Required
        margin with current position <b>0 USDT</b> High leverage. Please trade
        with caution.
      </p>
      <button className={cn('button-stroke button-small', styles.button)}>
        Login to set
      </button>
    </div>
  )
}

export default AdjustLeverage
