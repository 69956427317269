import Exchange from '..'
import Actions from './Actions'
import { fetchMarkets } from 'store/actions'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

const Futures = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchMarkets({
      type: 'FUTURES',
    }))
  }, [])
  return (
    <Exchange>
      <Actions />
    </Exchange>
  )
}

export default Futures
