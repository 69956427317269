import React, { useContext, useEffect, useState } from 'react'
import styles from './Form.module.sass'
import Action from './Action'
import { useMediaQuery } from 'react-responsive'
import Icon from '../../../../../components/Icon'
import { useSelector } from 'react-redux'
import OpenClosePannel from 'screens/Exchange/Futures/Actions/OpenClosePannel'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { postOrderAPI } from 'apis/orders'
import SocketContext from 'context/context'

const Form = ({
  contentBuy,
  contentSell,
  conditionalOrOCO,
  limit,
  priceBuy,
  amountBuy,
  totalPriceBuy,
  priceSell,
  amountSell,
  totalPriceSell,
  // price,
  // amount,
  // totalPrice,
  stop,
  triggerPrice,
  visible,
  setValue,
  handleChange,
  takeProfit,
  stopLoss,
  orders,
  type
}) => {
  const { t } = useTranslation()
  const [isBuy, setIsBuy] = useState(true)
  const isTablet = useMediaQuery({ query: '(max-width: 1023px)' })
  
  const [priceSymbol, setPriceSymbol] = useState('USDT')
  const [amountSymbol, setAmountSymbol] = useState('BTC')
  const { state } = useContext(SocketContext)
  const { klines } = state
  
  useEffect(() => {
    let priceSymbol = klines?.symbol?.split('-')?.[0]
    let amountSymbol = klines?.symbol?.split('-')?.[0]
    setPriceSymbol(priceSymbol)
    setAmountSymbol(amountSymbol)
  }, [klines])

  const navigate = useNavigate()
  const { clientData } = useSelector((state) => state?.Profile)

  const { selectedLayout } = useSelector(
    (state) => state?.crypto?.layoutReducer,
  )

  const handleChangeOrderType = (val) => {
    setIsBuy(val)
  }

  
  return (
    <div className={styles.form}>
      {isTablet && selectedLayout !== 'Standerd' ? (
        <>
          {visible ? (
            <>
              <div className={styles.head}>
                <div className={styles.title}>Place order</div>
                <button
                  className={styles.close}
                  onClick={() => setValue(false)}
                >
                  <Icon name="close-circle" size="24" />
                </button>
              </div>
              <Action
                title={`Buy ${priceSymbol}`}
                content={contentBuy}
                price={priceBuy}
                totalPrice={totalPriceBuy}
                amount={amountBuy}
                priceSymbol={'USDT'}
                amountSymbol={amountSymbol}
                conditionalOrOCO={conditionalOrOCO}
                contentSell={contentSell}
                triggerPrice={triggerPrice}
                stop={stop}
                limit={limit}
                classButton="button-green"
                buttonText={clientData?._id ? "Buy" : "Sign In"}
                handleChange={handleChange}
                takeProfit={takeProfit}
                stopLoss={stopLoss}
                orders={orders}
                type={type}
                actionType='buy'
              />
            </>
          ) : (
            <>
              <div className={styles.head}>
                <div className={styles.title}>Place order</div>
                <button
                  className={styles.close}
                  onClick={() => setValue(false)}
                >
                  <Icon name="close-circle" size="24" />
                </button>
              </div>
              <Action
                title={`Sell ${amountSymbol}`}
                content={contentSell}
                triggerPrice={triggerPrice}
                price={priceSell}
                totalPrice={totalPriceSell}
                amount={amountSell}
                priceSymbol={'USDT'}
                amountSymbol={amountSymbol}
                conditionalOrOCO={conditionalOrOCO}
                stop={stop}
                limit={limit}
                classButton="button-red"
                buttonText={clientData?._id ? "Sell" : "Sign In"}
                handleChange={handleChange}
                orders={orders}
                type={type}
                actionType='sell'
              />
            </>
          )}
        </>
      ) : selectedLayout !== 'Standard' ? (
        <div>
          <div className={styles.row}>
            <div className={styles.panelContainer}>
              <OpenClosePannel
                label1={'Buy'}
                label2={'Sell'}
                handleChange={handleChangeOrderType}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.panelCol}>
              <div className={styles.advancePanelActionContainer}>
                {isBuy ? (
                  <Action
                    title={`Buy ${priceSymbol}`}
                    priceSymbol={'USDT'}
                    amountSymbol={amountSymbol}
                    triggerPrice={triggerPrice}
                    conditionalOrOCO={conditionalOrOCO}
                    stop={stop}
                    limit={limit}
                    classButton="button-green"
                    buttonText={clientData?._id ? "Buy" : "Sign In"}
                    takeProfit={takeProfit}
                    stopLoss={stopLoss}
                    orders={orders}
                    type={type}
                    actionType='buy'
                  />
                ) : (
                  <Action
                    title={`Sell ${amountSymbol}`}
                    priceSymbol={'USDT'}
                    amountSymbol={amountSymbol}
                    triggerPrice={triggerPrice}
                    conditionalOrOCO={conditionalOrOCO}
                    stop={stop}
                    limit={limit}
                    classButton="button-red"
                    buttonText={clientData?._id ? "Sell" : "Sign In"}
                    orders={orders}
                    type={type}
                    actionType='sell'
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.row}>
          <div className={styles.col}>
            <Action
              title={`Buy ${priceSymbol}`}
              content={contentBuy}
              priceSymbol={'USDT'}
              amountSymbol={amountSymbol}
              triggerPrice={triggerPrice}
              conditionalOrOCO={conditionalOrOCO}
              stop={stop}
              limit={limit}
              classButton="button-green"
              buttonText={clientData?._id ? "Buy" : "Sign In"}
              takeProfit={takeProfit}
              stopLoss={stopLoss}
              orders={orders}
              type={type}
              actionType='buy'
            />
          </div>
          <div className={styles.col}>
            <Action
              title={`Sell ${amountSymbol}`}
              content={contentSell}
              priceSymbol={'USDT'}
              amountSymbol={amountSymbol}
              triggerPrice={triggerPrice}
              conditionalOrOCO={conditionalOrOCO}
              stop={stop}
              limit={limit}
              classButton="button-red"
              buttonText={clientData?._id ? "Sell" : "Sign In"}
              orders={orders}
              type={type}
              actionType='sell'
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Form
