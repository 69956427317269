export const SUBSCRIBE_PUSH_NOTIFICATION = 'SUBSCRIBE_PUSH_NOTIFICATION'
export const SUBSCRIBE_PUSH_NOTIFICATION_SUCCESS =
  'SUBSCRIBE_PUSH_NOTIFICATION_SUCCESS'
export const SUBSCRIBE_PUSH_NOTIFICATION_ERROR =
  'SUBSCRIBE_PUSH_NOTIFICATION_ERROR'

export const UNSUBSCRIBE_PUSH_NOTIFICATION = 'UNSUBSCRIBE_PUSH_NOTIFICATION'
export const UNSUBSCRIBE_PUSH_NOTIFICATION_SUCCESS =
  'UNSUBSCRIBE_PUSH_NOTIFICATION_SUCCESS'
export const UNSUBSCRIBE_PUSH_NOTIFICATION_ERROR =
  'UNSUBSCRIBE_PUSH_NOTIFICATION_ERROR'
