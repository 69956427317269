const { SELECTED_LAYOUT } = require('./actionTypes')

const INIT_STATE = {
  selectedLayout: 'Standard',
}

const layoutReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SELECTED_LAYOUT:
      return {
        ...state,
        selectedLayout: action.payload,
      }
    default:
      return state
  }
}

export default layoutReducer
