import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './TwoFaEnabledDisabled.module.sass'
import TextInput from '../../../components/TextInput'
import Icon from '../../../components/Icon'
// import { generateQRCodeAPI } from 'apis/auth'
import { generateQRCodeStart, verify2FACodeStart } from 'store/general/auth/twoFactorAuth/actions'
import { useDispatch, useSelector } from 'react-redux'
import Loader from 'components/Loader'
import AuthCode from "react-auth-code-input";
import CardWrapper from 'components/CardWrapper/CardWrapper'

const TwoFaEnabledDisabled = () => {
  const [twoFACode, setTwoFACode] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const { clientData } = useSelector(state=>state.Profile);
  const { verifyCode, generateQR } = useSelector(state=>state.twoFactorAuthReducer);
  const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(false);
  const dispatch = useDispatch();
  const focusInput2FA = (digits) => {
    const activeInputs = document.querySelectorAll(".twofa-input.active");
    const inputs = document.querySelectorAll(".twofa-input");
    if (activeInputs.length > digits.toString().length)
      activeInputs[activeInputs.length - 1]?.classList.remove("active");
    else
      inputs[digits.toString().length - 1]?.classList.add("active");
      setTwoFACode(digits);
    if (digits.length === 6) enableDisableTwoFactorAuth(digits);
  };

  useEffect(() => {
    if (generateQR && generateQR.qrCode) {
      setShowVerification(true);
    } else {
      setShowVerification(false);
    }
  }, [generateQR.qrCode]);

  useEffect(()=>{
    if (verifyCode?.result?.type === "disable" && verifyCode.success) {
      setTwoFactorAuthEnabled(false);
    }
    if (verifyCode?.result?.type === "enable" && verifyCode.success) {
      setTwoFactorAuthEnabled(true);
    }
  }, [verifyCode]);

  useEffect(()=>{
    if (clientData && clientData.settings && clientData.settings.twoFactorAuthEnabled)
      setTwoFactorAuthEnabled(true);
  }, [clientData]);

  useEffect(()=>{
    if (verifyCode.disabled) 
      setShowVerification(false);
  }, [verifyCode.disabled]);

  const enable2FA = () => dispatch(generateQRCodeStart());
  const disable2FA = () => setShowVerification(true);
  const enableDisableTwoFactorAuth = (code = '') => {
    if ((code || twoFACode)?.length === 6){
      twoFactorAuthEnabled ? dispatch(verify2FACodeStart({
        token: code || twoFACode,
        email: clientData && clientData.email && clientData.email.toLowerCase(),
        type: "disable",
      })) 
      : dispatch(verify2FACodeStart({
        token: code || twoFACode,
        email: clientData && clientData.email && clientData.email.toLowerCase(),
        type: "enable",
      }));
    }
  }

  const getCardData = () => {
    let buttonClick = enable2FA;
    let disabled = verifyCode.loading;
    let buttonText = "Enable 2FA";
    let topText = "2FA is not enabled";
    let buttonClass = cn('button-green button-small', styles.button);
    let cdaLoading = generateQR.loading;
    if (twoFactorAuthEnabled) {
      buttonClick = disable2FA;
      disabled = verifyCode.loading;
      buttonText = "Disable 2FA";
      topText = "2FA is already enabled, click below to disable it";
      buttonClass = cn('button-red button-small', styles.button);
      cdaLoading = verifyCode.loading;
    }
    return (
      <CardWrapper>
        <div>
          <div>
            <h3 className={styles.cardTopText}>
              {topText}
            </h3>
            <div className={styles.cardTopText}>
              <button 
                className={buttonClass}
                onClick={buttonClick} 
                disabled={disabled}
              >
                {cdaLoading ? <Loader/> : buttonText}
              </button>
            </div>
          </div>
        </div>
      </CardWrapper>
    );
  };
  
  function detectDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
    if (/android/i.test(userAgent)) {
        return 'android';
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
        return 'ios';
    } else {
        return 'other';
    }
}

  function openApp() {
      const device = detectDevice();
      let appLink;

      if (device === 'android') {
          appLink = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US';
        } else if (device === 'ios') {
          appLink = 'https://apps.apple.com/us/app/google-authenticator/id388497605';
        } else {
          appLink = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US';
        }

      window.open(appLink, '_blank');
  }
  
  return (
    <div>
      <div className="mt-5">
        <h1 className={cn('h3', styles.title)}>{("Two Factor Auth")}</h1>
        {getCardData()}
        {
          generateQR?.qrCode && (
            <CardWrapper className="mb-2 glass-card shadow">
              <div className={styles.qrCodeWrapper}>
                <div className="mb-4">
                  <div className={styles.code}>
                    {generateQR.loading ? <Loader></Loader> : 
                      <img src={generateQR.qrCode} alt="qr-code" width={200} height={200} />}
                  </div>
                </div>
                <div>
                  <div className={styles.btns}>
                    <p className={styles.text}>{("If you want to turn on 2FA, use Google Authenticator app to scan code,then enter six-digit code provided by the app to the form below.")}</p>
                    <button onClick={openApp} className={cn('button-stroke', styles.button)}>{("Download 2FA App")}</button>
                  </div>
                </div>
              </div>
            </CardWrapper>
          )
        }
        {showVerification && (
          <>
            <h1 className={styles.subtitle}>{("Enter Six-Digit Code")}</h1>
            <CardWrapper className="glass-card shadow">
              <div>
                <AuthCode
                  characters={6}
                  className="form-control form-control-lg text-center w-100"
                  allowedCharacters="numeric"
                  containerClassName="twofa-container"
                  inputClassName="twofa-input"
                  onChange={(a) => { focusInput2FA(a) }}></AuthCode>
              </div>
            </CardWrapper>
            <div className={styles.btns}>
              <button 
                className={cn('button-red', styles.button)}
                onClick={()=> enableDisableTwoFactorAuth(twoFACode)} 
                disabled={(verifyCode.loading || twoFACode?.length < 6)}>
                {("Verify")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default TwoFaEnabledDisabled
