import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import styles from './User.module.sass'
import Icon from '../../Icon'
import Theme from '../../Theme'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from 'store/actions'
import { useTranslation } from 'react-i18next'

const User = ({ className }) => {
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { clientData } = useSelector((state) => state?.Profile)
  const items = [
    {
      title: 'Profile',
      icon: 'user',
      content: 'Important account details',
      url: '/profile',
      type: 'url',
    },
    {
      title: 'Referrals',
      icon: 'share',
      content: 'Invite your friends and earn rewards',
      url: '/referrals',
      type: 'url',
    },
    {
      title: 'KYC',
      icon: 'share',
      content: 'Upload Kyc requirment',
      url: '/kyc',
      type: 'url',
    },
    {
      title: '2FA security',
      icon: 'lock',
      content: 'Setup 2FA for more security',
      url: '/2fa',
      type: 'url',
    },
    {
      title: 'Dark mode',
      icon: 'theme-dark',
      content: 'Switch dark/light mode',
      type: 'switch',
    },
    {
      title: 'Log out',
      icon: 'exit',
      type: 'handler',
      handler: () => {
        dispatch(logoutUser(navigate))
      },
    },
  ]

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(className, styles.user, { [styles.active]: visible })}>
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img src={clientData?.profileAvatar ? clientData?.profileAvatar : "/images/content/avatar-user.jpg"} alt="Avatar" />
        </button>
        <div className={styles.body}>
          <div className={styles.menu}>
            {items.map((x, index) => {
              switch (x.type) {
                case 'url':
                  return (
                    <Link
                      className={styles.item}
                      to={x.url}
                      onClick={() => setVisible(!visible)}
                      key={index}
                    >
                      <div className={styles.icon}>
                        <Icon name={x.icon} size="20" />
                      </div>
                      <div className={styles.details}>
                        <div className={styles.title}>{t(x.title)}</div>
                        <div className={styles.content}>{t(x.content)}</div>
                      </div>
                    </Link>
                  )
                case 'switch':
                  return (
                    <div className={styles.item} key={index}>
                      <div className={styles.icon}>
                        <Icon name={x.icon} size="20" />
                      </div>
                      <div className={styles.details}>
                        <div className={styles.line}>
                          <div className={styles.title}>{t(x.title)}</div>
                          <Theme className={styles.theme} small />
                        </div>
                        <div className={styles.content}>{t(x.content)}</div>
                      </div>
                    </div>
                  )
                case 'handler':
                  return (
                    <div
                      className={styles.item}
                      onClick={() => {
                        x.handler()
                        setVisible(!visible)
                      }}
                      key={index}
                    >
                      <div className={styles.icon}>
                        <Icon name={x.icon} size="20" />
                      </div>
                      <div className={styles.details}>
                        <div className={styles.title}>{t(x.title)}</div>
                        <div className={styles.content}>{t(x.content)}</div>
                      </div>
                    </div>
                  )
              }
            })}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default User
