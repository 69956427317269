export const UPLOAD_DOC_START = 'UPLOAD_DOC_START'
export const UPLOAD_DOC_END = 'UPLOAD_DOC_END'

export const GET_DOC_START = 'GET_DOC_START'
export const GET_DOC_END = 'GET_DOC_END'

export const UPLOADED_DOCS_CLEAR = 'UPLOADED_DOCS_CLEAR'
export const CHANGE_DOCS_CLEAR = 'CHANGE_DOCS_CLEAR'

export const CHANGESTATUS_DOC_START = 'CHANGESTATUS_DOC_START'
export const CHANGESTATUS_DOC_END = 'CHANGESTATUS_DOC_END'
export const UPDATE_STATUS_DOCS = 'UPDATE_STATUS_DOCS'

export const DELETE_DOC_START = 'DELETE_DOC_START'
export const DELETE_DOC_END = 'DELETE_DOC_END'
export const DELETE_CLEAR = 'DELETE_CLEAR'
