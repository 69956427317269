import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Table/Table.module.sass'
import cn from 'classnames'
import Dropdown from 'components/Dropdown'
import useDarkMode from 'use-dark-mode'
import TextInput from 'components/TextInput'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWallets } from 'store/actions'
import { getOrdersStart } from 'store/crypto/orders/actions'
const Status = [
    'open',
    'closed',
    'rejected',
  ]

  const types = [
    'sell',
    'buy',
  ]

  const sides = [
    'market',
    'limit',
  ]

const Orders = () => {

 const [ status, setStatus] = useState ("")
 const [dateFrom, setDateFrom] = useState("")
 const [dateTo, setDateTo] = useState("")
 const [ limit, setLimit ] = useState(5)
 const [selectedMarket, setSelectedMarket] = useState();
 const [marketsData, setMarkets] = useState();
 const [type, setType] = useState();
 const [side, setSide] = useState();


    const darkMode = useDarkMode(false)
    const customStyles = {
    width:'100%', 
     backgroundColor:darkMode ? '#23262F' : '#E6E8EC',
    height:'auto',
    borderRadius:'20px',
    marginBottom:'60px',
    paddingTop:'1px'
}
const dispatch = useDispatch();

const { markets } = useSelector((state) => state?.crypto.markets)
const { orders } = useSelector((state) => state?.crypto.orders)

useEffect(() => {
  dispatch(fetchWallets());
}, [dispatch]);


useEffect(() => {
  const filtered = markets?.map(market => market?.marketId?.pairName);
  setMarkets(filtered);
}, [ markets]); 

// handle api 
useEffect(() => {

  const requestData = {
    limit: limit,
    page: 1,
    ...(status !== undefined && status !== "" && { status }),
    ...(dateFrom !== undefined && dateFrom !== "" && { fromDate: dateFrom }),
    ...(dateTo !== undefined && dateTo !== "" && { toDate: dateTo }),
    ...(side !== undefined && side !== "" && { side: side }),
    ...(type !== undefined && type !== "" && { type: type }),
    ...(selectedMarket !== undefined && selectedMarket !== "" && { symbol: selectedMarket }),
  
  };

  
  dispatch(getOrdersStart(requestData));


  }, [ limit, dateTo, dateFrom, status, side, type, selectedMarket ]);


  return (
<>
<div style={customStyles}>
<h4 style={{ marginTop:'30px', marginLeft:'10px' }}>Filter by</h4>
<div className={styles.card}>

<div className={styles.field}   style={{ width:'31%' }}>
              <Dropdown   
                className={styles.dropdown}
                label="Type"
                value={type}
                setValue={setType}
                options={types}
              />
            </div>


           <div className={styles.field}  style={{ width:'31%' }}>
              <Dropdown
                className={styles.dropdown}
                label="Status"
                value={status}
                setValue={setStatus}
                options={Status}
              />
            </div>  
            
            <div className={styles.field}  style={{ width:'31%' }}>
              <Dropdown
                className={styles.dropdown}
                label="Side"
                value={side}
                setValue={setSide}
                options={sides}
              />
            </div>


            <div className={styles.field}  style={{ width:'31%' }}>
              <Dropdown
                className={styles.dropdown}
                label="Market"
                value={selectedMarket}
                setValue={setSelectedMarket}
                options={marketsData}
              />
            </div>   

            <div className={styles.field}  style={{ width:'31%' }}>
            <TextInput
              className={styles.field}
              label="From Date"
              onChange={(e)=>setDateFrom(e.target.value)}
              value={dateFrom}
              name="dateFrom"
              type="date"
              required
                />
                </div>
                <div className={styles.field}  style={{ width:'31%' }}>
            <TextInput
              className={styles.field}
              label="To Date"
              onChange={(e)=>setDateTo(e.target.value)}
              value={dateTo}
              name="dateTo"
              type="date"
              required
                />
                </div>
</div>
</div>

<div className={styles.table}>
      <div className={styles.row}>
      <div className={styles.col}>
          <div className="sorting">Symbol</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Date</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Type</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Side</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Amount</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Tp</div>
        </div>
        <div className={styles.col}>
          <div className="sorting"> Sl</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Price</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Status</div>
        </div>
      </div>
      {orders?.map((x, index) => (
        <Link className={styles.row} key={x._id} to='/buy-crypto'>
 
          <div className={styles.col}>
            <div className={styles.item}>
              <div className={styles.currency}>{x?.symbol?.split("/")[0]}</div>
            </div>
          </div>
          <div className={styles.col}>
            {x?.createdAt}
          </div>
          <div className={styles.col}>
            {x?.type}
          </div>
          <div className={styles.col}>
            {x?.side}
          </div>
          <div className={styles.col}>
            {x?.amount?.$numberDecimal ? x.amount.$numberDecimal : x.amount}
          </div>
          <div className={styles.col}>
          {x.paramsData ? x.paramsData.tp : ""}
                  </div>
                  <div className={styles.col}>
          {x.paramsData ? x.paramsData.sl : ""}
                  </div>       
          <div className={styles.col}>
          {x?.price?.$numberDecimal ? x.price.$numberDecimal : x.price}
                      </div>
          <div className={styles.col}>
          {x?.status}
          </div>
        </Link>
      ))}


    </div>

    <div style={{marginTop:'10px'}}>
<button
  style={{width:'100%'}}
  className={cn(
            'button-stroke button-small',
            // { [styles.active]: visible },
            styles.button,
          )}
          // eslint-disable-next-line react/jsx-indent-props
  // eslint-disable-next-line react/jsx-no-duplicate-props
  onClick={() => {
              setLimit(preValue => preValue + 5)
          }}
        >
          <span>Load More</span>
        </button>
</div>
</>
  )
}

export default Orders
