import FromikTextInput from 'components/FormComponents/TextInput'
import styles from './ContactusForm.module.sass'
import cn from 'classnames'
import { useFormik } from 'formik'
import FromikTextArea from 'components/FormComponents/TextArea'
import { useTranslation } from 'react-i18next'

const ContactUsForm = () => {
  const { t } = useTranslation()

  const handleSubmit = (values) => {
    alert('values', { values })
  }
  const contactUsForm = useFormik({
    initialValues: {
      nickName: '',
      email: '',
      password: '',
    },
    // validationSchema: EmailLoginSchema,
    onSubmit: handleSubmit,
  })

  return (
    <div className={cn(styles.formContainer)}>
      <div className={cn('form', styles.form)}>
        <FromikTextInput
          className={styles.field}
          label={t('Nick name')}
          name="nickName"
          type="nickName"
          placeholder={t('Nick name')}
          onChange={contactUsForm.handleChange}
          value={contactUsForm.values.nickName}
          error={contactUsForm.errors.nickName}
          touched={contactUsForm.touched.nickName}
          errClassName={styles.errMessage}
        />
        <FromikTextInput
          className={styles.field}
          label={t('Email')}
          name="email"
          type="email"
          placeholder={t('Email')}
          onChange={contactUsForm.handleChange}
          value={contactUsForm.values.email}
          error={contactUsForm.errors.email}
          touched={contactUsForm.touched.email}
          errClassName={styles.errMessage}
        />
        <FromikTextInput
          className={styles.field}
          label={t('Phone Number')}
          name="contact"
          type="contact"
          placeholder={t('Phone Number')}
          onChange={contactUsForm.handleChange}
          value={contactUsForm.values.contact}
          error={contactUsForm.errors.contact}
          touched={contactUsForm.touched.contact}
          errClassName={styles.errMessage}
        />
        <FromikTextArea
          className={styles.field}
          label={t('Resources')}
          name="resources"
          type="resources"
          placeholder={t('Resources')}
          onChange={contactUsForm.handleChange}
          value={contactUsForm.values.resources}
          error={contactUsForm.errors.resources}
          touched={contactUsForm.touched.resources}
          errClassName={styles.errMessage}
        />
        <button
          type="submit"
          className={cn('button', styles.button)}
          // onClick={goNext}
        >
          {t('Submit Request')}
        </button>
      </div>
    </div>
  )
}

export default ContactUsForm
