import { useTranslation } from 'react-i18next'
import styles from './SpotFees.module.sass'
import cn from 'classnames'

const coinPairsData = {
  BTCUSDT: { makerFee: 0.002, takerFee: 0.002 },
  ETHUSDT: { makerFee: 0.002, takerFee: 0.002 },
  BNBUSDT: { makerFee: 0.001, takerFee: 0.001 },
  ADAUSDT: { makerFee: 0.001, takerFee: 0.001 },
  ADABTC: { makerFee: 0.001, takerFee: 0.001 },
  XRPUSDT: { makerFee: 0.001, takerFee: 0.001 },
  DASHUSDT: { makerFee: 0.001, takerFee: 0.001 },
  ADAETH: { makerFee: 0.001, takerFee: 0.001 },
  DOTUSDT: { makerFee: 0.001, takerFee: 0.001 },
  TRXUSDT: { makerFee: 0.001, takerFee: 0.001 },
  DOGEUSDT: { makerFee: 0.003, takerFee: 0.003 },
  ALGOUSDT: { makerFee: 0.001, takerFee: 0.001 },
  LTCUSDT: { makerFee: 0.001, takerFee: 0.001 },
  BSVUSDT: { makerFee: 0.001, takerFee: 0.001 },
  MATICUSDT: { makerFee: 0.003, takerFee: 0.003 },
  BCHUSDT: { makerFee: 0.001, takerFee: 0.001 },
  ALIUSDT: { makerFee: 0.001, takerFee: 0.001 },
  AVAXUSDT: { makerFee: 0.003, takerFee: 0.003 },
  BTSUSDT: { makerFee: 0.001, takerFee: 0.001 },
  ATOMUSDT: { makerFee: 0.001, takerFee: 0.001 },
  UNIUSDT: { makerFee: 0.003, takerFee: 0.003 },
  '1INCHUSDT': { makerFee: 0.001, takerFee: 0.001 },
  ALPINEUSDT: { makerFee: 0.001, takerFee: 0.001 },
  AGIXUSDT: { makerFee: 0.001, takerFee: 0.001 },
  APTUSDT: { makerFee: 0.001, takerFee: 0.001 },
  ACHUSDT: { makerFee: 0.001, takerFee: 0.001 },
  ARBUSDT: { makerFee: 0.001, takerFee: 0.001 },
  ARUSDT: { makerFee: 0.001, takerFee: 0.001 },
  ACMUSDT: { makerFee: 0.001, takerFee: 0.001 },
  SUSHIUSDT: { makerFee: 0.001, takerFee: 0.001 },
  ASRUSDT: { makerFee: 0.001, takerFee: 0.001 },
  LINKUSDT: { makerFee: 0.003, takerFee: 0.003 },
  SWEATUSDT: { makerFee: 0.001, takerFee: 0.001 },
  ATMUSDT: { makerFee: 0.001, takerFee: 0.001 },
  XLMUSDT: { makerFee: 0.003, takerFee: 0.003 },
  AXSUSDT: { makerFee: 0.001, takerFee: 0.001 },
  NEARUSDT: { makerFee: 0.003, takerFee: 0.003 },
  ETCUSDT: { makerFee: 0.001, takerFee: 0.001 },
  CROUSDT: { makerFee: 0.003, takerFee: 0.003 },
  BARUSDT: { makerFee: 0.001, takerFee: 0.001 },
  BCHBTC: { makerFee: 0.001, takerFee: 0.001 },
  XMRUSDT: { makerFee: 0.003, takerFee: 0.003 },
  COMPUSDT: { makerFee: 0.001, takerFee: 0.001 },
}

const SpotFees = () => {
  const { t } = useTranslation()

  return (
    <div className={'container'}>
      <div className={'section'}>
        <div>
          <div className={cn('h3', styles.title)}>Trading Fees</div>
          <table>
            <thead>
              <tr>
                <th>COIN PAIR</th>
                <th>MAKER FEE</th>
                <th>TAKER FEE</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(coinPairsData).map((pair) => (
                <tr key={pair}>
                  <td className={styles.pairNameCol}>{pair}</td>
                  <td>{coinPairsData[pair].makerFee}</td>
                  <td>{coinPairsData[pair].takerFee}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SpotFees
