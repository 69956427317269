import {
  ChevronDown,
  ChevronRight,
} from 'feather-icons-react/build/IconComponents'
import styles from './LeveragePannel.module.sass'
import { useState } from 'react'
import Modal from 'components/Modal'
import AdjustLeverage from 'components/AdjustLeverage'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

const leverages = [1, 2, 3, 5, 10, 20, 30, 50, 75, 100]

const LeveragePannel = () => {
  const [selectedOption, setSelectedOption] = useState('Cross')
  const [visibleModal, setVisibleModal] = useState(false)
  const [openOptions, setOpenOptions] = useState(false)

  const handleClickOption = (value) => {
    setSelectedOption(value)
    setOpenOptions(false)
  }
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.pannel}>
        <div className={cn('isolated', styles.isolated)}>
          <span onClick={() => setOpenOptions(!openOptions)}>
            {selectedOption} <ChevronDown size={16} />
          </span>
          {openOptions && (
            <div className={styles.options}>
              <div
                className={styles.option}
                onClick={() => handleClickOption('Cross')}
              >
                {t('Cross')} ({t('BTCUSDT Perpetual')})
              </div>
              <div
                className={styles.option}
                onClick={() => handleClickOption('Isolated')}
              >
                {t('Isolated')} ({t('BTCUSDT Perpetual')})
              </div>
            </div>
          )}
        </div>
        {selectedOption === 'Isolated' ? (
          <>
            <div
              className={cn('leverage', styles.leverageL)}
              onClick={() => setVisibleModal(true)}
            >
              L 5.00x <ChevronRight size={16} />
            </div>
            <div
              className={cn('leverage', styles.leverageS)}
              onClick={() => setVisibleModal(true)}
            >
              S 5.00x <ChevronRight size={16} />
            </div>
          </>
        ) : (
          <div
            className={cn('leverage', styles.leverage)}
            onClick={() => setVisibleModal(true)}
          >
            5.00x <ChevronRight size={16} />
          </div>
        )}
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <AdjustLeverage
          currency={'BTC/USDT'}
          leverageType={selectedOption}
          buySell={'Buy'}
          leverages={leverages}
        />
      </Modal>
    </>
  )
}

export default LeveragePannel
