import styles from './Banners.module.sass'

const banners = ['Affiliate', 'Mock', 'Invite', 'Reward']
const banners2 = ['become-affiliate-banner', 'learn-trading']

const Banners = () => {
  return (
    <div className={styles.main}>
      <div className="container">
        <div className={styles.imageList}>
          {banners2.map((banner, index) => (
            <div key={index} className={styles.imageItem}>
              <img
                src={`/images/content/crypto/new-crypto-images/${banner.toLowerCase()}.png`}
                className={styles.image}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Banners
