import { call, put, takeEvery } from 'redux-saga/effects'
// Login Redux States
import { MARKETS_FETCH, UPDATE_FAVORITE } from './actionTypes'
import {
  fetchMarketsFailed,
  fetchMarketsSuccessful,
  updateFevouriteFail,
  updateFevouriteSuccess,
} from './actions'

//Include Both Helper File with needed methods
import * as marketsApi from '../../../apis/markets'
import { showErrorNotification, showSuccessNotification } from 'store/actions'

function* fetchMarkets(params) {
  try {
    const data = yield call(marketsApi.fetchMarkets, params)

    yield put(fetchMarketsSuccessful(data))
  } catch (error) {
    yield put(fetchMarketsFailed(error))
  }
}

function* updateFavourite(params) {
  try {
    const data = yield call(marketsApi.updateFavorite, params)
    yield call(fetchMarkets, {})
    yield put(updateFevouriteSuccess({ data }))
    yield put(showSuccessNotification('Symbols updated successfully.'))
  } catch (error) {
    console.log('Error', error)
    yield put(updateFevouriteFail({ error }))
    // yield put(showErrorNotification(error.message || "Failed to update symbols."));
  }
}
function* marketsSaga() {
  yield takeEvery(MARKETS_FETCH, fetchMarkets)
  yield takeEvery(UPDATE_FAVORITE, updateFavourite)
}

export default marketsSaga
