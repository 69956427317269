import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Main.module.sass'
import Icon from '../../../components/Icon'
import { useTranslation } from 'react-i18next'
import { usePricingMonitor } from 'hooks/usePricingMonitor'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

const items = [
  {
    title: 'high',
    content: '520.80 +1.25%',
    icon: 'arrow-top',
  },
  {
    title: 'low',
    content: '520.80 +1.25%',
    icon: 'arrow-bottom',
  },
  {
    title: 'volume',
    content: '75.655.26',
    icon: 'chart',
  },
]

const Main = (props) => {
  const { t } = useTranslation()
  const { selectedMarket } = props
  const pricingData = usePricingMonitor(selectedMarket)
  const [walletType, setWalletType] = useState('trading')
  const [walletPrice, setWalletPrice] = useState([])
  const location = useLocation()
  
  const { allWallets } = useSelector((state) => state?.walletReducer)

  useEffect(()=>{
    const locationRoutes = location?.pathname?.split('/')
    if (locationRoutes?.includes('margin')){
      setWalletType('margin')
    }
    if (locationRoutes?.includes('futures')){
      setWalletType('futures')
    }
  }, [location])
  
  useEffect(()=>{
    const wallet = allWallets?.[walletType]?.filter(wallett => {
      return selectedMarket?.split('/')?.[0 ] === wallett?.asset
    })?.[0]
    setWalletPrice( wallet)
  }, [walletType, allWallets])

  if (!pricingData || !selectedMarket) {
    return null
  }
  return (
    <div className={styles.main}>
      <div className={styles.details}>
        <div className={cn('box', styles.box)}>
          <div className={styles.line}>
            <div className={styles.info}>{pricingData?.instanceId}</div>
            {/* <div className={cn('category-green', styles.category)}>10x</div> */}
          </div>
          {/* <div className={styles.content}>{pricingData?.instanceId}</div> */}
        </div>
        <div className={cn('box', styles.box)}>
          <div className={
            parseFloat(pricingData?.percentage ?? pricingData?.percentage?.$numberDecimal) >= 0 ? styles.positive : styles.negative
          }>
            {/* {walletPrice?.usdPrice || pricingData?.currentPrice} {" "} */}
            USDT
          </div>
          <div className={styles.content}>
            {/* <Icon name="coin" size="16" /> {} */}
          </div>
        </div>
      </div>
      {/* <div className={cn('list', styles.list)}>
        {items.map((x, index) => {
          const getData = () => {
            switch (x.title) {
              case 'high':
                return pricingData?.high24h
              case 'low':
                return pricingData?.low24h
              case 'volume':
                return pricingData?.volume24h
              default:
                return ''
            }
          }
          return <div className={cn('item', styles.item)} key={index}>
            <div className={cn('subtitle', styles.subtitle)}>
              <Icon name={x.icon} size="16" />
              24h {t(x.title)}
            </div>
            <div className={styles.content} style={{ color: x.color }}>
              {getData()}
            </div>
          </div>
        })}
      </div> */}
    </div>
  )
}

export default Main
