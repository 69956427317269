export const REQ_IB_START = 'REQ_IB_START'
export const REQ_IB_SUCCESS = 'REQ_IB_SUCCESS'
export const GET_IB_REQUEST_STATUS = 'GET_IB_REQUEST_STATUS'
export const REQ_IB_FAIL = 'REQ_IB_FAIL'
export const GET_IB_REQUEST_STATUS_SUCCESS = 'GET_IB_REQUEST_STATUS_SUCCESS'
export const GET_IB_REQUEST_STATUS_FAIL = 'GET_IB_REQUEST_STATUS_FAIL'

// change leverage request
export const CREATE_CHANGE_LEVERAGE_REQ_REQUESTED =
  'CREATE_CHANGE_LEVERAGE_REQ_REQUESTED'
export const CREATE_CHANGE_LEVERAGE_REQ_SUCCESS =
  'CREATE_CHANGE_LEVERAGE_REQ_SUCCESS'
export const CREATE_CHANGE_LEVERAGE_REQ_FAIL = 'CREATE_CHANGE_LEVERAGE_REQ_FAIL'
