import React from 'react'
import PropTypes from 'prop-types'
import { Route, Navigate } from 'react-router-dom'

const AuthMiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isNotAuthProtected,
  ...rest
}) => {
  console.log(
    'isAuthProtectedisAuthProtected',
    isAuthProtected,
    isNotAuthProtected,
  )
  if (isAuthProtected && !localStorage.getItem('authUser')) {
    return <Navigate to="/login" />
  }
  // if (isNotAuthProtected && localStorage.getItem('authUser')) {
  //   return <Navigate to="/home" />
  // }
  return (
    <Layout
      headerHide={rest.headerHide}
      footerHide={rest.footerHide}
      headerWide={rest.headerWide}
      stickyHeader={rest.stickyHeader}
    >
      <Component {...rest} />
    </Layout>
  )
}

export default AuthMiddleware
