import React, { useState } from 'react'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import styles from './InputDropdown.module.sass'
import Icon from '../Icon'

const InputDropdown = ({
  adornmentText,
  className,
  classLabel,
  value,
  setValue,
  options,
  label,
  inputFieldName,
  classDropdownHead,
  classDropdownArrow,
  classDropdownBody,
  classDropdownOption,
  handleChangeInput,
  inputValue,
}) => {
  const [visible, setVisible] = useState(false)
  const [inputVisible, setInputVisible] = useState(true)

  const handleClick = (value) => {
    setValue(value)
    setVisible(false)
    handleChangeMarketOrder(value)
  }

  const handleChangeMarketOrder = (value) => {
    setInputVisible(value === inputFieldName ? false : true)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div
        className={cn('dropdown', styles.dropdown, className, {
          [styles.active]: visible,
        })}
      >
        <div
          className={cn(classDropdownHead, styles.head, 'head')}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.inputFieldsContainer}>
            <div>
              {value}
              <Icon name="arrow-down" size="24" />
            </div>
            {inputVisible && (
              <input
                autoFocus
                className={styles.inputField}
                type="number"
                name={inputFieldName}
                value={inputValue}
                onChange={(e) => handleChangeInput(e.target.value)}
              />
            )}
          </div>
          <div className={cn('arrow', styles.arrow, classDropdownArrow)}>
            <span className={cn('adornmentText', styles.adornmentText)}>
              {inputVisible ? adornmentText : 'Market'}
            </span>
          </div>
        </div>
        <div className={cn(classDropdownBody, styles.body)}>
          {options.map((x, index) => (
            <div
              className={cn(classDropdownOption, styles.option, {
                [styles.selectioned]: x === value,
              })}
              onClick={() => handleClick(x, index)}
              key={index}
            >
              {x}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default InputDropdown
