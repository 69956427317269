import qs from 'qs'
import * as axiosHelper from './api_helper'

export async function getOrders(params) {
  const result = await axiosHelper.get(`/orders?${qs.stringify(params)}`)
  return result
}

export const postOrderAPI = async (params) => {
  let reqParams = {}
  if (params.orderType === 'market') {
    if (params.type === 'buy') {
      reqParams = {
        symbol: params.market.pairName,
        type: params.orderType,
        side: params.type,
        amount: `${params.baseAmount}`,
      }
    } else if (params.type === 'sell') {
      reqParams = {
        symbol: params.market.pairName,
        type: params.orderType,
        side: params.type,
        amount: `${params.baseAmount}`,
      }
    }
  } else if (params.orderType === 'limit') {
    if (params.type === 'buy') {
      reqParams = {
        symbol: params.market.pairName,
        type: params.orderType,
        side: params.type,
        amount: `${params.baseAmount}`,
        price: params.quoteAmount,
      }
    } else if (params.type === 'sell') {
      reqParams = {
        symbol: params.market.pairName,
        type: params.orderType,
        side: params.type,
        amount: `${params.baseAmount}`,
        price: params.quoteAmount,
      }
    }
  } else if (params.orderType === 'conditional') {
    if (params.type === 'buy') {
      reqParams = {
        symbol: params.market.pairName,
        type: params.orderType,
        side: params.type,
        amount: `${params.baseAmount}`,
        price: params.price,
        triggerPrice: params.triggerPrice,
        tpType: params.tpType,
      }
    } else if (params.type === 'sell') {
      reqParams = {
        symbol: params.market.pairName,
        type: params.orderType,
        side: params.type,
        amount: `${params.baseAmount}`,
        price: params.price,
        triggerPrice: params.triggerPrice,
        tpType: params.tpType,
      }
    }
  } else if (params.orderType === 'oco') {
    if (params.type === 'buy') {
      reqParams = {
        symbol: params.market.pairName,
        type: params.orderType,
        side: params.type,
        amount: `${params.baseAmount}`,
        tpTriggerPrice: params.tpTriggerPx,
        tpOrderType: params.tpType,
        tpPrice: params.tpPrice,
        slTriggerPrice: params.slTriggerPx,
        slOrderType: params.slType,
        slPrice: params.slPrice,
      }
    } else if (params.type === 'sell') {
      reqParams = {
        symbol: params.market.pairName,
        type: params.orderType,
        side: params.type,
        amount: `${params.baseAmount}`,
        tpTriggerPrice: params.tpTriggerPx,
        tpOrderType: params.tpType,
        tpPrice: params.tpPrice,
        slTriggerPrice: params.slTriggerPx,
        slOrderType: params.slType,
        slPrice: params.slPrice,
      }
    }
  }

  const result = await axiosHelper.post('orders', reqParams)
  if (result.status) {
    return result
  } else throw new Error(result.message)
}
