import cn from 'classnames'
import CashReward from './CashReward'
import Progress from './Progress'

const TaskCentre = () => {
  return (
    <div className={cn('container')}>
      <CashReward />
      <Progress />
    </div>
  )
}

export default TaskCentre
