import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Successfully.module.sass'
import { Link } from 'react-router-dom'


const Successfully = ({ responseData, setVisibleSuccessfully, setVisibleConvert, onClose }) => {

  const handleClick = ()=>{
    setVisibleSuccessfully(false)
    setVisibleConvert(false)
    }
  return (
    <>
      <div className={styles.successfully}>
        <div className={cn('h2', styles.title)}>
          Yay!{' '}
          <span role="img" aria-label="firework">
            🎉
          </span>
        </div>
        <div style={{textAlign:'center'}}>
Your Convert was successful for ${responseData?.amount?.$numberDecimal} {responseData?.fromAsset}  to  ${responseData?.toAmount?.$numberDecimal} {responseData?.toAsset}        </div>
        <button onClick={handleClick} className={cn('button', styles.button)} to="/dashboard">
          Wallet
        </button>
      </div>
    </>
  )
}

export default Successfully
