import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Deposite.module.sass'
import Icon from '../Icon'
import TextInput from '../TextInput'
import Checkbox from '../Checkbox'
import Successfully from './Successfully'
import Dropdown from 'components/Dropdown'
import { useSelector } from 'react-redux'
import DropdownWithId from 'components/DropdownCoins'
import DropdownCoins from 'components/DropdownCoins'
import DropdownDynamic from 'components/DropdownDynamic'
import { addWithdrawCrypto } from 'apis/withdraw'
import Loader from 'components/Loader'
import { toast } from 'react-toastify'
import QRCode from 'qrcode.react'
import { addDeposit } from 'apis/deposit'
const languageOptions = ['sss', 'wwww']
const Deposite = ({setVisibleDeposite}) => {
  const [save, setSave] = useState(true)
  const [responseData, setResponseData] = useState([])
  const [walletsOptions, setWalletsOptions] = useState([])
  const [networksOptions, setNetworksOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [visibleWithdraw, setVisibleWithdraw] = useState(true)
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false)
  const [address, setAddress] = useState('')
  const [coin, setCoin] = useState('')
  const [totalPrice, setTotalPrice] = useState(0)
  const [transactionFees, setTransactionFees] = useState(0)
  const [amount, setAmount] = useState()
  const [withdrawAmount, setWithdrawAmount] = useState()
  const [network, setNetwork] = useState('')

  const {wallets, allWallets} = useSelector((state) => state?.crypto?.wallets)

  const handleClick = () => {
    // setVisibleWithdraw(false)
    // setVisibleSuccessfully(true)
    setVisibleDeposite(false)
  }

  function validateInputs(){
    let status = true
    if (!coin) {
      toast.error(('Coin Is Required'))
      status = false
    }
    if (!network) {
      toast.error(('Network Is Required'))
      status = false
    }
    // if (!withdrawAmount) {
    //   toast.error(('Withdraw Amount Is Required'))
    //   status = false
    // }
    return status
  }

  const sendData = async() => {
    try {
      if (!validateInputs()) {
        return
      }
      setIsLoading(true)
      let sendedData = {
        gateway: "BLOCKCHAIN",
        walletId: coin?._id,
        amount: withdrawAmount,
        note: "note",
      }
      // addDeposit({
      //   gateway: gateway,
      //   walletId: selectedWallet?.value,
      //   amount: amount,
      //   note: "note",
      // })
      let data = await addDeposit(sendedData)

      setResponseData(data?.result)
      setIsLoading(false)
      if (data?.isSuccess) {
        setVisibleWithdraw(false)
        // setVisibleSuccessfully(true)
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }
  useEffect(()=>{
    setWalletsOptions(allWallets?.funding || [])
  }, [allWallets])
  useEffect(()=>{
    setAmount(coin?.amount)
  }, [coin])
  useEffect(()=>{
    setAddress(network?.address)
  }, [network])

  return (
    <>
      {visibleWithdraw && (
        <div className={styles.withdraw}>
          <div className={cn('h4', styles.title)}>
            <Icon name="arrow-left" size="32" />
            Deposite USDT
          </div>
          <div>
            <label className={styles.label}>Coin/Wallet</label>
            <DropdownCoins
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={coin}
              setValue={setCoin}
              options={walletsOptions}
            />
          </div>
          <div>
            <label className={styles.label}>Network</label>
            <DropdownDynamic
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={network}
              setValue={setNetwork}
              options={coin?.networks}
              uniqueName={'chainId?.name'}
              uniqueId={'chainId?._id'}
            />
          </div>
          
          {/* <TextInput
            className={styles.field}
            label="address"
            name="address"
            type="text"
            note="Do not send Tether USD unless you are certain the destination supports
        TRC-20 transactions. If it does not, you could permanently lose access
        to your coins."
            onChange={(e)=>setAddress(e?.target?.value)}
            value={address}
            required
            disabled
          /> */}
          <div className={cn(styles.addressText, styles.field)}>
            {address ? <>
              {address}
              <button onClick={() => {
                navigator.clipboard.writeText(address);
                toast.success('Text copied to clipboard successfully');
              }}>
                <Icon name="copy" size="24"/>
              </button>
            </> : "Select wallet"}
          </div>
          {/* <div className={styles.box}>
            <TextInput
              className={styles.field}
              label="Amount to withdraw"
              name="amount"
              type="number"
              note="$1,000,000.00 daily withdrawal limit remaining."
              onChange={(e)=>setWithdrawAmount(e?.target?.value)}
              value={withdrawAmount}
              required
            />
            <button className={cn('button-stroke button-small', styles.button)}>
              Max amount
            </button>
          </div> */}
          {
            address &&
            <>
              <div className={styles.qrWrapper}>
                <QRCode size={250} value={network?.address} renderAs="canvas" className='mx-auto' />
              </div>
              <div className={styles.noteWrapper}>
                <p className={styles.note}>{(`Send only ${coin?.asset} to this deposit address.`)}</p>
                <p className={styles.note}>{(`Ensure the network is ${network?.chainId?.name}.`)}</p>
                <p className={styles.note}>
                  {("Do not send NFT to this address. Learn how to deposit NFTs")}
                </p>
              </div>
            </>
          }
          <button
            type="submit"
            disabled={isLoading}
            className={cn('button', styles.button)}
            onClick={handleClick}
          >
            {isLoading ? <Loader /> : 'Deposite' }
          </button>
        </div>
      )}
      {visibleSuccessfully && <Successfully responseData={responseData} handleClick={handleClick}/>}
    </>
  )
}

export default Deposite
