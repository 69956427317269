import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Table/Table.module.sass'
import cn from 'classnames'
import Dropdown from 'components/Dropdown'
import useDarkMode from 'use-dark-mode'
import TextInput from 'components/TextInput'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWallets } from 'store/actions'
import { getDepositsStart } from 'store/crypto/history/actions'
import Icon from 'components/Icon'
const Status = [
    'PENDING',
    'APPROVED',
    'REJECTED',
  ]

  const Gateway = [
    'BLOCKCHAIN',
  ]
const Deposit = () => {

 const [assetWallet, setAssetWallet ] =  useState("")
 const [ status, setStatus] = useState ("")
 const [gateway, setGateway] = useState("")
 const [dateFrom, setDateFrom] = useState("")
 const [dateTo, setDateTo] = useState("")
 const [ limit, setLimit ] = useState(5)


    const darkMode = useDarkMode(false)
    const customStyles = {
    width:'100%', 
     backgroundColor:darkMode ? '#23262F' : '#E6E8EC',
    height:'auto',
    borderRadius:'20px',
    marginBottom:'60px',
    paddingTop:'1px'
}
const dispatch = useDispatch();

const { wallets } = useSelector((state) => state?.walletReducer)
const { deposits } = useSelector((state) => state?.crypto?.historyReducer)

useEffect(() => {
  dispatch(fetchWallets());
}, [dispatch]);

const [filteredData, setFilteredData] = useState([]);
useEffect(() => {
  const filtered = wallets?.map(item => item.asset);
  setFilteredData(filtered);
}, [ wallets]); 

console.log("Wallettstss", deposits);
// handle api 
useEffect(() => {

  const requestData = {
    limit: limit,
    page: 1,
    ...(status !== undefined && status !== "" && { status }),
    ...(gateway !== undefined && gateway !== "" && { gateway }),
    ...(dateFrom !== undefined && dateFrom !== "" && { fromDate: dateFrom }),
    ...(dateTo !== undefined && dateTo !== "" && { toDate: dateTo }),
    ...(assetWallet !== undefined && assetWallet !== "" && { currency: assetWallet }),
  
  };

  
  dispatch(getDepositsStart(requestData));


  }, [ limit, dateTo, dateFrom, assetWallet, status, gateway ]);


  return (
<>
<div style={customStyles}>
<h4 style={{ marginTop:'30px', marginLeft:'10px' }}>Filter by</h4>
<div className={styles.card}>

<div className={styles.field}   style={{ width:'31%' }}>
              <Dropdown   
                className={styles.dropdown}
                label="Currency"
                value={assetWallet}
                setValue={setAssetWallet}
                options={filteredData}
              />
            </div>
            <div className={styles.field}  style={{ width:'31%' }}>
              <Dropdown
                className={styles.dropdown}
                label="Status"
                value={status}
                setValue={setStatus}
                options={Status}
              />
            </div>


            <div className={styles.field}  style={{ width:'31%' }}>
              <Dropdown
                className={styles.dropdown}
                label="Gateway"
                value={gateway}
                setValue={setGateway}
                options={Gateway}
              />
            </div>   

            <div className={styles.field}  style={{ width:'48%' }}>
            <TextInput
              className={styles.field}
              label="From Date"
              onChange={(e)=>setDateFrom(e.target.value)}
              value={dateFrom}
              name="dateFrom"
              type="date"
              required
                />
                </div>
                <div className={styles.field}  style={{ width:'48%' }}>
            <TextInput
              className={styles.field}
              label="To Date"
              onChange={(e)=> setDateTo(e.target.value)}
              value={dateTo}
              name="dateTo"
              type="date"
              required
                />
                </div>
</div>
</div>

<div className={styles.table}>
      <div className={styles.row}>
      <div className={styles.col}>
          <div className="sorting">Asset</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Date</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Amount</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Gateway</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Status</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">On Orders</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Available Balance</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Total Balance</div>
        </div>
      </div>
      {deposits?.map((x, index) => (
        <Link className={styles.row} key={x._id} to='/buy-crypto'>
 
          <div className={styles.col}>
            <div className={styles.item}>
              <div className={styles.currency}>{x.walletId ? x.walletId.asset : ""}</div>
            </div>
          </div>
          <div className={styles.col}>
            {x?.createdAt}
          </div>
          <div className={styles.col}>
            {x?.amount?.$numberDecimal ? x?.amount?.$numberDecimal : x?.amount}
          </div>
          <div className={styles.col}>
            {x?.gateway}
          </div>
          <div className={styles.col}>
            {x?.status}
          </div>
          <div className={styles.col}>
            {x?.walletId?.freezeAmount?.$numberDecimal ? x?.walletId?.freezeAmount?.$numberDecimal : x?.walletId?.freezeAmount}
          </div>
          <div className={styles.col}>
            {x?.walletId?.amount?.$numberDecimal ? x.walletId.amount.$numberDecimal : x?.walletId?.amount}
          </div>
          <div className={styles.col}>
          {x?.walletId?.freezeAmount}
          </div>
        </Link>
      ))}


    </div>

    <div style={{marginTop:'10px'}}>
<button
  style={{width:'100%'}}
  className={cn(
            'button-stroke button-small',
            // { [styles.active]: visible },
            styles.button,
          )}
          // eslint-disable-next-line react/jsx-indent-props
  // eslint-disable-next-line react/jsx-no-duplicate-props
  onClick={() => {
              setLimit(preValue => preValue + 5)
          }}
        >
          <span>Load More</span>
        </button>
</div>
</>
  )
}

export default Deposit
