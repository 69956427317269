import cn from 'classnames'
import styles from './ContactUs.module.sass'
import ContactUsForm from './ContactUsForm'
import { useTranslation } from 'react-i18next'

const ContactUs = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={cn('h2', styles.contactUsTitle)}>{t('Contact us')}</div>
      <div className={cn('section', styles.contactUsContainer)}>
        <ContactUsForm />
        {/* <div>
          <img src="/images/content/reward/affiliate/contact-us.webp" />
        </div> */}
      </div>
    </>
  )
}

export default ContactUs
