import React from 'react'
import cn from 'classnames'
import styles from './AffiliateEasySteps.module.sass'
import { useTranslation } from 'react-i18next'

const items = [
  {
    title: 'Sign Up',
    image2x: '/images/content/step-1@2x.png',
    image: '/images/content/reward/affiliate/affiliate-step-number1.webp',
    content:
      'Become a memeber of the FiniticX Crypto Affiliate Program effortlessly',
  },
  {
    title: 'Promote',
    image2x: '/images/content/step-2@2x.png',
    image: '/images/content/reward/affiliate/affiliate-step-number2.webp',
    content: 'Use our promotional tools and Share FiniticX with your audience',
  },
  {
    title: 'Earn',
    image2x: '/images/content/step-3@2x.png',
    image: '/images/content/reward/affiliate/affiliate-step-number3.webp',
    content:
      'Benefits from up to 50% in Commissions on refferals and Enjoy our extraordinary bonuses',
  },
]

const AffiliateEasySteps = ({ scrollToRef }) => {
  const { t } = useTranslation()

  return (
    <div className={cn('section', styles.section)} ref={scrollToRef}>
      <div className={cn('container', styles.container)}>
        <div className={styles.head}>
          <h2 className={cn('h2', styles.title)}>{t('How it works')}</h2>
          <div className={styles.info}>
            {t(
              'Stacks is a production-ready library of stackable content blocks built in React Native.',
            )}
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={cn('custom-item', styles.item)} key={index}>
              <div className={cn('custom-preview', styles.preview)}>
                <img
                  srcSet={`${x.image} 2x`}
                  src={x.image}
                  alt={`Step ${index + 1}`}
                />
              </div>
              <div className={styles.subtitle}>{t(x.title)}</div>
              <div className={styles.content}>{t(x.content)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AffiliateEasySteps
