export const FETCH_IB_AGREEMENTS_START = 'FETCH_IB_AGREEMENTS_START'
export const FETCH_IB_AGREEMENTS_SUCCESS = 'FETCH_IB_AGREEMENTS_SUCCESS'

export const FETCH_REFERRALS_START = 'FETCH_REFERRALS_START'
export const FETCH_REFERRALS_SUCCESS = 'FETCH_REFERRALS_SUCCESS'

export const FETCH_STATEMENT_START = 'FETCH_STATEMENT_START'
export const FETCH_STATEMENT_SUCCESS = 'FETCH_STATEMENT_SUCCESS'
export const FETCH_STATEMENT_DEALS_START = 'FETCH_STATEMENT_DEALS_START'
export const FETCH_STATEMENT_DEALS_SUCCESS = 'FETCH_STATEMENT_DEALS_SUCCESS'
