import cn from 'classnames'
import styles from './ReferralProgram.module.sass'
import Steps from './steps'
import { useTranslation } from 'react-i18next'
import EarnLikeBanner from './EarBanner'

const ReferralProgram = () => {
  const { t } = useTranslation()
  const commissionSteps = [
    {
      imgSrc: '/images/content/reward/referralProgram/EarnCommision.png',
      title: 'Send Invitation Code or Link to Others',
    },
    {
      imgSrc: '/images/content/reward/referralProgram/OtherUsers.svg',
      title: 'Other Users Register and Trade',
    },
    {
      imgSrc: '/images/content/reward/referralProgram/SendInvitationCode.png',
      title: 'Earn Commission from Trading Fees',
    },
  ]

  const productAdvantages = [
    {
      imgSrc:
        '/images/content/crypto/new-crypto-images/high-commission-rate.png',
      title: 'High Commission Rate',
      content:
        'We provide high commission rate for brokers and support commission for 2 levels to maximize the return for brokers',
    },
    {
      imgSrc:
        '/images/content/crypto/new-crypto-images/quantifiable-commission-record.png',
      title: 'Quantifiable Commission Record',
      content:
        'You can view detailed commission record, daily commission, trading details at personal center',
    },
    {
      imgSrc:
        '/images/content/crypto/new-crypto-images/operate-earn-commission.png',
      title: 'Easy to operate, three steps to earn commission',
      content:
        'Easy to operate, only need to share invitation code, you can earn commission after other users fill in the invitation code and trade',
    },
  ]

  return (
    <div className={cn('section', styles.main)}>
      <div className={cn('container')}>
        <div className={cn('section', styles.referralContainer)}>
          <img
            src="/images/content/crypto/new-crypto-images/affiliate-page-banner.png"
            height={400}
            className={styles.bannerImage}
          />
          <div className={cn('container', styles.bannerContent)}>
            <h1 className={cn('h1', styles.title)}>
              {t('Refer & Win Rewards')}
            </h1>
            <div className={styles.subTitle}>
              {t(
                'Join the FiniticX Affiliate Program today and Gain up to 50% commission on each referral',
              )}
            </div>
          </div>
        </div>
        <div className={styles.earnCommissionContainer}>
          <h2 className={cn('h2', styles.earnCommissionTitle)}>
            {t('Three Steps to Earn Commission')}
          </h2>
          <Steps />
        </div>
        <div className="section">
          <div className={cn('h3', styles.brokerProductTitle)}>
            {t('Our Broker Product Advantages')}
          </div>
          <div className={styles.productAdvantageItemContainer}>
            {productAdvantages.map((advantage, index) => (
              <div className={styles.productAdvantageItem} key={index}>
                <img
                  src={advantage.imgSrc}
                  height={96}
                  alt={`Advantage ${index + 1}`}
                />
                <div>
                  <div className={styles.productAdvantageItemTitle}>
                    {t(advantage.title)}
                  </div>
                  <div>
                    <div className={styles.productAdvantageItemContent}>
                      {t(advantage.content)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <EarnLikeBanner />
      </div>
    </div>
  )
}

export default ReferralProgram
