import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import {
  Layout as LayoutIcon,
  X,
} from 'feather-icons-react/build/IconComponents'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedLayout } from 'store/actions'
import styles from './Layout.module.sass'
import { useTranslation } from 'react-i18next'

const layoutOptions = [
  { label: 'Standard', image: 'bottom' },
  {
    label: 'Advanced (left)',
    image: 'left',
  },
]

const Layout = () => {
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { selectedLayout } = useSelector(
    (state) => state?.crypto?.layoutReducer,
  )

  const handleSelectedLayout = (layout) => {
    dispatch(getSelectedLayout(layout))
  }

  useEffect(() => {
    if (visible) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }, [visible])

  return (
    <>
      <button
        className={cn('layout-head', styles.head, styles.active)}
        onClick={() => setVisible(!visible)}
      >
        <LayoutIcon size="24" />
      </button>
      {visible && (
        <div className={styles.layoutContainer}>
          <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div className={styles.layoutSidebar}>
              <div className={styles.headerTitle}>
                <h3>{t('Layout')}</h3>
                <button
                  className={cn(styles.head, styles.active)}
                  onClick={() => setVisible(!visible)}
                >
                  <X size="24" />
                </button>
              </div>
              <div className={styles.layoutOptions}>
                {layoutOptions.map((item, index) => (
                  <div key={index}
                    className={styles.layoutItem}
                    onClick={() => handleSelectedLayout(item.label)}
                  >
                    <img
                      
                      src={`/images/content/crypto/${item.image}-layout.png`}
                      className={
                        selectedLayout === item.label ? styles.activeLayout : ''
                      }
                    />
                    <span key={index}>{t(item.label)}</span>
                  </div>
                ))}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      )}
    </>
  )
}

export default Layout
