import React, { useState } from 'react'
import styles from './Form.module.sass'
import Action from './Action'
import { useMediaQuery } from 'react-responsive'
import Icon from '../../../../../components/Icon'
import { useSelector } from 'react-redux'
import OpenClosePannel from 'screens/Exchange/Futures/Actions/OpenClosePannel'
import { useTranslation } from 'react-i18next'

const Form = ({
  amount,
  contentBuy,
  contentSell,
  conditionalOrOCO,
  limit,
  price,
  stop,
  triggerPrice,
  visible,
  setValue,
  total,
  handleChange,
  takeProfit,
  stopLoss,
  orders,
  isMarket,
  isTpSl,
}) => {
  const { t } = useTranslation()
  const [isBuy, setIsBuy] = useState(true)
  const isTablet = useMediaQuery({ query: '(max-width: 1023px)' })

  const { selectedLayout } = useSelector(
    (state) => state?.crypto?.layoutReducer,
  )

  const handleChangeOrderType = (val) => {
    setIsBuy(val)
  }

  return (
    <div className={styles.form}>
      {isTablet ? (
        <>
          {visible ? (
            <>
              <div className={styles.head}>
                <div className={styles.title}>{t('Place order')}</div>
                <button
                  className={styles.close}
                  onClick={() => setValue(false)}
                >
                  <Icon name="close-circle" size="24" />
                </button>
              </div>
              <Action
                title={'Buy BTC'}
                content={contentBuy}
                price={price}
                amount={amount}
                conditionalOrOCO={conditionalOrOCO}
                contentSell={contentSell}
                triggerPrice={triggerPrice}
                stop={stop}
                total={total}
                limit={limit}
                classButton="button-green"
                buttonText={'Sign In'}
                handleChange={handleChange}
                takeProfit={takeProfit}
                stopLoss={stopLoss}
                orders={orders}
                isMarket={isMarket}
                isTpSl={isTpSl}
              />
            </>
          ) : (
            <>
              <div className={styles.head}>
                <div className={styles.title}>{t('Place order')}</div>
                <button
                  className={styles.close}
                  onClick={() => setValue(false)}
                >
                  <Icon name="close-circle" size="24" />
                </button>
              </div>
              <Action
                title={'Sell BTC'}
                content={contentSell}
                triggerPrice={triggerPrice}
                price={price}
                amount={amount}
                conditionalOrOCO={conditionalOrOCO}
                stop={stop}
                total={total}
                limit={limit}
                classButton="button-red"
                buttonText={'Sign In'}
                handleChange={handleChange}
                orders={orders}
                takeProfit={takeProfit}
                stopLoss={stopLoss}
                isMarket={isMarket}
                isTpSl={isTpSl}
              />
            </>
          )}
        </>
      ) : selectedLayout !== 'Standard' ? (
        <div>
          <div className={styles.row}>
            <div className={styles.panelContainer}>
              <OpenClosePannel
                label1={'Buy'}
                label2={'Sell'}
                handleChange={handleChangeOrderType}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.panelCol}>
              <div className={styles.advancePanelActionContainer}>
                {isBuy ? (
                  <Action
                    title={'Buy BTC'}
                    content={contentBuy}
                    price={price}
                    triggerPrice={triggerPrice}
                    amount={amount}
                    conditionalOrOCO={conditionalOrOCO}
                    stop={stop}
                    total={total}
                    limit={limit}
                    classButton="button-green"
                    buttonText={'Sign In'}
                    takeProfit={takeProfit}
                    stopLoss={stopLoss}
                    orders={orders}
                    isMarket={isMarket}
                    isTpSl={isTpSl}
                  />
                ) : (
                  <Action
                    title={'Sell BTC'}
                    content={contentSell}
                    price={price}
                    triggerPrice={triggerPrice}
                    amount={amount}
                    conditionalOrOCO={conditionalOrOCO}
                    stop={stop}
                    total={total}
                    limit={limit}
                    classButton="button-red"
                    buttonText={'Sign In'}
                    orders={orders}
                    takeProfit={takeProfit}
                    stopLoss={stopLoss}
                    isMarket={isMarket}
                    isTpSl={isTpSl}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.row}>
          <div className={styles.col}>
            <Action
              title={'Buy BTC'}
              content={contentBuy}
              price={price}
              triggerPrice={triggerPrice}
              amount={amount}
              conditionalOrOCO={conditionalOrOCO}
              stop={stop}
              total={total}
              limit={limit}
              classButton="button-green"
              buttonText={'Sign In'}
              takeProfit={takeProfit}
              stopLoss={stopLoss}
              orders={orders}
              isMarket={isMarket}
              isTpSl={isTpSl}
            />
          </div>
          <div className={styles.col}>
            <Action
              title={'Sell BTC'}
              content={contentSell}
              price={price}
              triggerPrice={triggerPrice}
              amount={amount}
              conditionalOrOCO={conditionalOrOCO}
              stop={stop}
              total={total}
              limit={limit}
              classButton="button-red"
              buttonText={'Sign In'}
              orders={orders}
              takeProfit={takeProfit}
              stopLoss={stopLoss}
              isMarket={isMarket}
              isTpSl={isTpSl}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Form
