import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { MdOutlineExpandMore } from "react-icons/md";
import Button from '@mui/material/Button';
import useDarkMode from 'use-dark-mode';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Checkbox, FormControlLabel, Switch } from '@mui/material';
import { fetchProfile } from 'store/actions';

export const Setting = () => {

    const darkMode = useDarkMode(false)
    const dispatch = useDispatch()
    const styleAccordion = {
        backgroundColor: darkMode.value ? '#23262F' : '', 
        color: darkMode.value ? 'white' : '',
        marginTop : '15px'
    }
    const styleBtn = {
        color: darkMode.value ? 'white' : '',
    }


    const {
        clientData,
        settingsLoading,
      } = useSelector(
        (state) => ({
          clientData: state.Profile.clientData,
          settingsLoading: state.Profile.settingsLoading,
        })
      );
      useEffect(() => {
        dispatch(fetchProfile())
      }, [])
      const [Kyc, setKyc ] = useState([])
      const [transaction, setTransaction ] = useState([])
      const [request, setRequest ] = useState([])
useEffect(() => {
    const transaction = clientData?.settings?.pushNotifications?.filter((item)=> item.key === "TRANSACTION" )
    setTransaction(transaction)
    const request = clientData?.settings?.pushNotifications?.filter((item)=> item.key === "REQUEST")
    setRequest(request)
    const kyc = clientData?.settings?.pushNotifications?.filter((item)=> item.key === "KYC" )
    setKyc(kyc)
}, [clientData])


  return (
    <div>
    <Accordion sx={styleAccordion}>
      <AccordionSummary
        expandIcon={<MdOutlineExpandMore style={styleBtn} />    }
        aria-controls="panel1-content"
        id="panel1-header"
      >
<Box>
<FormControlLabel
  control={<Checkbox checked={ !transaction === 'null' ?  transaction[0].enabled : false} />}
    />

{ !transaction === 'null' ? transaction[0].key : 'TRANSACTION'}
</Box>

      </AccordionSummary>
      <AccordionDetails>

{ 
   transaction ? transaction.map((trans)=>
   // eslint-disable-next-line react/jsx-key
   <Box sx={{ display:'flex', marginLeft:"15px" }}>


<Box>

{
 trans?.actions?.map((item)=> 
 // eslint-disable-next-line react/jsx-key
 <div style={{ display:'flex' }}>
 <FormControlLabel
   control={<Checkbox checked={item?.enabled} />}
    />
 <h5 style={{ marginTop:'13px'}}> {item?.action} </h5>
 </div>
 )
}
</Box>


   </Box>
   ) : 'No Notification'
}
      </AccordionDetails>
    </Accordion>


    <Accordion sx={styleAccordion}>
      <AccordionSummary
        expandIcon={<MdOutlineExpandMore style={styleBtn} />    }
        aria-controls="panel1-content"
        id="panel1-header"
      >
 <Box>
<FormControlLabel
  control={<Checkbox checked={ !Kyc === 'null' ? Kyc[0].enabled : false } />}
    />

{ !Kyc === 'null' ? Kyc[0].key : 'KYC'}
</Box> 

      </AccordionSummary>
      <AccordionDetails>

{ 
   Kyc ? Kyc.map((trans)=>
   // eslint-disable-next-line react/jsx-key
   <Box sx={{ display:'flex', marginLeft:"15px" }}>


<Box>

{
 trans?.actions?.map((item)=> 
 // eslint-disable-next-line react/jsx-key
 <div style={{ display:'flex' }}>
 <FormControlLabel
   control={<Checkbox checked={item?.enabled} />}
    />
 <h5 style={{ marginTop:'13px'}}> {item?.action} </h5>
 </div>
 )
}
</Box>


   </Box>
   ) : 'No Notification'
}
      </AccordionDetails>
    </Accordion>

    <Accordion sx={styleAccordion}>
      <AccordionSummary
        expandIcon={<MdOutlineExpandMore style={styleBtn} />    }
        aria-controls="panel1-content"
        id="panel1-header"
      >
<Box>
<FormControlLabel
  control={<Checkbox checked={ !request === "null" ? request[0].enabled : false } />}
    />

{ !request === 'null' ? request[0].key : 'REQUEST'}
</Box>

      </AccordionSummary>
      <AccordionDetails>

{ 
   request ? request.map((trans)=>
   // eslint-disable-next-line react/jsx-key
   <Box sx={{ display:'flex', marginLeft:"15px" }}>


<Box>

{
 trans?.actions?.map((item)=> 
 // eslint-disable-next-line react/jsx-key
 <div style={{ display:'flex' }}>
 <FormControlLabel
   control={<Checkbox checked={item?.enabled} />}
    />
 <h5 style={{ marginTop:'13px'}}> {item?.action} </h5>
 </div>
 )
}
</Box>


   </Box>
   ) : 'No Notification'
}
      </AccordionDetails>
    </Accordion>
  </div>
  )
}
