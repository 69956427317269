import cn from 'classnames'
import { Link } from 'react-router-dom'
import styles from './Main.module.sass'
import ScrollButton from '../../../components/ScrollButton'

const Main = ({ scrollToRef }) => {
  return (
    <div className={cn('section', styles.main2)}>
      <div className={cn('container', styles.container)}>
        <div className={cn(styles.topBanner)}>
          <div className={styles.wrap}>
            <h1 className={cn('h1', styles.title)}>About FiniticX Exchange</h1>
            <div className={styles.text}>
              Redefining crypto trading with secure, user-friendly, and
              innovative solutions. FiniticX Exchange offers unparalleled market
              access and end-to-end support, equipping traders for success in
              the digital economy. Join our vision and advance your trading
              journey.
            </div>
            <Link className={cn('button', styles.button)} to="/sign-up">
              Get started now
            </Link>
            <ScrollButton
              onScroll={() =>
                scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
              }
              className={styles.scroll}
            />
          </div>
          <div>
            <img
              srcSet="/images/content/crypto/new-crypto-images/about-us-banner.png"
              src="/images/content/crypto/new-crypto-images/about-us-banner.png"
              alt="Cards"
              className={cn('bg', styles.bannerImage)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
