import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import styles from './Login.module.sass'
import { useTranslation } from 'react-i18next'
import { clientName } from 'content'

const Login = ({ className, content, linkText, linkUrl, children }) => {
  const { t } = useTranslation()
  return (
    <div className={cn(className, styles.login)}>
      <div
        className={styles.col}
        style={{
          backgroundImage: 'url(/images/content/bg-login.png)',
        }}
      >
        <Link className={styles.logo} to="/">
          <img src="/images/logo-light.png" alt={clientName} />
          <img src="/images/logo-dark.png" alt={clientName} />
        </Link>
      </div>
      <div className={styles.col}>
        <div className={styles.head}>
          <span>{t(content)}</span>
          <Link className={styles.link} to={linkUrl}>
            {t(linkText)}
          </Link>
        </div>
        <div className={styles.wrap}>{children}</div>
      </div>
    </div>
  )
}

export default Login
