import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Action.module.sass'
import balanceStyles from '../../../../Balance/Balance.module.sass'
import { Range, getTrackBackground } from 'react-range'
import Icon from '../../../../../../components/Icon'
import Dropdown from 'components/Dropdown'
import Checkbox from 'components/Checkbox'
import TPSLPrice from './TPSLPrice'
import InputDropdown from 'components/InputDropdown'
import { useTranslation } from 'react-i18next'

const COOptions = ['Conditional', 'OCO']
const LoMoOptions = ['Limit order', 'Market order']
const TpLimitOptions = ['TP Limit', 'TP Market']
const SlLimitOptions = ['SL Limit', 'SL Market']

const Action = ({
  amount,
  buttonText,
  conditionalOrOCO,
  content,
  classButton,
  price,
  limit,
  stop,
  title,
  triggerPrice,
  tpsl,
  orders,
  isLimit,
}) => {
  const { t } = useTranslation()
  const [values, setValues] = useState([10])
  const [conditional, setConditional] = useState(COOptions[0])
  const [isTPSL, setIsTPSL] = useState(false)
  const [loMoOption, setLoMoOptions] = useState(LoMoOptions[0])
  const [tpLimitOption, setTpLimitOption] = useState(TpLimitOptions[0])
  const [slLimitOption, setSlLimitOption] = useState(SlLimitOptions[0])

  const stepPrice = 10
  const minPrice = 0
  const maxPrice = 100

  return (
    <>
      <div className={styles.head}>
        <div className={styles.title}>{title}</div>
        <div className={cn('counter', styles.counter)}>
          <Icon name="wallet" size="16" /> {content}
        </div>
      </div>
      {price &&
        (isLimit ? (
          <div className={styles.orderPrice}>
            <TPSLPrice label={t('Price')} isMarket marketLabel="BBO" />
          </div>
        ) : (
          <label className={styles.field}>
            <div className={styles.label}>{t('Price')}</div>
            <input
              className={styles.input}
              type="text"
              name={t('price')}
              placeholder={t('Place order at market price')}
              style={{ paddingRight: 0 }}
              required
            />
          </label>
        ))}
      {stop && (
        <label className={styles.field}>
          <div className={styles.label}>{t('Stop')}</div>
          <input className={styles.input} type="text" name="stop" required />
          <div className={styles.currency}>{t('BTC')}</div>
        </label>
      )}
      {conditionalOrOCO && (
        <div className={styles.tpslContainer}>
          <div className={styles.dropdown}>
            <Dropdown
              className={balanceStyles.dropdown}
              classDropdownHead={balanceStyles.dropdownArrow}
              classDropdownBody={balanceStyles.dropdownBody}
              classDropdownOption={balanceStyles.dropdownOption}
              value={t(conditional)}
              setValue={setConditional}
              options={COOptions}
            />
          </div>
          {conditional === COOptions[0] ? (
            <>
              <TPSLPrice label="TP trigger price" />
              <InputDropdown
                className={balanceStyles.dropdown}
                classDropdownHead={balanceStyles.dropdownArrow}
                classDropdownBody={balanceStyles.dropdownBody}
                classDropdownOption={balanceStyles.dropdownOption}
                value={loMoOption}
                inputFieldName={LoMoOptions[1]}
                setValue={setLoMoOptions}
                options={LoMoOptions}
                adornmentText={'USDT'}
              />
              <label className={styles.field}>
                <div className={styles.label}>Amount</div>
                <input
                  className={styles.input}
                  type="text"
                  name="amount"
                  required
                />
                <div className={styles.currency}>BTC</div>
              </label>
            </>
          ) : (
            <>
              <TPSLPrice label="TP trigger price" />
              <InputDropdown
                className={balanceStyles.dropdown}
                classDropdownHead={balanceStyles.dropdownArrow}
                classDropdownBody={balanceStyles.dropdownBody}
                classDropdownOption={balanceStyles.dropdownOption}
                value={tpLimitOption}
                inputFieldName={TpLimitOptions[1]}
                setValue={setTpLimitOption}
                options={TpLimitOptions}
                adornmentText={'USDT'}
              />
              <TPSLPrice label="SL trigger price" />
              <InputDropdown
                className={balanceStyles.dropdown}
                classDropdownHead={balanceStyles.dropdownArrow}
                classDropdownBody={balanceStyles.dropdownBody}
                classDropdownOption={balanceStyles.dropdownOption}
                value={slLimitOption}
                inputFieldName={SlLimitOptions[1]}
                setValue={setSlLimitOption}
                options={SlLimitOptions}
                adornmentText={'USDT'}
              />
              <label className={styles.field}>
                <div className={styles.label}>Amount</div>
                <input
                  className={styles.input}
                  type="text"
                  name="amount"
                  required
                />
                <div className={styles.currency}>BTC</div>
              </label>
            </>
          )}
        </div>
      )}
      {limit && (
        <label className={styles.field}>
          <div className={styles.label}>Limit</div>
          <input className={styles.input} type="text" name="limit" required />
          <div className={styles.currency}>USDT</div>
        </label>
      )}
      {triggerPrice && <TPSLPrice label="Trigger price" />}
      {orders && (
        <InputDropdown
          className={balanceStyles.dropdown}
          classDropdownHead={balanceStyles.dropdownArrow}
          classDropdownBody={balanceStyles.dropdownBody}
          classDropdownOption={balanceStyles.dropdownOption}
          value={loMoOption}
          inputFieldName={LoMoOptions[1]}
          setValue={setLoMoOptions}
          options={LoMoOptions}
          adornmentText={'USDT'}
        />
      )}
      {amount && (
        <label className={styles.field}>
          <div className={styles.label}>Amount</div>
          <input
            className={styles.input}
            type="text"
            name="amount"
            required
            placeholder="Single contract value 0.01 BTC"
          />
          <div className={styles.currency}>Contracts</div>
        </label>
      )}
      <div className="ltr">
        <Range
          values={values}
          step={stepPrice}
          min={minPrice}
          max={maxPrice}
          onChange={(values) => setValues(values)}
          renderMark={({ props, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '6px',
                width: '2px',
                marginTop: '-2px',
                borderRadius: '1px',
                backgroundColor:
                  index * stepPrice < values[0] ? '#3772FF' : '#E6E8EC',
              }}
            />
          )}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '2px',
                  width: '100%',
                  borderRadius: '1px',
                  background: getTrackBackground({
                    values,
                    colors: ['#3772FF', '#E6E8EC'],
                    min: minPrice,
                    max: maxPrice,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '18px',
                width: '18px',
                borderRadius: '50%',
                backgroundColor: '#F4F5F6',
                border: '4px solid #777E90',
                boxShadow: '0px 8px 16px -8px rgba(15, 15, 15, 0.2)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '-27px',
                  color: '#FCFCFD',
                  fontWeight: '600',
                  fontSize: '13px',
                  lineHeight: '16px',
                  fontFamily: 'Poppins',
                  padding: '2px 6px',
                  borderRadius: '6px',
                  backgroundColor: '#777E90',
                }}
              >
                {values[0].toFixed(0)}%
              </div>
            </div>
          )}
        />
      </div>
      {tpsl && (
        <Checkbox
          className={styles.checkbox}
          value={isTPSL}
          onChange={() => setIsTPSL(!isTPSL)}
          content="TP/SL"
        />
      )}
      {isTPSL && (
        <>
          <TPSLPrice label="TP trigger price" />
          <TPSLPrice label="SL trigger price" />
        </>
      )}
      <button className={cn(classButton, styles.button)}>{buttonText}</button>
    </>
  )
}

export default Action
