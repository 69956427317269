import React, { useState } from 'react'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import styles from './Dropdown.module.sass'
import Icon from '../Icon'

const CountriesDropdown = ({
  className,
  classLabel,
  value,
  setValue,
  options,
  label,
  classDropdownHead,
  classDropdownArrow,
  classDropdownBody,
  classDropdownOption,
  error,
  touched,
  errClassName,
}) => {
  const [visible, setVisible] = useState(false)

  const handleClick = (value) => {
    setValue(value)
    setVisible(false)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div className={styles.main}>
        <div
          className={cn(styles.dropdown, className, {
            [styles.active]: visible,
          })}
        >
          <div
            className={cn(classDropdownHead, styles.head)}
            onClick={() => setVisible(!visible)}
          >
            <div className={styles.selection}>+{value.country}</div>
            <div className={cn(styles.arrow, classDropdownArrow)}>
              <Icon name="arrow-down" size="24" />
            </div>
          </div>
          <div className={cn(classDropdownBody, styles.body)}>
            {options.map((x, index) => (
              <div
                className={cn(classDropdownOption, styles.option, {
                  [styles.selectioned]: x === value,
                })}
                onClick={() => handleClick(x, index)}
                key={index}
              >
                {x.label} (+{x.phone})
              </div>
            ))}
          </div>
        </div>
        {error && touched && <div className={errClassName}>{error}</div>}
      </div>
    </OutsideClickHandler>
  )
}

export default CountriesDropdown
