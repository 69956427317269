import cn from 'classnames'
import Banner from './Banner'
import ContactUs from './ContactUs'
import AffiliateEasySteps from './AffiliateEasySteps'
import Download from 'screens/Home/Download'

const Affiliate = () => {
  return (
    <div className={cn('container')}>
      <Banner />
      {/* <Benifits /> */}
      <AffiliateEasySteps />
      <ContactUs />
      <Download />
      {/* <AggressiveIncome /> */}
    </div>
  )
}
export default Affiliate
