import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Header.module.sass'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Image from '../Image'
import Dropdown from './Dropdown'
import Settings from './Settings'
import Icon from '../Icon'
import Notifications from './Notifications'
import Theme from '../Theme'
import User from './User'
import PortalEntry from './PortalEntry'
import Layout from './Layout'
import { useTranslation } from 'react-i18next'

const navigation = [
  {
    title: 'Trade',
    dropdown: [
      {
        title: 'Spot',
        icon: 'refresh',
        url: '/exchange',
        isIcon: true,
      },
      {
        title: 'Futures',
        icon: 'newspaper-folded',
        url: '/exchange/futures',
        isIcon: true,
      },
      {
        title: 'Margin',
        icon: 'seesaw',
        url: '/exchange/margin',
        isIcon: true,
      },
    ],
  },
  {
    title: 'Buy Crypto',
    dropdown: [
      {
        title: 'Credit card',
        icon: 'user',
        url: '/buy-crypto',
      },
      {
        title: 'Bank deposit',
        icon: 'image',
        url: '/deposit',
      },
    ],
  },
  {
    title: 'Market',
    url: '/market',
  },
  {
    title: 'Community',
    dropdown: [
      {
        title: 'Telegram',
        icon: 'telegram',
        url: '/community/telegram',
        isIcon: true,
      },
      {
        title: 'X',
        icon: 'twitter',
        url: '/community/x',
      },
      {
        title: 'Medium',
        icon: 'medium',
        url: '/community/medium',
        isIcon: true,
      },
      {
        title: 'Discover',
        icon: 'find-person',
        url: '/community/discover',
        isIcon: true,
      },
    ],
  },
  {
    title: 'Reward',
    dropdown: [
      {
        title: 'Affiliate',
        icon: 'affiliate',
        url: '/reward/affiliate',
        isIcon: true,
      },
      {
        title: 'Task Centre',
        icon: 'clipboard',
        url: '/reward/task-centre',
        isIcon: true,
      },
      {
        title: 'Referral Program',
        icon: 'referral',
        url: '/reward/referral-program',
        isIcon: true,
      },
    ],
  },
]

const Header = ({ headerWide, stickyHeader }) => {
  const [visibleNav, setVisibleNav] = useState(false)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const { clientData } = useSelector((state) => state?.Profile)

  return (
    <header
      className={cn(stickyHeader ? styles.headerSticky : styles.header, {
        [styles.wide]: headerWide,
      })}
    >
      <PortalEntry />
      <div className={cn('container', styles.container)}>
        <Link
          className={cn(styles.logo, 'logo')}
          to="/home"
          onClick={() => setVisibleNav(false)}
        >
          <Image
            src="/images/logo-light.png"
            srcDark="/images/logo-dark.png"
            alt="FiniticX"
          />
        </Link>
        <div className={cn(styles.wrapper, 'wrapper')}>
          <div
            className={cn(styles.wrap, 'wrap', {
              [styles.visible]: visibleNav,
            })}
          >
            <nav className={cn(styles.nav, 'nav')}>
              {navigation.map((x, index) =>
                x.dropdown ? (
                  <Dropdown
                    className={styles.dropdown}
                    key={index}
                    item={x}
                    setValue={setVisibleNav}
                  />
                ) : (
                  <NavLink
                    className={cn(styles.item, 'item', {
                      [styles.active]: pathname === x.url,
                    })}
                    onClick={() => setVisibleNav(false)}
                    to={x.url}
                    key={index}
                  >
                    {t(x.title)}
                  </NavLink>
                ),
              )}
            </nav>
            <NavLink
              className={cn('button-stroke', styles.button, {
                [styles.active]: pathname === '/dashboard',
              })}
              to="/dashboard"
              onClick={() => setVisibleNav(false)}
            >
              {t('Wallet')}
            </NavLink>
            {/* <div className={styles.btns}>
                            <Link
                                className={cn("button-small", styles.button)}
                                to="/sign-up"
                            >
                                Sign Up
                            </Link>
                            <Link
                                className={cn(
                                    "button-stroke button-small",
                                    styles.button
                                )}
                                to="/sign-in"
                            >
                                Login
                            </Link>
                        </div> */}
          </div>
          <Settings className={cn(styles.settings, 'settings')} />
          <div className={cn('control', styles.control)}>
            {pathname.startsWith('/exchange') && <Layout />}
            <NavLink
              className={cn('activity', styles.activity, {
                [styles.active]: pathname === '/activity',
              })}
              to="/activity"
            >
              <Icon name="lightning" size="24" />
            </NavLink>
            {clientData?._id && (
              <Notifications
                className={cn('notifications', styles.notifications)}
              />
            )}
            <NavLink
              className={cn(
                'button-stroke button-small',
                'button',
                styles.button,
                {
                  [styles.active]: pathname === '/dashboard',
                },
              )}
              to="/dashboard"
            >
              {t('Wallet')}
            </NavLink>
            <Theme className={cn('theme', styles.theme)} icon />
            {clientData?._id ? (
              <User className={styles.user} />
            ) : (
              <>
                <NavLink className={'button-stroke button-small'} to="/login">
                  {t('Login')}
                </NavLink>
                <NavLink
                  className={'button-stroke button-small'}
                  to="/register/crypto/live"
                >
                  {t('Sign Up')}
                </NavLink>
              </>
            )}
          </div>
          {/* <div className={styles.btns}>
                        <Link
                            className={cn("button-small", styles.button)}
                            to="/sign-up"
                            onClick={() => setVisibleNav(false)}
                        >
                            Sign Up
                        </Link>
                        <Link
                            className={cn(
                                "button-stroke button-small",
                                styles.button
                            )}
                            to="/sign-in"
                            onClick={() => setVisibleNav(false)}
                        >
                            Login
                        </Link>
                    </div> */}
          <button
            className={cn(styles.burger, {
              [styles.active]: visibleNav,
            })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </div>
    </header>
  )
}

export default Header
