import Form from './Form'

const TPSL = ({ contentBuy, contentSell, visible, setValue }) => {
  return (
    <div>
      <Form
        contentBuy={contentBuy}
        contentSell={contentSell}
        conditionalOrOCO={true}
        visible={visible}
        setValue={setValue}
      />
    </div>
  )
}

export default TPSL
