import { useTranslation } from 'react-i18next'
import styles from './Disclaimer.module.sass'
import cn from 'classnames'

const Disclaimer = () => {
  const { t } = useTranslation()

  return (
    <div className={'container'}>
      <div className={cn('section')}>
        <div className={cn('h4', styles.title)}>{t('Disclaimer')}</div>
        <div className={styles.dateContainer}>
          <div className="h5">Disclaimer for FiniticX</div>
          <div className="h5">Effective Date: 1st Jan 2024</div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn(styles.peragraph)}>
            Please carefully read the following disclaimer ({'"'}Disclaimer{'"'}) as it
            contains important information regarding your legal rights,
            remedies, and obligations.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>1. General Disclaimer</div>
          <div className={cn(styles.peragraph)}>
            FiniticX provides technology solutions and services for setting up
            and managing crypto exchanges. The company does not operate a
            cryptocurrency exchange nor does it offer trading or financial
            services to end users.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>
            2. Limitation of Liability
          </div>
          <div className={cn(styles.peragraph)}>
            FiniticX shall not be liable for any damages or losses arising from
            your use of our services, including but not limited to direct,
            indirect, incidental, punitive, and consequential damages, unless
            otherwise specified in writing.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn(styles.peragraph)}>
            A) FiniticX is not responsible for the actions, content,
            information, or data of third parties, and you release us, our
            directors, officers, employees, and agents from any claims and
            damages, known and unknown, arising out of or in any way connected
            with any claim you have against such third parties.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn(styles.peragraph)}>
            B) FiniticX shall not be liable for any failure or delay in
            performance due in whole or in part to any cause beyond our
            reasonable control, including but not limited to utility or
            transmission failures, failure of the internet, natural disasters,
            pandemics, strikes, or other labor disturbances, acts of war or
            terror, riots, or other acts of God.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn(styles.peragraph)}>
            C) FiniticX is not liable for any issues relating to the purchase,
            sale, or trade of cryptocurrencies that are conducted outside the
            bounds of our provided solutions and services.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Service Disruptions</div>
          <div className={cn(styles.peragraph)}>
            FiniticX will not be responsible for any disruption, interruption,
            or delay in the services due to maintenance, updates, or technical
            limitations.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>User Content</div>
          <div className={cn(styles.peragraph)}>
            FiniticX is not responsible for monitoring or policing the content
            or data users may create or upload when using our technology
            solutions. FiniticX disclaims all liability for user-generated
            content.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Cryptocurrency Risks</div>
          <div className={cn(styles.peragraph)}>
            The company disclaims any liability for potential defects, failures,
            or interruptions in the delivery of cryptocurrency that may arise
            due to external factors, including but not limited to the services
            of cryptocurrency management systems or telecommunication service
            providers.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Registration Content</div>
          <div className={cn(styles.peragraph)}>
            The company is not responsible for verifying or guaranteeing the
            accuracy, adequacy, or completeness of the information provided by
            users who utilize FiniticX solutions to set up their cryptocurrency
            exchanges.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>
            Third-party Links and Services
          </div>
          <div className={cn(styles.peragraph)}>
            Our services may include links to third-party websites or services
            that are not owned or controlled by FiniticX. We assume no
            responsibility for the content, privacy policies, or practices of
            any third-party websites or services.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Amendments</div>
          <div className={cn(styles.peragraph)}>
            FiniticX reserves the right to modify or replace these terms at any
            time. If a revision is material, we will provide reasonable notice
            prior to any new terms taking effect.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Governing Law</div>
          <div className={cn(styles.peragraph)}>
            This disclaimer shall be governed by and construed in accordance
            with the laws of the jurisdiction in which FiniticX is established,
            without regard to its conflict of law provisions.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn(styles.peragraph)}>
            By using our services, you acknowledge that you have read,
            understood, and agree to be bound by this Disclaimer.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn(styles.peragraph)}>
            © 2024 FiniticX. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Disclaimer
