import { useTranslation } from 'react-i18next'
import styles from './AllProgress.module.sass'

const AllProgress = ({ isDaily }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      {isDaily && (
        <div className={styles.description}>
          {t(
            'After completing the task, the task reward will be distributed to your reward center in real time.',
          )}
        </div>
      )}
      <div className={styles.card}>
        <div className={styles.content}>
          <img
            src="/images/content/reward/referralProgram/OtherUsers.png"
            height={100}
          />
          <div className={styles.progressContent}>
            <div className={styles.cashReward}>
              1 USDT
              <div className={styles.cashRewardText}>{t('Cash Reward')}</div>
            </div>
            <div className={styles.text}>
              {t('Daily futures trading volumes ≥ 2,000 USDT')}
            </div>
            <div className={styles.progress}>
              <div className={styles.progressBar}></div>
              0.00 / 2,000 USDT
            </div>
          </div>
        </div>
        <div className={styles.button}>{t('Complete')}</div>
      </div>
    </div>
  )
}

export default AllProgress
