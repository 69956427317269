import React, { useState, useEffect, memo } from 'react'
import cn from 'classnames'
import styles from './Currency.module.sass'
import Icon from '../../../components/Icon'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { updateFevourite } from 'store/actions'
import { FaStar } from 'react-icons/fa'
import { usePricingMonitor } from 'hooks/usePricingMonitor'

const navigation = ['All', 'Favorite', 'Top']

const CurrencyRow = (props) => {
  const { data: x, onMarketSelect, updateVisibility } = props
  const pricingData = usePricingMonitor(x.instanceId)
  return (<div
    className={styles.row}
    style={{
      cursor: 'pointer',
    }}
    onClick={() => onMarketSelect(x.instanceId)}
  >
    <div className={styles.col}>
      <div className={styles.line}>
        <button
          className={cn('favorite', styles.favorite)}
          onClick={() => {
            updateVisibility('isFav', x)
          }}
        >
          {x.isFav ? (
            <FaStar />
          ) : (
            <Icon name="star-outline" size="16" />
          )}
        </button>
        <div className={styles.info}>
          {x.instanceId}
        </div>
      </div>
    </div>
    <div className={styles.col}>
      {parseFloat(pricingData?.currentPrice?.$numberDecimal ?? pricingData.currentPrice).toFixed(2)}
      {/* {x?.marketPrice?.$numberDecimal} */}
      {/* {x.positive && (
          <div className={styles.positive}>{x.positive}</div>
        )}
        {x.negative && (
          <div className={styles.negative}>{x.negative}</div>
      )} */}
    </div>
    {/* <div className={styles.col}>{x?.volume?.$numberDecimal}</div> */}
  </div>)
}

const Currency = (props) => {
  const { t } = useTranslation()
  const { onMarketSelect } = props

  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [favoritesData, setFavoritesData] = useState([])
  const markets = useSelector((state) => state.crypto.markets.markets)

  const updateVisibility = (crmOrCp, item) => {
    dispatch(
      updateFevourite(
        item._id,
        !item[crmOrCp] ? { [crmOrCp]: true } : { [crmOrCp]: false },
      ),
    )
  }
  const filterMarkets = () => {
    const favorites = markets.filter((item) => item.isFav === true)
    setFavoritesData(favorites)
  }

  useEffect(() => {
    filterMarkets()
  }, [markets])

  return (
    <div className={styles.currency}>
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {t(x)}
          </button>
        ))}
      </div>
      <div>
        <input
          className={styles.input}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder="Search"
          required
        />
        {/* <button className={styles.result}>
          <Icon name="search" size="20" />
        </button> */}
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">Pair</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Price</div>
          </div>
          {/* <div className={styles.col}>
            <div className="sorting">Volume</div>
          </div> */}
        </div>
        {activeIndex === 0 && (
          <>
            {markets
            .sort((a, b) => {
              return parseFloat(b.volume?.$numberDecimal) - parseFloat(a.volume?.$numberDecimal)
            })
              ?.filter((m) => {
                if (m.pairName?.toLowerCase().includes(search.toLowerCase())) {
                  return m
                }
              })
              .map((x, index) => {
                return (
                  <CurrencyRow
                    key={index}
                    data={x}
                    onMarketSelect={onMarketSelect}
                    updateVisibility={updateVisibility}
                  />
                )
              })}
          </>
        )}
        {activeIndex === 1 && (
          <>
            {favoritesData
              ?.filter((m) => {
                if (m.pairName.toLowerCase().includes(search.toLowerCase())) {
                  return m
                }
              })
              .map((x, index) => {
                const pricingData = usePricingMonitor(x.instanceId)
                return (
                  <div
                    className={styles.row}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => onMarketSelect(x.pairName)}
                    key={index}
                  >
                    <div className={styles.col}>
                      <div className={styles.line}>
                        <button
                          className={cn('favorite', styles.favorite)}
                          onClick={() => {
                            updateVisibility('isFav', x)
                          }}
                        >
                          {x.isFav ? (
                            <FaStar />
                          ) : (
                            <Icon name="star-outline" size="16" />
                          )}
                        </button>
                        <div className={styles.info}>
                          {/* {x.pairName.split('/')[0]}/{x.pairName.split('/')[1]} */}
                          {x.instanceId}
                        </div>
                      </div>
                    </div>
                    <div className={styles.col}>
                      {pricingData?.currentPrice?.$numberDecimal ?? pricingData.currentPrice}
                      {/* {x.positive && (
                  <div className={styles.positive}>{x.positive}</div>
                )}
                {x.negative && (
                  <div className={styles.negative}>{x.negative}</div>
                )} */}
                    </div>
                    {/* <div className={styles.col}>{x?.volume?.$numberDecimal}</div> */}
                  </div>
                )
              })}
          </>
        )}
      </div>
    </div>
  )
}

export default memo(Currency)
