import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Transfer.module.sass'
import Icon from '../Icon'
import TextInput from '../TextInput'
import Dropdown from '../Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import DropdownDynamic from 'components/DropdownDynamic'
import Loader from 'components/Loader'
import { createTransferBetweenWallets } from 'apis/transfer'
import { toast } from 'react-toastify'
import Successfully from './Successfully'
import { fetchWallets } from 'store/actions'

const fromDirectionOptions = ['Margin', 'Fiat and Spot']
const toDirectionOptions = ['Fiat and Spot', 'Margin']
const coinOptions = ['ETH Ethereum', 'BTC Bitcoin', 'TRX Tron']

const Transfer = ({ setVisibleTransfer }) => {
  const [fromDirection, setFromDirection] = useState(fromDirectionOptions[0])
  const [toDirection, setToDirection] = useState(toDirectionOptions[0])
  // const [coin, setCoin] = useState(coinOptions[0])
  const [isLoading, setIsLoading] = useState(false)
  const [coin, setCoin] = useState('')
  const { wallets, allWallets, loading } = useSelector((state) => state?.walletReducer)
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [amount, setAmount] = useState('')
  const [fromCoins, setFromCoins] = useState([])
  const [toCoins, setToCoins] = useState([])
  const [coins, setCoins] = useState([])
  const dispatch = useDispatch();

  const [responseData, setResponseData] = useState([])
  const [visibleWithdraw, setVisibleWithdraw] = useState(true)
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false)

  const handleClick = () => {
    setVisibleWithdraw(false)
    setVisibleSuccessfully(true)
  }

  const handleCloseClick = () => {
    setVisibleWithdraw(false)
    setVisibleSuccessfully(false)
    setVisibleTransfer(false)
  }

  const [items, setItems] = useState([
    {
      title: 'Funding Wallets',
      type: 'funding',
      color: '#FFD166',
      currency: '0.2785689852 BTC',
      price: '$10,098.36',
      hasTransfer: true,
      hasDeposite: true,
    },
    {
      title: 'Trading Wallets',
      type: 'trading',
      color: '#D4314D',
      currency: '0.2785689852 BTC',
      price: '$10,098.36',
      hasTransfer: true,
      hasDeposite: false,
    },
    {
      title: 'Margin',
      type: 'margin',
      color: '#9757D7',
      currency: '0.2785689852 BTC',
      price: '$10,098.36',
      hasTransfer: true,
      hasDeposite: false,
    },
    // {
    //   title: 'P2P',
    //   color: '#4BC9F0',
    //   soon: true,
    // },
    {
      title: 'Futures',
      type: 'futures',
      color: '#3772FF',
      currency: '0.2785689852 BTC',
      price: '$10,098.36',
      hasTransfer: true,
      hasDeposite: false,
    },
  ])

  const sendData = async() => {
    try {
      // if (!validateInputs()) {
      //   return
      // }
      setIsLoading(true)
      let sendedData = {
        fromAssetType: from?.type,
        toAssetType: to?.type,
        asset: coin?.asset,
        amount: amount,
        // toAssetPriceData,
        // feeData: 0,
      }
      
      let data = await createTransferBetweenWallets(sendedData)
      setResponseData(data?.result)
      setIsLoading(false)
      if (data?.isSuccess) {
        handleClick()
        dispatch(fetchWallets());
      }
      if (data?.isError){
        toast.error(data?.message);
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  useEffect(()=>{
    if (allWallets){
      setFromCoins(allWallets?.[from?.type] || [])
    }
  }, [from])

  useEffect(()=>{
    if (allWallets){
      setToCoins(allWallets?.[to?.type] || [])
    }
  }, [to])
  
  useEffect(()=>{
    let finalCoins = []
    fromCoins?.forEach(fromCoin=>{
      if ( (toCoins?.filter(toCoin=> toCoin?.asset === fromCoin?.asset)?.[0]) ){
        finalCoins?.push(fromCoin)
      }
    })
    setCoins(finalCoins)
  }, [fromCoins, toCoins])
  
  return (
    <>
      {
        visibleWithdraw ?
        <div className={styles.transfer}>
          <div className={cn('h4', styles.title)}>
            <Icon name="arrow-left" size="32" />
            Transfer
          </div>
          <div className={styles.wrap}>
            <div className={styles.category}>
              Available <br></br>balance
            </div>
            <div className={styles.details}>
              <div className={styles.currency}>{coin?.amount || 0} {coin?.asset || ''}</div>
              {/* <div className={styles.price}>$10,095.35</div> */}
            </div>
          </div>

          <div className={styles.field}>
            <DropdownDynamic
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              label="from"
              value={from}
              setValue={setFrom}
              options={items}
              uniqueName={'title'}
              uniqueId={'type'}
            />
          </div>
          <div className={styles.sign}>
            <Icon name="arrows" size="16" />
          </div>
          
          <div className={styles.field}>
            <DropdownDynamic
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              label="To"
              value={to}
              setValue={setTo}
              options={items}
              uniqueName={'title'}
              uniqueId={'type'}
            />
          </div>

          <div className={styles.field}>
            <DropdownDynamic
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              label="Coin"
              value={coin}
              setValue={setCoin}
              options={coins}
              uniqueName={'asset'}
              uniqueId={'asset'}
            />
          </div>

          <div className={styles.box}>
            <TextInput
              className={styles.field}
              label="Amount to transfer"
              name="amount"
              type="text"
              note={`${coin?.amount || 0} ${coin?.asset || ''}available`}
              value={amount}
              onChange={(e)=> setAmount(e.target.value)}
              required
            />
            <button onClick={()=> setAmount(coin?.amount)} className={cn('button-stroke button-small', styles.button)}>
              Max amount
            </button>
          </div>

          <button
            type="button"
            disabled={isLoading}
            className={cn('button', styles.button)}
            onClick={sendData}
          >
            {isLoading ? <Loader /> : 'Transfer' }
          </button>
        </div>
        :
        visibleSuccessfully && <Successfully responseData={responseData} fromAsset={from?.title} toAsset={to?.title} handleClick={handleCloseClick}/>
      }
    </>
  )
}

export default Transfer
