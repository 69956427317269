import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './AccountBalances.module.sass'
import { Link } from 'react-router-dom'
import Icon from '../../../components/Icon'
import Modal from '../../../components/Modal'
import Transfer from '../../../components/Transfer'

// const items = [
//   {
//     title: 'Funding Wallets',
//     type: 'funding',
//     color: '#FFD166',
//     currency: '0.2785689852 BTC',
//     price: '$10,098.36',
//     hasTransfer: true,
//     hasDeposite: true,
//   },
//   {
//     title: 'Trading Wallets',
//     type: 'trading',
//     color: '#D4314D',
//     currency: '0.2785689852 BTC',
//     price: '$10,098.36',
//     hasTransfer: true,
//     hasDeposite: false,
//   },
//   {
//     title: 'Margin',
//     type: 'margin',
//     color: '#9757D7',
//     currency: '0.2785689852 BTC',
//     price: '$10,098.36',
//     hasTransfer: true,
//     hasDeposite: false,
//   },
//   // {
//   //   title: 'P2P',
//   //   color: '#4BC9F0',
//   //   soon: true,
//   // },
//   {
//     title: 'Futures',
//     type: 'futures',
//     color: '#3772FF',
//     currency: '0.2785689852 BTC',
//     price: '$10,098.36',
//     hasTransfer: true,
//     hasDeposite: false,
//   },
// ]

const AccountBalances = ({ activeWallet, setActiveWallet, allWallets }) => {
  const [visibleTransfer, setVisibleTransfer] = useState(false)
  const [items, setItems] = useState([
    {
      title: 'Funding Wallets',
      type: 'funding',
      color: '#FFD166',
      currency: '0.2785689852 BTC',
      price: '$10,098.36',
      hasTransfer: true,
      hasDeposite: true,
    },
    {
      title: 'Trading Wallets',
      type: 'trading',
      color: '#D4314D',
      currency: '0.2785689852 BTC',
      price: '$10,098.36',
      hasTransfer: true,
      hasDeposite: false,
    },
    {
      title: 'Margin',
      type: 'margin',
      color: '#9757D7',
      currency: '0.2785689852 BTC',
      price: '$10,098.36',
      hasTransfer: true,
      hasDeposite: false,
    },
    // {
    //   title: 'P2P',
    //   color: '#4BC9F0',
    //   soon: true,
    // },
    {
      title: 'Futures',
      type: 'futures',
      color: '#3772FF',
      currency: '0.2785689852 BTC',
      price: '$10,098.36',
      hasTransfer: true,
      hasDeposite: false,
    },
  ])

  function handleTransferClick(e) {
    e.stopPropagation();
    setVisibleTransfer(true)
  }
  
  useEffect(()=>{
    let modifiedItems = [...items]
    modifiedItems = modifiedItems?.map(item=>{
      let btcValue = 0
      let usdValue = 0
      let walletTypeItem = allWallets?.[item?.type]
      walletTypeItem?.forEach((walletItem)=>{
        btcValue += walletItem?.btcValue
        usdValue += walletItem?.usdValue
      })
      item['currency'] = `${btcValue} BTC`
      item['price'] = `$ ${usdValue}`
      return item
    })
    setItems(modifiedItems)
  }, [allWallets])
  return (
    <>
      <div className={styles.list}>
        {items.map((x, index) => (
          <div
            className={cn(styles.item, { [styles.active]: x.type === activeWallet }, { [styles.soon]: x.soon })}
            key={index}
            onClick={(e)=>{x.type === activeWallet ? setActiveWallet('') : setActiveWallet(x.type)}}
          >
            
            <div className={styles.head}>
              <div className={styles.title}>
                <div
                  className={styles.bg}
                  style={{ backgroundColor: x.color }}
                ></div>
                  {x.title}
              </div>
              <div className={styles.details}>
                <div className={styles.currency}>{x.currency}</div>
                <div className={styles.price}>{x.price}</div>
              </div>
            </div>
            <div className={styles.body}>
              {x.soon ? (
                <div className={styles.soon}>Coming soon</div>
              ) : (
                <div className={styles.btns}>
                  {
                    x?.hasDeposite && 
                    <Link
                      className={cn('button-stroke button-small', styles.button)}
                      to="/deposit"
                    >
                      <span>Deposit</span>
                      <Icon name="arrow-right" size="16" />
                    </Link>
                  }
                  {
                    x?.hasTransfer && 
                    <button
                      className={cn('button-stroke button-small', styles.button)}
                      onClick={(e) => handleTransferClick(e)}
                    >
                      Transfer
                    </button>
                  }
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <Transfer setVisibleTransfer={setVisibleTransfer}/>
      </Modal>
    </>
  )
}

export default AccountBalances
