import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './ProfileInfo.module.sass'
import Profile from '../../components/Profile'
import Dropdown from '../../components/Dropdown'
import Switch from '../../components/Switch'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FromikTextInput from 'components/FormComponents/TextInput'
import { useFormik } from 'formik'
import CountriesDropdown from 'components/CountriesDropdown'
import { countries } from 'common/countries'
import { SignupValidationSchema } from 'screens/SignUp/Form/signupValidationSchema'

const breadcrumbs = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Profile info',
  },
]

const languageOptions = ['🇺🇸 USA']

const settings = [
  {
    title: 'level 1',
    items: [
      {
        title: 'Deposit assets',
        id: 1,
      },
      {
        title: 'Withdraw assets',
        content: 'Enabled $1,000,000/day',
      },
      {
        title: 'Card purchases',
        id: 2,
      },
      {
        title: 'Bank deposit',
        id: 3,
      },
    ],
  },
  {
    title: 'level 2',
    items: [
      {
        title: 'Fiat and Spot wallet',
        id: 4,
      },
      {
        title: 'Margin wallet',
        content: 'Enabled 100x Leverage',
      },
    ],
  },
]

const ProfileInfo = () => {
  const [language, setLanguage] = useState(languageOptions[0])

  const [selectedFilters, setSelectedFilters] = useState([])
  const { clientData } = useSelector((state) => state?.Profile)

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id))
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id])
    }
  }
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const handleSubmit = (values) => {
    // dispatch(loginUser(values))
  }

  const progileForm = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      country: countries[0].phone,
      nationality: '',
      phone: '',
      dateOfBirth: '',
      gender: '',
      city: '',
      password: '',
    },
    validationSchema: SignupValidationSchema,
    onSubmit: handleSubmit,
  })
  const handleChangeCountryDropdown = (country) => {
    progileForm.setFieldValue('country', country.phone)
  }
  useEffect(()=>{
    if (clientData){
      progileForm.setFieldValue('firstName', clientData.firstName)
      progileForm.setFieldValue('lastName', clientData.lastName)
      progileForm.setFieldValue('email', clientData.email)
      progileForm.setFieldValue('country', clientData.country)
      progileForm.setFieldValue('nationality', clientData.nationality)
      progileForm.setFieldValue('phone', clientData.phone)
      progileForm.setFieldValue('dateOfBirth', clientData.dateOfBirth)
      progileForm.setFieldValue('gender', clientData.gender)
      progileForm.setFieldValue('city', clientData.city)
    }
  }, [clientData])

  return (
    <Profile title="Profile info" breadcrumbs={breadcrumbs}>
      <div className={styles.head}>
        <div className={styles.details}>
          <div className={styles.user}>
            {clientData?.firstName} {clientData?.lastName}
          </div>
          <div className={styles.email}>{clientData?.email}</div>
          <div className={styles.level}>Level 2 verified</div>
          <NavLink
            style={{ color: '#FFD166', border: '1px solid #FFD166', marginLeft: '10px', height:'30px' }}
            className={cn(
              'button-stroke button-small',
              'button',
              styles.button,
              {
                [styles.active]: pathname === '/dashboard',
              },
            )}
            to="/kyc"
          >
            {t('Upgrade')}
          </NavLink>
        </div>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={language}
          setValue={setLanguage}
          options={languageOptions}
        />
      </div>

      <div className={styles.settings}>
        <div className={styles.title}>Personal Details</div>
        <div className={styles.boxWrapper}>
          <div className={styles.inputBox}>
            <FromikTextInput
              className={styles.field}
              label='First Name'
              name="firstName"
              type="text"
              placeholder='First Name'
              onChange={progileForm.handleChange}
              value={progileForm.values.firstName}
              error={progileForm.errors.firstName}
              touched={progileForm.touched.firstName}
              errClassName={styles.errMessage}
              disabled
            />
          </div>
          <div className={styles.inputBox}>
            <FromikTextInput
              className={styles.field}
              label='Last Name'
              name="lastName"
              type="text"
              placeholder='Last Name'
              onChange={progileForm.handleChange}
              value={progileForm.values.lastName}
              error={progileForm.errors.lastName}
              touched={progileForm.touched.lastName}
              errClassName={styles.errMessage}
              disabled
            />
          </div>
          <div className={styles.inputBox}>
            <FromikTextInput
              className={styles.field}
              label='Email'
              name="email"
              type="email"
              placeholder='Email'
              onChange={progileForm.handleChange}
              value={progileForm.values.email}
              error={progileForm.errors.email}
              touched={progileForm.touched.email}
              errClassName={styles.errMessage}
              disabled
            />
          </div>
          <div className={styles.inputBox}>
            <FromikTextInput
              className={styles.field}
              label='Phone'
              name="phone"
              type="text"
              placeholder='Phone'
              onChange={progileForm.handleChange}
              value={progileForm.values.phone}
              error={progileForm.errors.phone}
              touched={progileForm.touched.phone}
              errClassName={styles.errMessage}
              disabled
            />
          </div>
          {/* <div className={styles.inputBox}>
            <FromikTextInput
              className={styles.field}
              label='Nationality'
              name="nationality"
              type="text"
              placeholder='Nationality'
              onChange={progileForm.handleChange}
              value={progileForm.values.nationality}
              error={progileForm.errors.nationality}
              touched={progileForm.touched.nationality}
              errClassName={styles.errMessage}
            />
          </div> */}
          {/* <div className={styles.inputBox}>
            <FromikTextInput
              className={styles.field}
              label='Date Of Birth'
              name="dateOfBirth"
              type="date"
              placeholder='Date Of Birth'
              onChange={progileForm.handleChange}
              value={progileForm.values.dateOfBirth}
              error={progileForm.errors.dateOfBirth}
              touched={progileForm.touched.dateOfBirth}
              errClassName={styles.errMessage}
            />
          </div> */}
          <div className={styles.countryPhone}>
            <CountriesDropdown
              label="Country code"
              value={progileForm.values}
              setValue={handleChangeCountryDropdown}
              options={countries}
              error={progileForm.errors.country}
              touched={progileForm.touched.country}
              errClassName={styles.errMessage}
            />
            <div className={styles.phoneNumber}>
              <FromikTextInput
                value={progileForm.values.phone}
                error={progileForm.errors.phone}
                touched={progileForm.touched.phone}
                errClassName={styles.errMessage}
                onChange={progileForm.handleChange}
                className={styles.field}
                label="Phone"
                name="phone"
                type="text"
                placeholder="Phone number"
              />
            </div>
          </div>
          {/* <div className={styles.inputBox}>
            <FromikTextInput
              className={styles.field}
              label='City'
              name="city"
              type="text"
              placeholder='City'
              onChange={progileForm.handleChange}
              value={progileForm.values.city}
              error={progileForm.errors.city}
              touched={progileForm.touched.city}
              errClassName={styles.errMessage}
            />
          </div> */}
          {/* <div className={styles.inputBox}>
            <div className={styles.category}>Gender</div>
            
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={language}
              setValue={setLanguage}
              options={languageOptions}
            />
          </div> */}
        </div>
      </div>
      
      <div className={styles.settings}>
        <div className={styles.title}>Features</div>
        {settings.map((x, index) => (
          <div className={styles.box} key={index}>
            <div className={styles.subtitle}>{x.title}</div>
            {x.items.map((item, index) => (
              <div className={styles.item} key={index}>
                <div className={styles.category}>{item.title}</div>
                {item.id && (
                  <Switch
                    className={styles.switch}
                    value={selectedFilters.includes(item.id)}
                    onChange={() => handleChange(item.id)}
                  />
                )}
                {item.content && (
                  <div className={styles.content}>{item.content}</div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={styles.btns}>
        <button className={cn('button', styles.button)}>Save settings</button>
      </div>
    </Profile>
  )
}

export default ProfileInfo
