import cn from 'classnames'
import { ChevronRight } from 'feather-icons-react/build/IconComponents'
import styles from './CashReward.module.sass'
import { useTranslation } from 'react-i18next'

const CashReward = () => {
  const { t } = useTranslation()

  return (
    <div className={cn('section', styles.main)}>
      <div className={styles.cashReward}>
        <div className={styles.cashRewardText}>
          <div className={styles.text}>
            <h1 className={cn('h1', styles.title)}>{t('“1000 USDT”')}</h1>
            <div className={styles.subTitle}>
              {t('Cash reward, easy to get')}
            </div>
          </div>
          <div className={styles.description}>
            {t('Do tasks and get rewards')}
          </div>
          <div className={styles.button}>
            {t('Reward Balance')} <ChevronRight size={20} />
          </div>
        </div>
        <img src="/images/content/crypto/reward.png" className={styles.image} />
      </div>
    </div>
  )
}

export default CashReward
