import React from 'react'
import cn from 'classnames'
import styles from './Steps.module.sass'

const items = [
  {
    title: 'Sign Up',
    image2x: '/images/content/step-1@2x.png',
    image: '/images/content/reward/referralProgram/EarnCommision.png',
    content: 'Send Invitation Code or Link to Others',
  },
  {
    title: 'Verify',
    image2x: '/images/content/step-2@2x.png',
    image: '/images/content/reward/referralProgram/OtherUsers.png',
    content: 'Other Users Register and Trade',
  },
  {
    title: 'Deposit',
    image2x: '/images/content/step-3@2x.png',
    image: '/images/content/reward/referralProgram/SendInvitationCode.png',
    content: 'Earn Commission from Trading Fees',
  },
]

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn('section', styles.section)} ref={scrollToRef}>
      <div className={cn('container', styles.container)}>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={cn('custom-item', styles.item)} key={index}>
              <div className={cn('custom-preview', styles.preview)}>
                <img
                  srcSet={`${x.image} 2x`}
                  src={x.image}
                  alt={`Step ${index + 1}`}
                />
              </div>
              {/* <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div> */}
              <div className={styles.content}>{x.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Steps
