import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Form.module.sass'
import { Link } from 'react-router-dom'
import TextInput from '../../../components/TextInput'
import { useDispatch, useSelector } from 'react-redux'
import { userForgetPassword } from 'store/actions'

const Form = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const { forgetError, forgetSuccessMsg } = useSelector((state) => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  // handle changee
  const handleChange = (e) => {
    setEmail(e.target.value)
  }
  function handleValidSubmit(e) {
    e.preventDefault()
    dispatch(userForgetPassword(email.toLowerCase()?.trim()))
  }
  return (
    <form className={styles.form} onSubmit={handleValidSubmit}>
      <div className={styles.top}>
        <h3 className={cn('h3', styles.title)}>Forgot password</h3>
        <div className={styles.info}>
          For security purposes, no withdrawals are permitted for 24 hours after
          password changed.
        </div>
      </div>
      <div style={{ marginBottom: '20px' }}>
        {forgetError && forgetError ? (
          <div
            className={styles.info}
            style={{ marginTop: '13px', color: 'red' }}
          >
            {forgetError}
          </div>
        ) : null}
        {forgetSuccessMsg ? (
          <div
            className={styles.info}
            style={{ marginTop: '13px', color: 'green' }}
          >
            {forgetSuccessMsg}
          </div>
        ) : null}
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Enter the account email"
          onChange={handleChange}
          value={email}
          name="email"
          type="email"
          placeholder="Your email"
          icon="email"
          required
        />
      </div>
      <button className={cn('button', styles.button)}>Reset</button>
      {/* <button className={cn('button', styles.button)} onClick={goNext}>
        Continue
      </button> */}
      <div className={styles.foot}>
        <Link className={styles.link} to="/login">
          Nevermind, I got it
        </Link>
      </div>
    </form>
  )
}

export default Form
