import { useState } from 'react'
import styles from './OpenClosePannel.module.sass'

const OpenClosePannel = ({ label1, label2, handleChange }) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleSwitch = (val) => {
    setIsOpen(val)
    handleChange(val)
  }

  return (
    <div className={styles.pannel}>
      <div className={styles.head}>
        <div
          className={isOpen ? styles.openActive : styles.openClose}
          onClick={() => handleSwitch(true)}
        >
          {label1}
        </div>
        <div
          className={isOpen ? styles.openClose : styles.closeActive}
          onClick={() => handleSwitch(false)}
        >
          {label2}
        </div>
      </div>
    </div>
  )
}

export default OpenClosePannel
