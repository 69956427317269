export const GET_IB_CLIENTS_START = 'GET_IB_CLIENTS_START'
export const GET_IB_CLIENTS_SUCCESS = 'GET_IB_CLIENTS_SUCCESS'
export const GET_IB_CLIENT_ACCOUNTS_START = 'GET_IB_CLIENT_ACCOUNTS_START'
export const GET_IB_CLIENT_ACCOUNTS_SUCCESS = 'GET_IB_CLIENT_ACCOUNTS_SUCCESS'
export const GET_IB_CLIENT_ACCOUNT_ACTIVITIES_START =
  'GET_IB_CLIENT_ACCOUNT_ACTIVITIES_START'
export const GET_IB_CLIENT_ACCOUNT_ACTIVITIES_SUCCESS =
  'GET_IB_CLIENT_ACCOUNT_ACTIVITIES_SUCCESS'
export const REQ_IB_START = 'REQ_IB_START'

// get ib client accounts (owned by the client ibMT4 + ibMT5)
export const GET_CLIENT_IB_ACCOUNTS_START = 'GET_CLIENT_IB_ACCOUNTS_START'
export const GET_CLIENT_IB_ACCOUNTS_SUCCESS = 'GET_CLIENT_IB_ACCOUNTS_SUCCESS'
export const GET_CLIENT_IB_ACCOUNTS_FAILURE = 'GET_CLIENT_IB_ACCOUNTS_FAILURE'

// get all clients accounts
export const GET_ALL_CLIENTS_IB_ACCOUNTS_START =
  'GET_ALL_CLIENTS_IB_ACCOUNTS_START'
export const GET_ALL_CLIENTS_IB_ACCOUNTS_SUCCESS =
  'GET_ALL_CLIENTS_IB_ACCOUNTS_SUCCESS'
export const GET_ALL_CLIENTS_IB_ACCOUNTS_FAILURE =
  'GET_ALL_CLIENTS_IB_ACCOUNTS_FAILURE'
