module.exports = {
  // SOCKET_URL: process.env.REACT_APP_API_CP_DOMAIN,
  // BACKEND_CP_URL: process.env.REACT_APP_SOCKET_URL,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
  BACKEND_CP_URL: process.env.REACT_APP_API_CP_DOMAIN,
  defaultPortal: process.env.REACT_APP_DEFAULT_PORTAL || 'CRYPTO',
  companyCryptoDeposit:
    process.env.REACT_APP_COMPANY_CRYPTO_DEPOSIT === 'true' || false,
  COMPANY_WALLETS: [
    {
      name: 'Bitcoin',
      networks: [
        {
          name: 'Bitcoin',
          address: '1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX',
          tag: 'bitcoin',
        },
      ],
    },
  ],
}
