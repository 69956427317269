export const FETCH_WALLETS_FAILED = 'FETCH_WALLETS_FAILED'
export const FETCH_WALLETS_START = 'FETCH_WALLETS_START'
export const FETCH_WALLETS_SUCCESS = 'FETCH_WALLETS_SUCCESS'

export const REQUEST_WALLET_FX_TRANSFER_START =
  'REQUEST_WALLET_FX_TRANSFER_START'
export const REQUEST_WALLET_FX_TRANSFER_SUCCESS =
  'REQUEST_WALLET_FX_TRANSFER_SUCCESS'
export const REQUEST_WALLET_FX_TRANSFER_ERROR =
  'REQUEST_WALLET_FX_TRANSFER_ERROR'

export const FETCH_WALLET_REPORT_START = 'FETCH_WALLET_REPORT_START'
export const FETCH_WALLET_REPORT_SUCCESS = 'FETCH_WALLET_REPORT_SUCCESS'
export const FETCH_WALLET_REPORT_FAILED = 'FETCH_WALLET_REPORT_FAILED'
