import cn from 'classnames'
import { Link } from 'react-router-dom'
import styles from './Main.module.sass'
import ScrollButton from '../../../components/ScrollButton'
import Cards from './Cards'

const Main = ({ scrollToRef }) => {
  return (
    <div className={cn('section', styles.main2)}>
      <div className={cn('container', styles.container)}>
        <div className={cn(styles.topBanner)}>
          <div className={styles.wrap}>
            <h1 className={cn('h1', styles.title)}>
              Revolutionizing Crypto Exchange with FiniticX: Your End-to-End
              Solution Partner
            </h1>
            <div className={styles.text}>
              Unleash the potential of your crypto exchange with FiniticX{"'"}s
              integrated solutions. Combining advanced technology, strategic
              marketing, and operational expertise, we pave the way for your
              platform{`'`}s success.
            </div>
            <Link className={cn('button', styles.button)} to="/sign-up">
              Get started now
            </Link>
            <ScrollButton
              onScroll={() =>
                scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
              }
              className={styles.scroll}
            />
          </div>
          <div className={cn('bg', styles.banner)}>
            <img
              srcSet="/images/content/crypto/new-crypto-images/home-banner.png"
              src="/images/content/crypto/new-crypto-images/home-banner.png"
              alt="Cards"
              className={styles.bannerImage}
            />
          </div>
        </div>
        <Cards className={styles.cards} />
      </div>
    </div>
  )
}

export default Main
