import React from 'react'
import cn from 'classnames'
import styles from './Main.module.sass'
import { useTranslation } from 'react-i18next'

const Main = () => {
  const { t } = useTranslation()

  return (
    <div className={cn('section', styles.main)}>
      <div className={cn('container', styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn('h1', styles.title)}>
            {t('Today’s Cryptocurrency prices')}
          </h1>
          <div className={styles.text}>
            {t('The global crypto market cap is')} <strong>$1.86T</strong>
          </div>
        </div>
        <div className={cn('bg', styles.bg)}>
          <img
            srcSet="/images/content/crypto/new-crypto-images/market-banner.png"
            src="/images/content/crypto/new-crypto-images/market-banner.png"
            alt="Cards"
          />
        </div>
      </div>
    </div>
  )
}

export default Main
