import SocketContext from 'context/context'
import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchAssets,
  fetchBankAccounts,
  fetchDictStart,
  fetchHighKlines,
  fetchLogs,
  fetchProfile,
  fetchMarkets
} from 'store/actions'

const PortalEntry = () => {
  const dispatch = useDispatch()
  const { setState } = useContext(SocketContext)
  const profile = useSelector((state) => state?.Profile?.clientData)

  useEffect(() => {
    dispatch(fetchDictStart())
    dispatch(fetchHighKlines())
    dispatch(fetchAssets())
    dispatch(fetchMarkets({
      type: 'SPOT',
    }))
    // if (localStorage.getItem('authUser')) {
    //   dispatch(fetchProfile())
    //   dispatch(
    //     fetchLogs({
    //       limit: 10,
    //       page: 1,
    //     }),
    //   )
    //   dispatch(fetchBankAccounts())
    // }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      dispatch(fetchProfile())
      dispatch(
        fetchLogs({
          limit: 10,
          page: 1,
        }),
      )
      dispatch(fetchBankAccounts())
    }
  }, [localStorage.getItem('authUser')])

  useEffect(() => {
    setState((state) => {
      return {
        ...state,
        markupId: profile?.markupId?._id,
      }
    })
  }, [profile])

  return <></>
}
export default PortalEntry
