export const CLEAR_ACCOUNTS_STATE = 'CLEAR_ACCOUNTS_STATE'
export const CREATE_ACCOUNT_START = 'CREATE_ACCOUNT_START'
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS'
export const CREATE_ACCOUNT_FAIL = 'CREATE_ACCOUNT_FAIL'

export const GET_ACCOUNTS_START = 'GET_ACCOUNTS_START'
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS'

export const GET_ACCOUNT_TYPES_START = 'GET_ACCOUNT_TYPES_START'
export const GET_ACCOUNT_TYPES_SUCCESS = 'GET_ACCOUNT_TYPES_SUCCESS'

export const UPDATE_LEVERAGE_START = 'UPDATE_LEVERAGE_START'
export const UPDATE_LEVERAGE_SUCCESS = 'UPDATE_LEVERAGE_SUCCESS'
export const UPDATE_LEVERAGE_FAIL = 'UPDATE_LEVERAGE_FAIL'

export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

export const GET_OPEN_POSITIONS_START = 'GET_OPEN_POSITIONS_START'
export const GET_OPEN_POSITIONS_SUCCESS = 'GET_OPEN_POSITIONS_SUCCESS'

export const GET_CLOSE_POSITIONS_START = 'GET_CLOSE_POSITIONS_START'
export const GET_CLOSE_POSITIONS_SUCCESS = 'GET_CLOSE_POSITIONS_SUCCESS'

export const GET_TRANSFERS_START = 'GET_TRANSFERS_START'
export const GET_TRANSFERS_SUCCESS = 'GET_TRANSFERS_SUCCESS'

export const CREATE_INTERNAL_TRANSFER_START = 'CREATE_INTERNAL_TRANSFER_START'
export const CREATE_INTERNAL_TRANSFER_SUCCESS =
  'CREATE_INTERNAL_TRANSFER_SUCCESS'
export const CREATE_INTERNAL_TRANSFER_FAIL = 'CREATE_INTERNAL_TRANSFER_FAIL'

export const CREATE_ACCOUNT_REQUEST_START = 'CREATE_ACCOUNT_REQUEST_START'
export const CREATE_ACCOUNT_REQUEST_SUCCESS = 'CREATE_ACCOUNT_REQUEST_SUCCESS'
export const CREATE_ACCOUNT_REQUEST_FAIL = 'CREATE_INTERNAL_TRANSFER_FAIL'
