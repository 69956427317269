import React, { useEffect } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import styles from './AssetBalances.module.sass'
import { useSelector, useDispatch } from 'react-redux'
import { FaStar } from 'react-icons/fa'
import { FaRegStar } from 'react-icons/fa'

const AssetBalances = ({ activeWallet }) => {
  const { wallets, allWallets } = useSelector((state) => state?.walletReducer)
  
  return (
    <div className={styles.wrap}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Asset</div>
          {/* TODO: Crypto APR Earn */}
          {/* <div className={styles.col}>Earn</div> */}
          <div className={styles.col}>On orders</div>
          {
            (activeWallet && activeWallet !== "funding") && <div className={styles.col}>Bouns balance</div>
          }
          <div className={styles.col}>Available balance</div>
          <div className={styles.col}>Total balance</div>
        </div>
        {(activeWallet ? allWallets?.[activeWallet] : wallets)
          ?.filter((x) => x.isCrypto)
          .sort(
            (a, b) =>
              b.marketPrice * (b.freezeAmount + b.amount) -
              a.marketPrice * (a.freezeAmount + a.amount),
          )
          .map((x, index) => (
            <Link className={styles.row} to={`/wallet/${x._id}`} key={index}>
              <div className={styles.col}>
                <div className={styles.asset}>
                  <div className={styles.icon}>
                    {/* if not crypto show white background */} 
                    <img
                      src={`/images/content/crypto/${x.asset}.svg`}
                      alt={x.asset}
                      style={
                        x.isCrypto
                          ? {
                              backgroundColor: '#fff',
                            }
                          : {}
                      }
                    />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.info}>{x.asset}</div>
                    <div className={styles.text}>{x.content}</div>
                  </div>
                </div>
              </div>
              {/* <div className={styles.col}>
              {x.earn && (
                <div className={cn('category-green', styles.category)}>
                  {x.earn}
                </div>
              )}
            </div> */}
              <div className={styles.col}>
                <div className={styles.info}>{x.freezeAmount}</div>
                <div className={styles.text}>
                  {(x.marketPrice * x.freezeAmount).toFixed(4)} USDT
                </div>
              </div>
              {
                (activeWallet && activeWallet !== "funding") &&
                <div className={styles.col}>
                  <div className={styles.info}>{x?.bouns_amount}</div>
                  <div className={styles.text}>
                    {(x?.bouns_amount || 0)?.toFixed(4)} USDT
                  </div>
                </div>
              }
              <div className={styles.col}>
                <div className={styles.info}>{x.amount}</div>
                <div className={styles.text}>
                  {(x.marketPrice * x.amount).toFixed(4)} USDT
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.info}>{x.freezeAmount + x.amount}</div>
                <div className={styles.text}>
                  {(x.marketPrice * (x.freezeAmount + x.amount)).toFixed(4)}{' '}
                  USDT
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  )
}

export default AssetBalances
