import { useTranslation } from 'react-i18next'
import styles from './BeginnerTask.module.sass'

const BeginnerTask = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src="/images/content/crypto/search-page.png" />
        <div className={styles.desc}>
          <div className={styles.text}>{t('There are currently no tasks')}</div>
          <div className={styles.text}>{t('Looking forward to more')}</div>
          <div className={styles.text}>{t('tasks')}</div>
        </div>
      </div>
      <div className={styles.description}>
        {t(
          ' If the task is not completed within the time limit, the task will be automatically closed and no reward can be obtained',
        )}
      </div>
    </div>
  )
}

export default BeginnerTask
