import { useContext, useState, useEffect } from 'react'
import SocketContext from 'context/context'
import { useSelector } from 'react-redux'

const PRICE_INIT = {
  currentPrice: 0,
  priceChange24h: 0,
  low24h: 0,
  high24h: 0,
  volume24h: 0,
  percentage: 0,
  buyPrice: 0,
  sellPrice: 0,
  pairName: '',
}

export function usePricingMonitor(pairName) {
  const [pricingData, setPricingData] = useState(PRICE_INIT)
  const { markets } = useSelector((state) => state.crypto?.markets)
  const { state } = useContext(SocketContext)
  const { orderBooks, markets: marketWS } = state
  // const prevBP = useRef()
  // const prevSP = useRef()

  // useEffect(() => {
  //   const foundMarket = markets && markets.find((x) => x.pairName === pairName)
  //   const foundOrderBook =
  //     orderBooks && orderBooks.find((x) => x.pairName === pairName)
  //   const buyPrice =
  //     (foundOrderBook && foundOrderBook.asks[0] && foundOrderBook.asks[0][0]) ||
  //     0
  //   const sellPrice =
  //     (foundOrderBook && foundOrderBook.bids[0] && foundOrderBook.bids[0][0]) ||
  //     0

  //   if (foundMarket) {
  //     setPricingData({
  //       currentPrice: foundMarket?.marketPrice?.$numberDecimal,
  //       close: foundMarket?.close?.$numberDecimal,
  //       priceChange24h: 1,
  //       low24h: foundMarket?.low24h?.$numberDecimal,
  //       high24h: foundMarket?.high24h?.$numberDecimal,
  //       volume24h: foundMarket?.volume24h?.$numberDecimal,
  //       percentage: foundMarket?.percentage,
  //       buyPrice,
  //       pairName,
  //       isPositive: parseFloat(foundMarket?.percentage) >= 0,
  //     })
  //   }
  // }, [])


  useEffect(() => {
    const foundMarket = marketWS && marketWS.find((x) => x.instanceId === pairName)
    const sMarket = markets && markets.find((x) => x.instanceId === pairName)
    const foundOrderBook = orderBooks && orderBooks.find((x) => x.pairName === pairName)
    const buyPrice =
      (foundOrderBook && foundOrderBook.asks[0] && foundOrderBook.asks[0][0]) ||
      0
    const sellPrice =
      (foundOrderBook && foundOrderBook.bids[0] && foundOrderBook.bids[0][0]) || 0
    if (foundMarket || sMarket){
      const { info: foundInfo } = foundMarket || {};
      const { volume24h: sVolume24h, high24h: sHigh24h, low24h: sLow24h, close: sClose, open: sOpen, marketPrice: sMarketPrice, percentage: sPercentage } = sMarket || {};
      const askVolume = foundMarket?.askVolume;
      const bidVolume = foundMarket?.bidVolume;
      const volume24h = foundInfo?.vol24h ?? sVolume24h?.$numberDecimal;
      const percentage = foundMarket?.percentage?.$numberDecimal ?? foundMarket?.percentage ?? sPercentage;
      const isPositive = parseFloat(percentage) >= 0;
      const high24h = foundInfo?.high24h ?? sHigh24h?.$numberDecimal;
      const low24h = foundInfo?.low24h ?? sLow24h?.$numberDecimal;
      const close = sClose?.$numberDecimal ?? foundMarket?.close;
      const open = foundMarket?.open?.$numberDecimal ?? foundMarket?.open;
      const currentPrice = foundMarket?.last ?? sMarketPrice ?? sMarketPrice?.$numberDecimal;
      const pricing = {
        askVolume,
        bidVolume,
        volume24h,
        percentage,
        pairName,
        isPositive,
        high24h,
        low24h,
        close,
        open,
        currentPrice,
        buyPrice,
        sellPrice,
        instanceId: foundMarket?.instanceId,
      };
      setPricingData(pricing);
    }
  }, [marketWS, pairName, markets, orderBooks])


  return pricingData
}
