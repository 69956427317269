import { verifyCodeAPI, generateQRCodeAPI } from 'apis/auth'
// eslint-disable-next-line object-curly-newline
import { call, put, takeEvery } from 'redux-saga/effects'
import { loginSuccess, toggleCurrentModal } from 'store/actions'
import {
  showErrorNotification,
  showSuccessNotification,
} from 'store/general/notifications/actions'
import {
  generateQRCodeSuccess,
  verify2FACodeFail,
  verify2FACodeSuccess,
  generateQRCodeError,
} from './actions'

import {
  GENERATE_QR_CODE_START,
  VERIFY_TWO_FACTOR_CODE_START,
} from './actionTypes'

function* generateQRCode(params) {
  try {
    const data = yield call(generateQRCodeAPI, params.payload)
    const { status, result } = data
    if (status) yield put(generateQRCodeSuccess(result))
  } catch (error) {
    yield put(generateQRCodeError(error.message))
    yield put(showErrorNotification(error.message))
  }
}

function* verifyCode({ payload }) {
  try {
    
    const { navigate, type } = payload
    delete payload.history
    const data = yield call(verifyCodeAPI, payload)
    const { result, status } = data

    if (type === 'login') {
      if (status && result.token) {
        localStorage.setItem('authUser', JSON.stringify(result))
        yield put(loginSuccess(result))
        yield put(verify2FACodeSuccess(data))
        yield put(toggleCurrentModal(''))
        showSuccessNotification('Logged succcessfully')
        navigate('/dashboard')
      }
    } else {
      yield put(
        verify2FACodeSuccess({
          ...data,
          type,
        }),
      )
      // yield put(showSuccessNotification('Verified succcessfully'))
      showSuccessNotification('Verified succcessfully')
    }
  } catch (error) {
    yield put(verify2FACodeFail(error.message))
    // yield put(showErrorNotification(error.message))
    showErrorNotification(error.message)
  }
}

function* twoFactorAuthSaga() {
  yield takeEvery(GENERATE_QR_CODE_START, generateQRCode)
  yield takeEvery(VERIFY_TWO_FACTOR_CODE_START, verifyCode)
}

export default twoFactorAuthSaga
