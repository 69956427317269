import React from 'react'
import cn from 'classnames'
import styles from './Banner.module.sass'
import { useTranslation } from 'react-i18next'

const Banner = () => {
  const { t } = useTranslation()

  return (
    <div className={cn('section', styles.banner)}>
      <div className={styles.content}>
        <div className={cn('h2', styles.title)}>
          {t('Earn Like Never Before!')}
        </div>
        <div className={styles.subTitle}>
          {t('Join the FiniticX Affiliate Program today and Gain up to')}
          <b> {t('50% commission')} </b>
          {t('on each referral')}
        </div>
        <button className={styles.button}>{t('Get started now')}</button>
      </div>
      <img
        src="/images/content/crypto/new-crypto-images/affiliate-page-banner.png"
        className={styles.bannerImage}
      />
    </div>
  )
}

export default Banner
