import cn from 'classnames'
import styles from './Trade.module.sass'
import { Link } from 'react-router-dom'
import Icon from '../../../../components/Icon'
import { AreaChart, Area, ResponsiveContainer } from 'recharts'
import { useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const items = [
  {
    title: 'Bitcoin',
    currency: 'BTC/USDT',
    image: '/images/content/crypto/BTC.svg',
  },
  {
    title: 'Ethereum',
    currency: 'ETH/USDT',
    image: '/images/content/crypto/ETH.svg',
  },
  {
    title: 'TRX',
    currency: 'TRX/USDT',
    image: '/images/content/crypto/TRX.svg',
  },
  {
    title: 'LTC',
    currency: 'LTC/USDT',
    image: '/images/content/crypto/LTC.svg',
  },
  {
    title: 'MATIC',
    currency: 'MATIC/USDT',
    image: '/images/content/crypto/MATIC.svg',
  },
  {
    title: 'Dogecoin',
    currency: 'DOGE/USDT',
    image: '/images/content/crypto/DOGE.svg',
  },
  {
    title: 'XRP',
    currency: 'XRP/USDT',
    image: '/images/content/crypto/XRP.svg',
  },
  {
    title: 'SHIB',
    currency: 'SHIB/USDT',
    image: '/images/content/crypto/SHIB.svg',
  },
  {
    title: 'LINK',
    currency: 'LINK/USDT',
    image: '/images/content/crypto/LINK.svg',
  },
  {
    title: 'BNB',
    currency: 'BNB/USDT',
    image: '/images/content/crypto/BNB.svg',
  },
]

const data = [
  {
    name: '1',
    price: 1000,
  },
  {
    name: '2',
    price: 2300,
  },
  {
    name: '3',
    price: 2000,
  },
  {
    name: '4',
    price: 2780,
  },
  {
    name: '5',
    price: 1890,
  },
  {
    name: '6',
    price: 2390,
  },
  {
    name: '7',
    price: 2490,
  },
  {
    name: '8',
    price: 3000,
  },
  {
    name: '9',
    price: 2500,
  },
  {
    name: '10',
    price: 2000,
  },
  {
    name: '11',
    price: 2780,
  },
  {
    name: '12',
    price: 1890,
  },
  {
    name: '13',
    price: 2390,
  },
  {
    name: '14',
    price: 1490,
  },
]

const MarketCard = ({ marketItem }) => {
  const { t } = useTranslation()
  const x = marketItem
  const markets = useSelector((state) => state?.crypto?.markets?.markets)
  console.log(markets, 'markets')
  const marketPriceData = useMemo(() => {
    const data = markets?.filter((market) => market?.pairName === x.currency)[0]

    if (!data)
      return {
        marketPrice: '0.00',
        percentage: '0.00',
        close: '0.00',
        volume: '0.00',
        marketCap: '0.00',
      }

    return {
      marketPrice: data?.marketPrice?.$numberDecimal,
      percentage: data?.percentage,
      close: data?.close?.$numberDecimal,
      volume: data?.volume?.$numberDecimal,
      marketCap: data?.marketCap?.$numberDecimal,
    }
  }, [markets])

  return (
    <>
      <div className={styles.col}>
        <div className={styles.label}>{t('Price')}</div>$
        {marketPriceData.marketPrice}
      </div>
      <div className={styles.col}>
        <div className={styles.label}>24h</div>
        <div
          className={
            marketPriceData.percentage >= 0 ? styles.positive : styles.negative
          }
        >
          {marketPriceData.percentage}%
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>24h</div>
        <div
          className={
            marketPriceData.percentage >= 0 ? styles.positive : styles.negative
          }
        >
          {marketPriceData.percentage}%
        </div>
      </div>
      <div className={styles.col}>
        <div
          className={
            marketPriceData.percentage >= 0 ? styles.positive : styles.negative
          }
        >
          {marketPriceData.marketCap}0.00
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>{t('Volume')} (24h)</div>$
        {marketPriceData.volume}
      </div>
    </>
  )
}

const Trade = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.trade}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">#</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">{t('Name')}</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">{t('Price')}</div>
          </div>
          <div className={styles.col}>24h %</div>
          <div className={styles.col}>7d %</div>
          <div className={styles.col}>
            {t('Marketcap')} <Icon name="coin" size="20" />
          </div>
          <div className={styles.col}>
            {t('Volume')} (24h) <Icon name="chart" size="20" />
          </div>
          <div className={styles.col}>{t('Chart')}</div>
        </div>
        {items.map((x, index) => (
          <div className={styles.row} key={index}>
            <div className={cn('market-last-column', styles.col)}>
              <div className={styles.line}>
                {/* <button className={cn('favorite', 'fav', styles.favorite)}>
                  <Icon name="star-outline" size="16" />
                </button> */}
                {index + 1}
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.item}>
                <div className={cn('currency-icon', styles.icon)}>
                  <img src={x.image} alt="Coin" />
                </div>
                <div className={styles.details}>
                  <span className={styles.subtitle}>{x.title}</span>
                  <span className={styles.currency}>{x.currency}</span>
                </div>
              </div>
            </div>
            <MarketCard marketItem={x} />
            <div className={cn('market-first-column', styles.col)}>
              <div className={styles.chartInner}>
                <div className={styles.chart}>
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      width={500}
                      height={400}
                      data={data}
                      margin={{
                        top: 3,
                        right: 0,
                        left: 0,
                        bottom: 3,
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="colorPrice"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#45B36B"
                            stopOpacity={0.6}
                          />
                          <stop
                            offset="95%"
                            stopColor="#45B36B"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <Area
                        type="monotone"
                        dataKey="price"
                        stroke="#58BD7D"
                        fillOpacity={1}
                        fill="url(#colorPrice)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <Link className={cn('button-small', styles.button)} to={x.url}>
                {t('Buy')}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Trade
