import React, { useContext, useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Trades.module.sass'
import { useTranslation } from 'react-i18next'
import SocketContext from 'context/context'
import moment from 'moment'
import { fetchAllLastTrades } from 'apis/trades'
import { updateAllTrades } from 'socket/events'

const navigation = [
  'Last Trades'
]

const topMovesItems = [
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    positive: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    positive: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    positive: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    positive: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    positive: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    positive: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    positive: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    positive: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    positive: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
  {
    positive: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    negative: '36,780.64',
    amount: '0.003670',
    time: '18:12:16',
  },
  {
    positive: '36,780.64',
    amount: '0.135420',
    time: '18:12:16',
  },
]

const Trades = (props) => {
  const { selectedMarket } = props
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState(0)
  const [items, setItems] = useState([])
  const [priceSymbol, setPriceSymbol] = useState(selectedMarket.split('-')[0])
  const [amountSymbol, setAmountSymbol] = useState(selectedMarket.split('-')[1])
  const { state, setState } = useContext(SocketContext)
  const { trades, klines } = state

  useEffect(() => {
    setPriceSymbol(selectedMarket.split('-')[0])
    setAmountSymbol(selectedMarket.split('-')[1])
  }, [selectedMarket])

  useEffect(() => {
    if (activeIndex === 1){
      setItems(topMovesItems)
    } else if (activeIndex === 0){
      let tradesData = trades?.[`${klines?.instanceId}`]?.map(trade=>{
        return {
            negative: trade?.side === "sell" ? trade?.price : null,
            positive: trade?.side === "buy" ? trade?.price : null,
            amount: trade?.amount,
            time: moment(trade?.timestamp).format('HH:mm:ss'),
        }
      })
      setItems(tradesData)
    }
  }, [activeIndex, trades?.[`${klines?.symbol}`]])
  
  async function getLastTrades(){
    const data = await fetchAllLastTrades()
    const allTrades = updateAllTrades(trades, data)
    setState((state) => {
      return {
        ...state,
        trades:allTrades,
      }
    })
  }
  useEffect(()=>{
    getLastTrades()
  }, [])
  return (
    <div className={styles.trades}>
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {t(x)}
          </button>
        ))}
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            {t('Price')} ({priceSymbol})
          </div>
          <div className={styles.col}>
            {t('Amount')} ({amountSymbol})
          </div>
          <div className={styles.col}>{t('Time')}</div>
        </div>
        
        {items?.map((x, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.col}>
              {x.positive && (
                <div className={styles.positive}>{x.positive}</div>
              )}
              {x.negative && (
                <div className={styles.negative}>{x.negative}</div>
              )}
            </div>
            <div className={styles.col}>{x.amount}</div>
            <div className={styles.col}>{x.time}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Trades
