import {
  ChevronDown,
  ChevronRight,
  ChevronUp,
} from 'feather-icons-react/build/IconComponents'
import styles from './LeveragePannel.module.sass'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Modal from 'components/Modal'
import AdjustLeverage from 'components/AdjustLeverage'
import { useTranslation } from 'react-i18next'

const leverages = [1, 2, 3, 5, 10]

const LeveragePannel = ({ selectedOption, setSelectedOption }) => {
  const { t } = useTranslation()
  const [selectedMargined, setSelectedMargined] = useState('BTC')
  const [openOptions, setOpenOptions] = useState(false)
  const [openMargined, setOpenMargined] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)

  const { selectedLayout } = useSelector(
    (state) => state?.crypto?.layoutReducer,
  )

  const handleClickOption = (value) => {
    setSelectedOption(value)
    setOpenOptions(false)
  }

  const handleClickMargined = (value) => {
    setSelectedMargined(value)
    setOpenMargined(false)
  }

  return (
    <>
      <div className={styles.pannel}>
        <div className={styles.isolated}>
          <span onClick={() => setOpenOptions(!openOptions)}>
            {selectedOption}{' '}
            {openOptions ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          </span>
          {openOptions && (
            <div className={styles.options}>
              <div
                className={styles.option}
                onClick={() => handleClickOption('Cross')}
              >
                {t('Cross')}
              </div>
              <div
                className={styles.option}
                onClick={() => handleClickOption('Isolated')}
              >
                {t('Isolated')}
              </div>
            </div>
          )}
        </div>
        <div
          className={
            selectedOption === 'Cross' ? styles.isolated : styles.disabled
          }
        >
          <span onClick={() => setOpenMargined(!openMargined)}>
            {selectedMargined}-margined{' '}
            {openMargined ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          </span>
          {selectedOption === 'Cross' && openMargined && (
            <div className={styles.options}>
              <div
                className={styles.option}
                onClick={() => handleClickMargined('BTC')}
              >
                {t('BTC-margined')}
              </div>
              <div
                className={styles.option}
                onClick={() => handleClickMargined('USDT')}
              >
                {t('USDT-margined')}
              </div>
            </div>
          )}
        </div>
        <div className={styles.leverage} onClick={() => setVisibleModal(true)}>
          5.00x <ChevronRight size={16} />
        </div>
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <AdjustLeverage
          currency={'BTC/USDT'}
          leverageType={selectedOption}
          buySell={'Buy'}
          leverages={leverages}
        />
      </Modal>
    </>
  )
}

export default LeveragePannel
