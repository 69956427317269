import { useTranslation } from 'react-i18next'
import styles from './UserAgreement.module.sass'
import cn from 'classnames'

const UserAgreement = () => {
  const { t } = useTranslation()

  return (
    <div className={'container'}>
      <div className={cn('section')}>
        <div className={cn('h4', styles.title)}>{t('User Agreement')}</div>
        {/* <div className={styles.dateContainer}>
          <div className="h5">Privacy Policy for FiniticX</div>
          <div className="h5">Effective Date: 1st Jan 2024</div>
        </div> */}

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Introduction</div>
          <div className={cn(styles.peragraph)}>
            FiniticX operates as a technology provider offering solutions for
            digital asset exchanges ({`"`}Services{`"`}). This User Agreement governs
            your use of our Services.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>
            Acknowledgement and Acceptance of Terms
          </div>
          <div className={cn(styles.peragraph)}>
            By registering and using FiniticX, you agree to comply with this
            User Agreement and confirm that you are of legal age to form a
            binding contract with FiniticX. You acknowledge that digital asset
            trading carries inherent risks and affirm that FiniticX is not
            responsible for losses incurred due to such risks.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn(styles.row)}>
            <div className={cn('h5', styles.subTitle)}>Services Provided</div>
            <div className={cn(styles.peragraph)}>
              FiniticX provides a platform for the establishment and operational
              support of digital asset exchanges. The Services include, but are
              not limited to:
            </div>
          </div>
          <ul>
            <li> Digital asset exchange setup solutions.</li>
            <li> Operational support and consultancy.</li>
            <li> Marketing and growth strategies for your exchange.</li>
          </ul>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>User Responsibilities</div>
          <div className={cn(styles.peragraph)}>
            Ensure the legality of digital assets managed through our platform.
          </div>
          <div className={cn(styles.peragraph)}>
            Safeguard account information and passwords.
          </div>
          <div className={cn(styles.peragraph)}>
            Assume full responsibility for all transactions and
            non-transactional actions within your account.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>FiniticX Rights</div>
          <div className={cn(styles.peragraph)}>
            FiniticX reserves the right to modify, suspend, or discontinue the
            Services at any time.
          </div>
          <div className={cn(styles.peragraph)}>
            We may revoke user access if terms are violated or if unauthorized
            usage is detected.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Intellectual Property</div>
          <div className={cn(styles.peragraph)}>
            All content on the FiniticX platform, including trademarks, logos,
            and software, is the property of FiniticX.
          </div>
          <div className={cn(styles.peragraph)}>
            Users are prohibited from misusing any intellectual property found
            on the platform.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Liability Limitation</div>
          <div className={cn(styles.peragraph)}>
            FiniticX will not be liable for indirect, incidental, special, or
            consequential damages resulting from the use of our Services.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Amendments</div>
          <div className={cn(styles.peragraph)}>
            FiniticX reserves the right to amend this User Agreement. Continued
            use of the Services after changes constitutes acceptance of the new
            terms.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Governing Law</div>
          <div className={cn(styles.peragraph)}>
            This User Agreement shall be governed by the laws of the
            jurisdiction where FiniticX is established.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Termination</div>
          <div className={cn(styles.peragraph)}>
            Either party may terminate this Agreement in accordance with its
            terms. Upon termination, FiniticX will cease providing Services to
            the user.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>
            Contact and Communication
          </div>
          <div className={cn(styles.peragraph)}>
            Users consent to receive electronic communications from FiniticX and
            understand that all agreements, notices, disclosures, and other
            communications that FiniticX provides electronically satisfy any
            legal requirement that such communications be in writing.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Entire Agreement</div>
          <div className={cn(styles.peragraph)}>
            This User Agreement constitutes the entire agreement between the
            user and FiniticX regarding the Services provided.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>
            Acceptance of the Agreement
          </div>
          <div className={cn(styles.peragraph)}>
            By using FiniticX{`'`}s Services, you acknowledge that you have read
            this agreement, understand it, and agree to be bound by its terms
            and conditions.
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserAgreement
