import React from 'react'
import Form from './Form'

const Market = ({
  amount,
  contentBuy,
  contentSell,
  limit,
  setValue,
  visible,
}) => {
  return (
    <div>
      <Form
        contentBuy={contentBuy}
        contentSell={contentSell}
        price={limit}
        amount={amount}
        visible={visible}
        setValue={setValue}
        tpsl={true}
      />
    </div>
  )
}

export default Market
