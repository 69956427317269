import { call, put, takeEvery } from 'redux-saga/effects'

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes'
import { apiError, loginSuccess } from './actions'
import {
  changePortal,
  showErrorNotification,
  showSuccessNotification,
  switchSubPortal,
} from '../../actions'
import { loginUserAPI } from '../../../../apis/auth'
import { toggleCurrentModal } from 'store/actions'

function* loginUser({ payload: { user } }) {
  try {
    const data = yield call(loginUserAPI, user)
    const { result, status } = data
    if (status) {
      if (!result.token) {
        console.log('dasdsadasdaas', data)
        yield put(
          toggleCurrentModal('TwoFactorAuth', {
            email: user.email,
            type: 'login',
          }),
        )
        yield put(loginSuccess(result))
      }
      if (result.token) {
        localStorage.setItem('authUser', JSON.stringify(result))
        yield put(loginSuccess(result))
        showSuccessNotification('Login Success')
      }
    } else {
      yield put(apiError(data?.message || 'Something went wrong'))
      showErrorNotification(data?.message || 'Incorrect email or password')
    }
  } catch (error) {
    console.log(error)
    yield put(apiError(error))(
      showErrorNotification(error?.message || 'Something went wrong'),
    )
  }
}

function* logoutUser({ payload: { navigate } }) {
  try {
    localStorage.removeItem('authUser')
    navigate('/home')
  } catch (error) {
    console.log(error)
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
