import { useRef } from 'react'
import Main from './Main'
import Trend from './Trend'
import Popular from '../../components/Popular'
import Download from './Download'
import News from '../../components/News'
import Steps from './Steps'
import Banners from './Banners'
import RestAssured from './RestAssured'
import TradeWithCrypto from './CryptoBanner'
import EarnLikeBanner from 'screens/Reward/Affiliate/EarBanner'
import GrabDemoAccount from './GrabDemoAccount'

const Home = () => {
  const scrollToRef = useRef(null)

  return (
    <>
      <Main scrollToRef={scrollToRef} />
      <Banners />
      {/* <Learn scrollToRef={scrollToRef} /> */}
      <Trend />
      <Popular classSection="section-bg section-mb0" />
      <EarnLikeBanner />
      {/* <RestAssured /> */}
      {/* <TradeWithCrypto /> */}
      <Steps />
      <Download />
      <div className="container">
        {/* <GrabDemoAccount /> */}
      </div>
      {/* <News classSection="section-bg" /> */}
    </>
  )
}

export default Home
