import { useTranslation } from 'react-i18next'
import styles from './FutureFees.module.sass'
import cn from 'classnames'

const feeData = [
  {
    pair: 'BTCUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'ETHUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'BCHUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'TRXUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'EOSUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'ICPUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'XRPUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'ETCUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'LTCUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'LINKUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'ADAUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'UNIUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'FILUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'DOTUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'DOGEUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'QTUMUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'YFIUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'AAVEUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'SUSHIUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'COMPUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'KSMUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'BNBUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'ATOMUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'THETAUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'KNCUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'MATICUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'SOLUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'APEUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'GMTUSDT',
    openMaker: 0.025,
    closeMaker: 0.025,
    openTaker: 0.075,
    closeTaker: 0.075,
  },
  {
    pair: 'RSRUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'LRCUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'ENSUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'SANDUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'MANAUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'ENJUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'AXSUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'FLOWUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'OPUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'CHZUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'CRVUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'ALGOUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'NEARUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'APTUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'WOOUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'XLMUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'MAGICUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'WAVESUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'MASKUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'FTMUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'RUNEUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
  {
    pair: 'ROSEUSDT',
    openMaker: 0.02,
    closeMaker: 0.02,
    openTaker: 0.05,
    closeTaker: 0.05,
  },
]

const FutureFees = () => {
  const { t } = useTranslation()

  return (
    <div className={'container'}>
      <div className={'section'}>
        <div>
          <div className={cn('h3', styles.title)}>Trading Fees</div>
          <table>
            <thead>
              <tr>
                <th rowSpan="2">PAIRS</th>
                <th colSpan="2">MAKER FEE</th>
                <th colSpan="2">TAKER FEE</th>
              </tr>
              <tr>
                <th>Open Maker</th>
                <th>Close Maker</th>
                <th>Open Taker</th>
                <th>Close Taker</th>
              </tr>
            </thead>
            <tbody>
              {feeData.map((fee, index) => (
                <tr key={index}>
                  <td className={styles.pairNameCol}>{fee.pair}</td>
                  <td>{fee.openMaker}%</td>
                  <td>{fee.closeMaker}%</td>
                  <td>{fee.openTaker}%</td>
                  <td>{fee.closeTaker}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default FutureFees
