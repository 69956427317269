import cn from 'classnames'
import { useState } from 'react'
import styles from './Progress.module.sass'
import AllProgress from './AllProgress'
import BeginnerTask from './BeginnerTask'
import { useTranslation } from 'react-i18next'

const tabs = ['All in progress', "Beginner's task", 'Daily task']

const Progress = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className={cn('section', styles.section)}>
      <div className={styles.tabs}>
        {tabs.map((tab, index) => (
          <div
            className={cn(styles.tab, { [styles.active]: activeTab === index })}
            key={index}
            onClick={() => setActiveTab(index)}
          >
            {t(tab)}
          </div>
        ))}
      </div>
      {(activeTab === 0 || activeTab === 2) && (
        <AllProgress isDaily={activeTab === 2} />
      )}
      {activeTab === 1 && <BeginnerTask isDaily={activeTab === 2} />}
    </div>
  )
}

export default Progress
