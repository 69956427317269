import React, { useState, useEffect, useRef } from 'react'
import datafeedTest from './datafeed'
import { widget } from 'charting_library'
import { fetchOHLCVExchange } from 'apis/klines'
import useDarkMode from 'use-dark-mode'

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)')
  const results = regex.exec(window.location.search)
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

function resolveTf(time) {
  let result = ''
  switch (time) {
    case '1':
      result = '1m'
      break
    case '5':
      result = '5m'
      break
    case '15':
      result = '15m'
      break
    case '30':
      result = '30m'
      break
    case '60':
      result = '1h'
      break
    case '1H':
      result = '1h'
      break
    case '240':
      result = '4h'
      break
    case '1D':
      result = '1d'
      break
    case '1W':
      result = '1w'
      break
    case '1M':
      result = '1M'
      break
    default:
      break
  }
  return result
}

export default function TVChartContainer({ symbol, socketKlines }) {
  const chartContainerRef = useRef()
  const [timeFrame, setTimeFrame] = useState('1m')
  const [subscription, setSubscription] = useState(null)
  const { onReady, searchSymbols, resolveSymbol, getBars } = datafeedTest
  const darkMode = useDarkMode(false)

  function updateBar(data) {
    if (subscription && data && data?.close) {
      subscription.handler(data)
    }
  }
  // const switchTheme = () => {
  //   setTheme('Dark')
  // }
  const subscribeBarsCustom = (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
  ) => {
    const newSubscription = {
      symbol: symbolInfo.name,
      id: subscriberUID,
      handler: onRealtimeCallback,
    }
    setSubscription(newSubscription)
    setTimeFrame('' + resolution)
  }

  const unsubscribeBarsCustom = (subscriberUID) => {
    console.log('[unsubscribe] Success Unsubscribing')
    if (subscription?.id === subscriberUID) setSubscription(null)
  }

  function removeDuplicates(data) {
    return [...new Set(data)]
  }
  //https://stackoverflow.com/questions/16139452/how-to-convert-big-negative-scientific-notation-number-into-decimal-notation-str?lq=1
  function noExponents(num) {
    var data = String(num).split(/[eE]/)
    if (data.length == 1) return data[0]

    var z = '',
      sign = num < 0 ? '-' : '',
      str = data[0].replace('.', ''),
      mag = Number(data[1]) + 1

    if (mag < 0) {
      z = sign + '0.'
      while (mag++) z += '0'
      //eslint-disable-next-line
      return z + str.replace(/^\-/, '')
    }
    mag -= str.length
    while (mag--) z += '0'
    return (str + z).substring(0, 10)
  }
  const getbarsCustom = async function getBars(
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback,
  ) {
    // debugger;
    const { from, to, countBack, firstDataRequest } = periodParams
    console.log('[getBars]: Method call', symbolInfo)
    console.log('[getBars]: Period params=> ', resolution, from, to, countBack)
    try {
      let payload = {
        since: from * 1000,
        to: to * 1000,
        limit: countBack + 20,
        symbol,
        timeframe: resolveTf(resolution),
      }
      if (countBack < 100) {
        onHistoryCallback([], { noData: true })
      } else {
        const resp = await fetchOHLCVExchange({ payload })
        //console.log({api: resp});
        if (resp?.result) {
          const bars = resp.result.map((i) => {
            const bar = {
              time: i['0'],
              open: i['1'],
              high: i['2'],
              low: i['3'],
              close: i['4'],
              volume: i['5'],
            }
            return bar
          })
          //console.log("NORMALIZED DATA >>> ", {bars});
          onHistoryCallback(bars, { noData: true })
        }
        onHistoryCallback([], { noData: true })
      }
    } catch (error) {
      console.log('[getBars]: Get error', error)
      onErrorCallback(error)
    }
  }
  // useEffect(() => {
  //   switchTheme()
  // }, [appTheme])
  const defaultProps = {
    symbol: 'ETH/USDT',
    interval: '15',
    datafeedUrl: 'https://demo_feed.tradingview.com',
    library_path: '../../charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    timezone: 'Etc/UTC',
    has_intraday: false,
  }
  useEffect(() => {
    const widgetOptions = {
      symbol: symbol,
      datafeed: {
        onReady,
        searchSymbols,
        resolveSymbol,
        getBars: getbarsCustom,
        subscribeBars: subscribeBarsCustom,
        unsubscribeBars: unsubscribeBarsCustom,
      },
      interval: defaultProps.interval,
      container: chartContainerRef.current,
      library_path: defaultProps.library_path,
      debug: true,
      locale: getLanguageFromURL() || 'en',
      disabled_features: [
        'use_localstorage_for_settings',
        'header_symbol_search',
        'legend_widget',
      ],
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      'has-intraday': true,
      has_daily: false,
      has_weekly_and_monthly: false,
      studies_overrides: defaultProps.studiesOverrides,
      theme: darkMode.value ? 'Dark' : 'Light',
    }
    console.log('widgetOptions >>> ', widgetOptions)
    const tvWidget = new widget(widgetOptions)
    tvWidget.onChartReady(() => {
      console.log('Chart has loaded')
    })

    return () => {
      console.log('removing chart >>>')
      tvWidget.remove()
    }
  }, [symbol, darkMode.value])

  useEffect(() => {
    const bar = socketKlines.klines.data[socketKlines.klines.data.length - 1]
    if (bar) {
      const data = {
        time: bar?.time * 1000,
        open: bar?.open,
        high: bar?.high,
        low: bar?.low,
        close: bar?.close,
        volume: bar?.volume,
      }
      updateBar(data)
    }
  }, [subscription, symbol, socketKlines])

  return (
    <div
      ref={chartContainerRef}
      style={{
        height: '100%',
      }}
      className={'TVChartContainer'}
    />
  )
}
