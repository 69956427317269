export const articles = [
  {
    title: 'Top 7 NFT usecase',
    author: 'First name Last name',
    category: 'purple',
    categoryText: 'video tutorial',
    status: 'green',
    statusText: 'popular',
    image: '/images/content/article-pic-1.jpg',
    image2x: '/images/content/article-pic-1@2x.jpg',
    play: true,
    url: '/learn-crypto-details',
  },
  {
    title: 'Top 7 NFT usecase',
    author: 'First name Last name',
    categoryText: 'popular',
    status: 'green',
    statusText: 'popular',
    image: '/images/content/article-pic-2.jpg',
    image2x: '/images/content/article-pic-2@2x.jpg',
    url: '/learn-crypto-details',
  },
  {
    title: 'Top 7 NFT usecase',
    author: 'First name Last name',
    category: 'green',
    categoryText: 'new',
    status: 'green',
    statusText: 'popular',
    image: '/images/content/article-pic-3.jpg',
    image2x: '/images/content/article-pic-3@2x.jpg',
    url: '/learn-crypto-details',
  },
  {
    title: 'Top 7 NFT usecase',
    author: 'First name Last name',
    category: 'purple',
    categoryText: 'video tutorial',
    status: 'green',
    statusText: 'popular',
    image: '/images/content/article-pic-4.jpg',
    image2x: '/images/content/article-pic-4@2x.jpg',
    play: true,
    url: '/learn-crypto-details',
  },
  {
    title: 'Top 7 NFT usecase',
    author: 'First name Last name',
    category: 'purple',
    categoryText: 'video tutorial',
    status: 'green',
    statusText: 'popular',
    image: '/images/content/article-pic-5.jpg',
    image2x: '/images/content/article-pic-5@2x.jpg',
    play: true,
    url: '/learn-crypto-details',
  },
  {
    title: 'Top 7 NFT usecase',
    author: 'First name Last name',
    category: 'blue',
    categoryText: 'featured',
    status: 'green',
    statusText: 'popular',
    image: '/images/content/article-pic-6.jpg',
    image2x: '/images/content/article-pic-6@2x.jpg',
    url: '/learn-crypto-details',
  },
]
