import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Profile.module.sass'
import { NavLink, useLocation } from 'react-router-dom'
import Icon from '../Icon'
import Breadcrumbs from '../Breadcrumbs'
import { useDispatch, useSelector } from 'react-redux'
import { uploadProfileAvatar } from 'store/actions'

const navigation = [
  {
    title: 'Profile',
    icon: 'user',
    url: '/profile',
  },
  {
    title: 'Client Activities',
    icon: 'eye',
    url: '/client-activities',
  },
  {
    title: 'Referrals',
    icon: 'share',
    url: '/referrals',
    separator: true,
  },
  {
    title: 'KYC',
    icon: 'share',
    url: '/kyc',
    separator: true,
  },
  // {
  //   title: 'API keys',
  //   icon: 'keyboard',
  //   url: '/api-keys',
  //   separator: true,
  // },
  {
    title: 'Sessions & login history',
    icon: 'laptop',
    url: '/sessions-and-login-history',
  },
  {
    title: '2FA',
    icon: 'barcode',
    url: '/2fa',
  },
  // {
  //   title: 'Change password',
  //   icon: 'lock',
  //   url: '/change-password',
  // },
]

const Profile = ({ title, breadcrumbs, children }) => {
  const { pathname } = useLocation()
  const [visible, setVisible] = useState(false)
  const [avatarImage, setAvatarImage] = useState({})
  const [isImageUploading, setIsImageUploading] = useState(false)
  const dispatch = useDispatch()
  const activeLink = navigation.find((x) => pathname.includes(x.url))
  const { clientData } = useSelector((state) => state?.Profile)

  const handleSubmit = (values) => {
    dispatch(uploadProfileAvatar(values))
  }

  if (!activeLink) {
    return null
  }
  
  function handleUploadedImage(e){
    let image = e?.target?.files?.[0]
    if (image){
      // Object.assign(image, {
      //     preview: URL.createObjectURL(image),
      // })
      // setAvatarImage(image)
      handleSubmit(image)
    }
  }

  return (
    <div className={styles.profile}>
      <div className={styles.head}>
        <div className={cn('container', styles.container)}>
          <h2 className={cn('h2', styles.title)}>{title}</h2>
          <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={cn('container', styles.container)}>
          <div className={styles.sidebar}>
            <div
              className={cn(styles.dropdown, {
                [styles.active]: visible,
              })}
            >
              <div className={styles.top} onClick={() => setVisible(!visible)}>
                <Icon name={activeLink?.icon} size="24" />
                {activeLink.title}
              </div>
              <div className={styles.group}>
                <div className={styles.menu}>
                  <div className={styles.imgWrapper}>
                    <div className={cn(styles.headImg, isImageUploading && styles.headImgLoading)}>
                      <input type='file' id='imageFile' className={styles.headImgInput} onChange={(e) => handleUploadedImage(e)}/>
                      <label htmlFor='imageFile' className={styles.headImgUpdateIconWrapper}>
                        <img src={clientData?.profileAvatar ? clientData?.profileAvatar : "/images/content/avatar-user.jpg"} alt="Avatar" />
                        <div className={styles.headImgUpdateIcon}>
                          <Icon name={'plus'} size="15" />
                        </div>
                      </label>

                    </div>
                    <div className={styles.details}>
                      <div className={styles.user}>
                        {clientData?.firstName} {clientData?.lastName}
                      </div>
                      <div className={styles.email}>{clientData?.email}</div>
                    </div>
                  </div>
                  {navigation.map((item, index) => (
                    <NavLink
                      className={cn(styles.link, {
                        [styles.separator]: item.separator,
                        [styles.active]: pathname === item.url,
                      })}
                      key={index}
                      to={item.url}
                      onClick={() => setVisible(false)}
                    >
                      <Icon name={item.icon} size="24" />
                      {item.title}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.wrapper}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Profile
