// crypto
export const REGISTER_LIVE_USER = 'REGISTER_LIVE_USER'
export const REGISTER_LIVE_USER_SUCCESSFUL = 'REGISTER_LIVE_USER_SUCCESSFUL'
export const REGISTER_LIVE_USER_FAILED = 'REGISTER_LIVE_USER_FAILED'
export const REGISTER_DEMO_USER = 'REGISTER_DEMO_USER'
export const REGISTER_DEMO_USER_SUCCESSFUL = 'REGISTER_DEMO_USER_SUCCESSFUL'
export const REGISTER_DEMO_USER_FAILED = 'REGISTER_DEMO_USER_FAILED'

// forex live
export const REGISTER_FOREX_LIVE_USER_REQUESTED =
  'REGISTER_FOREX_LIVE_USER_REQUESTED'
export const REGISTER_FOREX_LIVE_USER_SUCCESS =
  'REGISTER_FOREX_LIVE_USER_SUCCESS'
export const REGISTER_FOREX_LIVE_USER_FAIL = 'REGISTER_FOREX_LIVE_USER_FAIL'

// forex demo
export const REGISTER_FOREX_DEMO_USER_REQUESTED =
  'REGISTER_FOREX_DEMO_USER_REQUESTED'
export const REGISTER_FOREX_DEMO_USER_SUCCESS =
  'REGISTER_FOREX_DEMO_USER_SUCCESS'
export const REGISTER_FOREX_DEMO_USER_FAIL = 'REGISTER_FOREX_DEMO_USER_FAIL'

// forex ib
export const REGISTER_FOREX_IB_USER_REQUESTED =
  'REGISTER_FOREX_IB_USER_REQUESTED'
export const REGISTER_FOREX_IB_USER_SUCCESS = 'REGISTER_FOREX_IB_USER_SUCCESS'
export const REGISTER_FOREX_IB_USER_FAIL = 'REGISTER_FOREX_IB_USER_FAIL'
