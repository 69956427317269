import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import styles from './Form.module.sass'
import { useTranslation } from 'react-i18next'
import FromikTextInput from 'components/FormComponents/TextInput'
import { useFormik } from 'formik'
import { EmailLoginSchema } from './emailValidation'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser, toggleCurrentModal } from 'store/actions'
import Loader from 'components/Loader'
import { useNavigate } from 'react-router-dom'
import TwoFactorAuth from 'components/TwoFactorAuth'

const Form = (props) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, isLoginSuccess } = useSelector((state) => state.Login)
  const {  currentModal, modalData } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData
  }));
  const handleSubmit = (values) => {
    dispatch(loginUser(values))
  }

  useEffect(() => {
    if (!loading && isLoginSuccess && localStorage.getItem('authUser')) {
      navigate('/dashboard')
    }
  }, [loading, isLoginSuccess])

  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: EmailLoginSchema,
    onSubmit: handleSubmit,
  })

  return (
    <>
      <form className={styles.form} onSubmit={loginForm.handleSubmit}>
        <div className={styles.nav}>
          {/* {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
              type="button"
            >
              {x}
            </button>
          ))} */}
        </div>
        <div className={styles.container}>
          {activeIndex === 0 && (
            <FromikTextInput
              className={styles.field}
              label={t('Email')}
              name="email"
              type="email"
              placeholder={t('Email')}
              onChange={loginForm.handleChange}
              value={loginForm.values.email}
              error={loginForm.errors.email}
              touched={loginForm.touched.email}
              errClassName={styles.errMessage}
            />
          )}
          {/* {activeIndex === 1 && (
            <div className={styles.line}>
              <div className={styles.field}>
                <Dropdown
                  className={styles.dropdown}
                  label="mobile"
                  value={phone}
                  setValue={setPhone}
                  options={optionsPhone}
                />
              </div>
              <TextInput
                className={styles.field}
                name="phone"
                type="tel"
                required
              />
            </div>
          )} */}
        </div>
        <FromikTextInput
          className={styles.field}
          label="Password"
          name="password"
          type="password"
          placeholder="Password"
          onChange={loginForm.handleChange}
          value={loginForm.values.password}
          error={loginForm.errors.password}
          touched={loginForm.touched.password}
          errClassName={styles.errMessage}
        />
        <div className={styles.foot}>
          {/* TODO: Scan to login */}
          {/* <button className={styles.scan} onClick={onScan}>
            Scan to login
          </button> */}
          <Link className={styles.link} to="/forgot-password">
            {t('Forgot password?')}
          </Link>
        </div>
        <button
          type="submit"
          disabled={loading}
          className={cn('button', styles.button)}
        >
          {loading ? <Loader /> : t('Login')}
        </button>
      </form>
      {modalData && currentModal === "TwoFactorAuth" &&
      <TwoFactorAuth
        isOpen={currentModal === "TwoFactorAuth"}
        email={modalData.email}
        type={modalData.type}
        navigate={navigate}
        toggleOpen={() => {
          dispatch(toggleCurrentModal(""));
        }}>
      </TwoFactorAuth>}
    </>
  )
}

export default Form
