import React from 'react'
import Form from './Form'

const Market = ({
  amount,
  contentBuy,
  contentSell,
  limit,
  setValue,
  visible,
}) => {
  return (
    <div>
      <Form
        contentBuy={contentBuy}
        contentSell={contentSell}
        price={limit}
        visible={visible}
        setValue={setValue}
        takeProfit={true}
        stopLoss={true}
        type="market"
      />
    </div>
  )
}

export default Market
