import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Withdraw.module.sass'
import Icon from '../Icon'
import TextInput from '../TextInput'
import Checkbox from '../Checkbox'
import Successfully from './Successfully'
import Dropdown from 'components/Dropdown'
import { useSelector } from 'react-redux'
import DropdownWithId from 'components/DropdownCoins'
import DropdownCoins from 'components/DropdownCoins'
import DropdownDynamic from 'components/DropdownDynamic'
import { addWithdrawCrypto } from 'apis/withdraw'
import Loader from 'components/Loader'
import { toast } from 'react-toastify'
const languageOptions = ['sss', 'wwww']
const Withdraw = () => {
  const [save, setSave] = useState(true)
  const [responseData, setResponseData] = useState([])
  const [walletsOptions, setWalletsOptions] = useState([])
  const [networksOptions, setNetworksOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [visibleWithdraw, setVisibleWithdraw] = useState(true)
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false)
  const [address, setAddress] = useState('')
  const [coin, setCoin] = useState('')
  const [totalPrice, setTotalPrice] = useState(0)
  const [transactionFees, setTransactionFees] = useState(0)
  const [amount, setAmount] = useState()
  const [withdrawAmount, setWithdrawAmount] = useState()
  const [network, setNetwork] = useState('')

  const {wallets, allWallets} = useSelector((state) => state?.crypto?.wallets)

  const handleClick = () => {
    setVisibleWithdraw(false)
    setVisibleSuccessfully(true)
  }

  function validateInputs(){
    let status = true
    if (!coin) {
      toast.error(('Coin Is Required'))
      status = false
    }
    if (!network) {
      toast.error(('Network Is Required'))
      status = false
    }
    if (!withdrawAmount) {
      toast.error(('Withdraw Amount Is Required'))
      status = false
    }
    return status
  }

  const sendData = async() => {
    try {
      if (!validateInputs()) {
        return
      }
      setIsLoading(true)
      let sendedData = {
        to: address,
        walletId: coin?._id,
        amount: Number(transactionFees) + Number(withdrawAmount),
        assetId: coin?.assetId?._id,
        chainId: network?.chainId?._id,
        addressId: network?._id,
        note: "note",
        networkName: network?.chainId?.name,
        cryptoapiName: network?.chainId?.cryptoapiName,
        from: network?.address
      }
      
      let data = await addWithdrawCrypto(sendedData)
      setResponseData(data?.result)
      setIsLoading(false)
      if (data?.isSuccess) {
        setVisibleWithdraw(false)
        setVisibleSuccessfully(true)
      }
      if (data?.isError){
        toast.error(data?.message);
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }
  
  useEffect(()=>{
    setWalletsOptions(allWallets?.funding || [])
  }, [allWallets])
  useEffect(()=>{
    setAmount(coin?.amount)
  }, [coin])
  // useEffect(()=>{
  //   setAddress(network?.address)
  // }, [network])

  return (
    <>
      {visibleWithdraw && (
        <div className={styles.withdraw}>
          <div className={cn('h4', styles.title)}>
            <Icon name="arrow-left" size="32" />
            Withdraw USDT
          </div>
          <div>
            <label className={styles.label}>Coin/Wallet</label>
            <DropdownCoins
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={coin}
              setValue={setCoin}
              options={walletsOptions}
            />
            {/* {selectCurrancyError && (
              <p className="small text-danger "> {props.t("Please Select Currency")}</p>
            )} */}
          </div>
          <div>
            <label className={styles.label}>Network</label>
            <DropdownDynamic
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={network}
              setValue={setNetwork}
              options={coin?.networks}
              uniqueName={'chainId?.name'}
              uniqueId={'chainId?._id'}
            />
          </div>
          
          <TextInput
            className={styles.field}
            label="address"
            name="address"
            type="text"
            note="Do not send Tether USD unless you are certain the destination supports
        TRC-20 transactions. If it does not, you could permanently lose access
        to your coins."
            onChange={(e)=>setAddress(e?.target?.value)}
            value={address}
            required
          />
          {/* <Checkbox
            className={styles.checkbox}
            value={save}
            onChange={() => setSave(!save)}
            content="Save address for next time"
          /> */}
          <div className={styles.wrap}>
            <div className={styles.category}>
              Available <br></br>balance
            </div>
            <div className={styles.details}>
              <div className={styles.currency}>{amount} USDT</div>
              {/* <div className={styles.price}>$10,095.35</div> */}
            </div>
          </div>
          <div className={styles.box}>
            <TextInput
              className={styles.field}
              label="Amount to withdraw"
              name="amount"
              type="number"
              note="$1,000,000.00 daily withdrawal limit remaining."
              onChange={(e)=>setWithdrawAmount(e?.target?.value)}
              value={withdrawAmount}
              required
            />
            <button className={cn('button-stroke button-small', styles.button)} onClick={()=>{setWithdrawAmount(amount || 0)}}>
              Max amount
            </button>
          </div>
          <TextInput
            className={styles.field}
            label="transaction fee"
            name="transaction"
            type="number"
            placeholder="0.00000000 USDT"
            required
            onChange={(e)=>setTransactionFees(e?.target?.value)}
            value={transactionFees}
            disabled
          />
          <TextInput
            className={styles.field}
            label="total"
            name="total"
            type="number"
            placeholder="0.00000000 USDT"
            required
            // onChange={(e)=>setTotalPrice(e?.target?.value)}
            value={Number(transactionFees) + Number(withdrawAmount)}
            disabled
          />
          {/* <button
            className={cn('button', styles.button)}
            onClick={() => handleClick()}
          >
            Withdraw
          </button> */}
          <button
            type="submit"
            disabled={isLoading}
            className={cn('button', styles.button)}
            onClick={sendData}
          >
            {isLoading ? <Loader /> : 'Withdraw' }
          </button>
        </div>
      )}
      {visibleSuccessfully && <Successfully responseData={responseData} handleClick={handleClick}/>}
    </>
  )
}

export default Withdraw
