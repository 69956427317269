import { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Form.module.sass'
import Icon from '../../../components/Icon'
import Checkbox from '../../../components/Checkbox'
import { countries } from 'common/countries'
import { FormikProvider, useFormik } from 'formik'
import FromikTextInput from 'components/FormComponents/TextInput'
import { SignupValidationSchema } from './signupValidationSchema'
import CountriesDropdown from 'components/CountriesDropdown'
import { registerLiveUser } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Loader from 'components/Loader'
import EmailPinField from './EmailPinField'

const Form = ({ goNext }) => {
  const [policy, setPolicy] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, isRegisterSuccess } = useSelector((state) => state.Account) || {}
  const [isPinVerified, setIsPinVerified] = useState(false);

  const handleSubmit = (values) => {
    dispatch(registerLiveUser(values))
  }
  useEffect(() => {
    if (!loading && isRegisterSuccess && localStorage.getItem('authUser')) {
      navigate('/dashboard')
    }
  }, [loading, isRegisterSuccess])

  const signinForm = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      country: countries[0].phone,
      phone: '',
      email: '',
      password: '',
      emailPin: '',
      referral: '',
    },
    validationSchema: SignupValidationSchema,
    onSubmit: handleSubmit,
  })

  const handleChangeCountryDropdown = (country) => {
    signinForm.setFieldValue('country', country.phone)
  }

  return (
    <FormikProvider value={signinForm}>
      <form className={styles.form} onSubmit={signinForm.handleSubmit}>
        <div className={styles.top}>
          <h3 className={cn('h3', styles.title)}>Sign up</h3>
          {/* <div className={styles.info}>Use Your OpenID to Sign up</div>
          <div className={styles.btns}>
            <button className={cn('button', styles.button)}>
              <Icon name="google" size="16" />
              <span>Google</span>
            </button>
            <button className={cn('button-black', styles.button)}>
              <Icon name="apple" size="16" />
              <span>Apple</span>
            </button>
          </div> */}
        </div>
        {/* <div className={styles.info}>Or continue with email</div> */}
        <div className={styles.container}>
          <FromikTextInput
            value={signinForm.values.firstName}
            error={signinForm.errors.firstName}
            touched={signinForm.touched.firstName}
            className={styles.field}
            errClassName={styles.errMessage}
            label="First Name"
            name="firstName"
            type="text"
            placeholder="First name"
            onChange={signinForm.handleChange}
          />
          <FromikTextInput
            value={signinForm.values.lastName}
            error={signinForm.errors.lastName}
            touched={signinForm.touched.lastName}
            errClassName={styles.errMessage}
            onChange={signinForm.handleChange}
            className={styles.field}
            label="Last Name"
            name="lastName"
            type="text"
            placeholder="Last name"
          />
          <div className={styles.countryPhone}>
            <CountriesDropdown
              label="Country code"
              value={signinForm.values}
              setValue={handleChangeCountryDropdown}
              options={countries}
              error={signinForm.errors.country}
              touched={signinForm.touched.country}
              errClassName={styles.errMessage}
            />
            <div className={styles.phoneNumber}>
              <FromikTextInput
                value={signinForm.values.phone}
                error={signinForm.errors.phone}
                touched={signinForm.touched.phone}
                errClassName={styles.errMessage}
                onChange={signinForm.handleChange}
                className={styles.field}
                label="Phone"
                name="phone"
                type="text"
                placeholder="Phone number"
              />
            </div>
          </div>
          <FromikTextInput
            value={signinForm.values.email}
            error={signinForm.errors.email}
            errClassName={styles.errMessage}
            touched={signinForm.touched.email}
            onChange={signinForm.handleChange}
            className={styles.field}
            label="email"
            name="email"
            type="email"
            placeholder="Email address"
          />
          <FromikTextInput
            value={signinForm.values.password}
            error={signinForm.errors.password}
            touched={signinForm.touched.password}
            onChange={signinForm.handleChange}
            className={styles.field}
            errClassName={styles.errMessage}
            label="Password"
            name="password"
            type="password"
            placeholder="Password"
            view
          />
          {/* <div className={styles.sendPinContainer}>
            <FromikTextInput
              value={signinForm.values.pin}
              error={signinForm.errors.pin}
              touched={signinForm.touched.pin}
              onChange={signinForm.handleChange}
              className={styles.field}
              name="pin"
              type="number"
              placeholder="Enter pin"
            />
            <button
              className={cn('button', styles.subActionButton)}
              onClick={(e) => e.preventDefault()}
              // onClick={goNext}
            >
              Send pin
            </button>
          </div> */}
          
          <EmailPinField 
            values={signinForm.values}
            errors={signinForm.errors}
            isPinVerified={isPinVerified}
            setFieldTouched={signinForm.setFieldTouched} 
            setFieldValue={signinForm.setFieldValue}
            setIsPinVerified={setIsPinVerified}
            setFieldError={signinForm.setFieldError}
            touched={signinForm.touched}
          ></EmailPinField>

          <div className={styles.sendPinContainer}>
            <FromikTextInput
              value={signinForm.values.referral}
              error={signinForm.errors.referral}
              touched={signinForm.touched.referral}
              onChange={signinForm.handleChange}
              className={styles.field}
              name="referral"
              type="text"
              placeholder="Enter referral code (Optional)"
            />
            <button
              className={cn('button', styles.subActionButton)}
              onClick={(e) => e.preventDefault()}
              // onClick={goNext}
            >
              Check
            </button>
          </div>
          <Checkbox
            className={styles.checkbox}
            value={policy}
            onChange={() => setPolicy(!policy)}
            content="<span style='display:contents'>By signing up I agree that I’m 18 years of age or older, to the <a href='/#' target='_blank' rel='noopener noreferrer'>User Agreements</a>, <a href='/#' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>, <a href='/#' target='_blank' rel='noopener noreferrer'>Cookie Policy</a>, <a href='/#' target='_blank' rel='noopener noreferrer'>E-Sign Consent</a>.<span>"
          />
        </div>
        {/* <button
          type="submit"
          className={cn('button', styles.button)}
          // onClick={goNext}
        >
          Sign up
        </button> */}
        <button
          type="submit"
          // disabled={loading}
          className={cn('button', styles.button)}
        >
          {loading ? <Loader /> : 'Sign up' }
        </button>
      </form>
    </FormikProvider>
  )
}

export default Form
