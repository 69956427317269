// DatafeedConfiguration implementation
const configurationData = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: [
    '1',
    '5',
    '15',
    '30',
    '1H',
    '4H',
    '1D' /*, "1W", "1M"*/,
  ],
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  // exchanges: [
  // 	{
  // 		value: "Exinitic",
  // 		name: "Exinitic",
  // 		desc: "Exinitic"
  // 	},
  // ],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [
    {
      name: 'crypto',
      value: 'crypto',
    },
  ],
}
const dummySymbols = [
  {
    symbol: 'ETH/USDT',
    full_name: 'ETHER vs USDT',
    description: '',
    exchange: 'Okx',
    type: 'crypto',
    pricescale: 100,
    minmov: 1,
  },
  {
    symbol: 'EUR/USD',
    full_name: 'EURO VS USD',
    description: '',
    exchange: 'CME',
    type: 'fx',
  },
  {
    symbol: 'GBP/USD',
    full_name: 'GBP VS USD',
    description: '',
    exchange: 'CME',
    type: 'fx',
  },
  {
    symbol: 'AUD/USD',
    full_name: 'AUD VS USD',
    description: '',
    exchange: 'CME',
    type: 'fx',
  },
]
function onReady(callback) {
  console.log('[onReady]: Method call')
  setTimeout(() => callback(configurationData))
}
async function searchSymbols(
  userInput,
  exchange,
  symbolType,
  onResultReadyCallback,
) {
  console.log('[searchSymbols]: Method call')
  // const symbols = await getAllSymbols();
  const symbols = dummySymbols
  const newSymbols = symbols.filter((symbol) => {
    const isExchangeValid = exchange === '' || symbol.exchange === exchange
    const isFullSymbolContainsInput =
      symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1
    return isExchangeValid && isFullSymbolContainsInput
  })
  onResultReadyCallback(newSymbols)
}
async function resolveSymbol(
  symbolName,
  onSymbolResolvedCallback,
  onResolveErrorCallback,
  extension,
) {
  console.log('[resolveSymbol]: Method call', symbolName)
  if (!symbolName) {
    onResolveErrorCallback('Cannot resolve symbol')
    return
  }
  const symbolInfo = {
    ticker: symbolName,
    name: symbolName,
    description: '',
    type: 'crypto',
    session: '24x7',
    timezone: 'Etc/UTC',
    exchange: 'Okx',
    minmov: 1,
    pricescale: 100000,
    has_intraday: true,
    visible_plots_set: true,
    has_weekly_and_monthly: false,
    supported_resolutions: configurationData.supported_resolutions,
    volume_precision: 2,
    //data_status: "streaming",
    data_status: 'streaming',
  }
  console.log('[resolveSymbol]: Symbol resolved', symbolName)
  onSymbolResolvedCallback(symbolInfo)
}
async function getBars(
  symbolInfo,
  resolution,
  periodParams,
  onHistoryCallback,
  onErrorCallback,
) {
  // @ts-ignore
  // eslint-disable-next-line no-undef
  const bars = normalizedData
  console.log({ bars })
  const { from, to, firstDataRequest } = periodParams
  console.log('[getBars]: Method call', symbolInfo, resolution, from, to)
  try {
    //priceData;
    console.log('[getBars]: bars=> ', { bars })
    if (!bars) onHistoryCallback([], { noData: true })
    else {
      console.log(`[getBars]: returned ${bars.length} bar(s)`)
      onHistoryCallback(bars, { noData: false })
    }
  } catch (error) {
    console.log('[getBars]: Get error', error)
    onErrorCallback(error)
  }
}
function subscribeBars(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback,
) {
  console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID)
}
function unsubscribeBars(subscriberUID) {
  console.log(
    '[unsubscribeBars]: Method call with subscriberUID:',
    subscriberUID,
  )
}
export default {
  onReady: onReady,
  searchSymbols: searchSymbols,
  resolveSymbol: resolveSymbol,
  getBars: getBars,
  subscribeBars: subscribeBars,
  unsubscribeBars: unsubscribeBars,
}
