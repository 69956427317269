import React from 'react'
import cn from 'classnames'
import styles from './Steps.module.sass'

const items = [
  {
    title: '1. Consultation',
    image2x: '/images/content/step-1@2x.png',
    image: '/images/content/step-1.png',
    content:
      'Engage with our experts to outline your crypto exchange vision. We assess your needs to tailor a comprehensive strategy for your platform.',
  },
  {
    title: '2. Customization',
    image2x: '/images/content/step-2@2x.png',
    image: '/images/content/step-2.png',
    content:
      'With FiniticX, customize a robust and scalable crypto exchange platform. Choose from a range of features like Spot, Futures, and Options Trading Systems to fit your market niche.',
  },
  {
    title: '3. Integration',
    image2x: '/images/content/step-3@2x.png',
    image: '/images/content/step-3.png',
    content:
      'Our team seamlessly integrates essential services such as liquidity solutions, risk management tools, and KYC/AML compliance into your platform for smooth operations from day one.',
  },
  {
    title: '4. Launch & Growth',
    image2x: '/images/content/step-4@2x.png',
    image: '/images/content/step-4.png',
    content:
      "Launch your exchange with FiniticX's full support. Our marketing and operational expertise ensures a strong market entry and sustained growth for your platform.",
  },
]

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn('section', styles.section)} ref={scrollToRef}>
      <div className={cn('container', styles.container)}>
        <div className={styles.head}>
          <h2 className={cn('h2', styles.title)}>Our Process: </h2>
          <div className={styles.info}>
            Simplifying Your Path to Crypto Exchange Success
          </div>
        </div>
        <div className={cn('custom-list', styles.list)}>
          {items.map((x, index) => (
            <div className={cn('custom-item', styles.item)} key={index}>
              <div className={cn('custom-preview', styles.preview)}>
                <img
                  srcSet={`${x.image2x} 2x`}
                  src={x.image}
                  alt={`Step ${index + 1}`}
                />
              </div>
              <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Steps
