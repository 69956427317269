import React, { useContext, useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Balance.module.sass'
import Dropdown from '../../../components/Dropdown'
import Icon from '../../../components/Icon'
import SocketContext from 'context/context'
import { usePricingMonitor } from 'hooks/usePricingMonitor'

const sorting = [
  {
    color1: '#FF6838',
    color2: '#B1B5C3',
    color3: '#58BD7D',
  },
  {
    color1: '#B1B5C3',
    color2: '#B1B5C3',
    color3: '#58BD7D',
  },
  {
    color1: '#FF6838',
    color2: '#B1B5C3',
    color3: '#B1B5C3',
  },
]

const counterOptions = ['10']

const Balance = (props) => {
  const [counter, setCounter] = useState(counterOptions[0])
  const { state } = useContext(SocketContext)
  const { orderBooks } = state
  const { selectedMarket } = props
  const [selectedOrderBooks, setSelectedOrderBooks] = useState(null)

  useEffect(() => {
    const books = orderBooks.find(
      (market) => market.instanceId === selectedMarket,
    )
    setSelectedOrderBooks(books)
  }, [selectedMarket, orderBooks])

  const pricingData = usePricingMonitor(selectedMarket)


  return (
    <div className={styles.balance}>
      <div className={styles.head}>
        {/* <div className={styles.sorting}>
          {sorting.map((x, index) => (
            <button
              className={cn(styles.direction, {
                [styles.active]: index < 1,
              })}
              key={index}
            >
              <span style={{ backgroundColor: x.color1 }}></span>
              <span style={{ backgroundColor: x.color2 }}></span>
              <span style={{ backgroundColor: x.color3 }}></span>
            </button>
          ))}
        </div> */}
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          classDropdownArrow={styles.dropdownArrow}
          classDropdownBody={styles.dropdownBody}
          classDropdownOption={styles.dropdownOption}
          value={counter}
          setValue={setCounter}
          options={counterOptions}
        />
      </div>
      <div className={styles.top}>
        <div className={styles.price}>
          Price ({selectedOrderBooks?.pairName?.split('/')[1]})
        </div>
        <div className={styles.amount}>
          Amounts ({selectedOrderBooks?.pairName?.split('/')[0]})
        </div>
      </div>
      <div className={styles.list}>
        {selectedOrderBooks &&
          selectedOrderBooks.asks
            .slice(0, parseInt(counterOptions) / 2)
            .reverse()
            .map((x, index) => (
              <div
                className={cn(styles.statistics, styles.negative)}
                key={index}
              >
                <div className={styles.currency}>{x[0]}</div>
                <div className={styles.money}>{x[1]}</div>
              </div>
            ))}
        {selectedOrderBooks?.asks && selectedOrderBooks?.bids && pricingData && (
          <div
            className={cn(
              styles.statistics,
              styles.marketdata,
              parseFloat(pricingData?.percentage?.$numberDecimal ?? pricingData.percentage) >= 0 ? styles.positive : styles.negative,
            )}
          >
            <div className={styles.currency}>
              {pricingData?.currentPrice?.$numberDecimal ?? pricingData?.currentPrice} <Icon name={
                parseFloat( pricingData?.percentage?.$numberDecimal ?? pricingData.percentage) >= 0 ? 'arrow-top' : 'arrow-bottom'
              } size="35" />
            </div>
          </div>
        )}

        {selectedOrderBooks &&
          selectedOrderBooks.bids
            .slice(0, parseInt(counterOptions) / 2)
            .reverse()
            .map((x, index) => (
              <div
                className={cn(styles.statistics, styles.positive)}
                key={index}
              >
                <div className={styles.currency}>{x[0]}</div>
                <Icon name="-top" size="16" />
                <div className={styles.money}>{x[1]}</div>
              </div>
            ))}
      </div>
    </div>
  )
}

export default Balance
