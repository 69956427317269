import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Dropdown.module.sass'
import OutsideClickHandler from 'react-outside-click-handler'
import { NavLink, useLocation } from 'react-router-dom'
import Icon from '../../Icon'
import { useTranslation } from 'react-i18next'

const Dropdown = ({ className, item, setValue }) => {
  const [visible, setVisible] = useState(false)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const handleClick = () => {
    setVisible(false)
    setValue(false)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(className, styles.dropdown, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          {t(item.title)}
          <Icon name="arrow-down" size="16" />
        </button>
        <div className={styles.body}>
          {item.dropdown.map((x, index) => (
            <NavLink
              className={cn(styles.link, {
                [styles.active]: pathname === x.url,
              })}
              to={x.url}
              key={index}
              onClick={() => handleClick()}
            >
              {x.isIcon ? (
                <img
                  className={styles.icon}
                  src={`/images/content/crypto/${x.icon}.svg`}
                />
              ) : (
                <Icon name={x.icon} size="20" />
              )}
              {t(x.title)}
            </NavLink>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default Dropdown
