import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './WalletOverview.module.sass'
import Wallet from '../../components/Wallet'
import Icon from '../../components/Icon'
import Dropdown from '../../components/Dropdown'
import AccountBalances from './AccountBalances'
import AssetBalances from './AssetBalances'
import Integrations from './Integrations'
import { useTranslation } from 'react-i18next'
import { fetchProfile, fetchWallets } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'

const optionsCurrency = ['USD']

const WalletOverview = () => {
  const [search, setSearch] = useState('')
  const [currency, setCurrency] = useState(optionsCurrency[0])
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { wallets, allWallets, loading } = useSelector((state) => state?.walletReducer)
  const [activeWallet, setActiveWallet] = useState('')
  const handleSubmit = (e) => {
    alert()
  }

  const loadWallets = () => {
    dispatch(fetchWallets())
  }
  useEffect(() => {
    loadWallets({})
  }, [])

  return (
    <Wallet>
      <div className={styles.top}>
        <div className={styles.line}>
          <h4 className={cn('h4', styles.title)}>Overview</h4>
          <div className={styles.wrap}>
            {/* TODO: Wallet Search */}
            {/* <form
              className={styles.form}
              action=""
              onSubmit={() => handleSubmit()}
            >
              <input
                className={styles.input}
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                name="search"
                placeholder={t('Search')}
                required
              />
              <button className={styles.result}>
                <Icon name="search" size="20" />
              </button>
            </form> */}
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={currency}
              setValue={setCurrency}
              options={optionsCurrency}
            />
            <button className={cn('button-black button-small', styles.button)}>
              {t('Show balance')}
            </button>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.info}>{t('Total balance')}</div>
          <div className={styles.currency}>
            <div className={styles.number}>
              {wallets
                ?.filter((x) => x.isCrypto)
                .reduce((a, b) => a + b.btcValue, 0)
                ?.toFixed(6)}
            </div>
            <div className={cn('category-green', styles.category)}>USDT</div>
          </div>
          <div className={styles.price}>
            $
            {wallets
              ?.filter((x) => x.isCrypto)
              .reduce((a, b) => a + b.usdValue, 0)
              ?.toFixed(6)}
          </div>
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.head}>{t('Account Balances')}</div>
          <div className={styles.body}>
            <AccountBalances 
              setActiveWallet={setActiveWallet} 
              activeWallet={activeWallet} 
              allWallets={allWallets}
            />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.head}>{t('Account Balances')}</div>
          <div className={styles.body}>
            <AssetBalances 
              setActiveWallet={setActiveWallet} 
              activeWallet={activeWallet}
            />
          </div>
        </div>
        {/* TODO: API Integration  */}
        {/* <div className={styles.item}>
          <div className={styles.head}>{t('Integrations')}</div>
          <div className={styles.body}>
            <Integrations />
          </div>
        </div> */}
      </div>
    </Wallet>
  )
}

export default WalletOverview
