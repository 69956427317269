import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Table/Table.module.sass'
import cn from 'classnames'
import Dropdown from 'components/Dropdown'
import useDarkMode from 'use-dark-mode'
import TextInput from 'components/TextInput'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWallets } from 'store/actions'
import { getConvertsStart } from 'store/crypto/history/actions'

const Status = [
  'open',
  'closed',
  'rejected',
]
 
const Converts = () => {

 const [assetWalletFrom, setAssetWalletFrom ] =  useState("")
 const [assetWalletTo, setAssetWalletTo ] =  useState("")
 const [ status, setStatus] = useState ("")
 const [dateFrom, setDateFrom] = useState("")
 const [dateTo, setDateTo] = useState("")
 const [ limit, setLimit ] = useState(5)


    const darkMode = useDarkMode(false)
    const customStyles = {
    width:'100%', 
     backgroundColor:darkMode ? '#23262F' : '#E6E8EC',
    height:'auto',
    borderRadius:'20px',
    marginBottom:'60px',
    paddingTop:'1px'
}
const dispatch = useDispatch();

const { wallets } = useSelector((state) => state?.walletReducer)
const { converts } = useSelector((state) => state?.crypto?.historyReducer)

useEffect(() => {
  dispatch(fetchWallets());
}, [dispatch]);

const [filteredData, setFilteredData] = useState([]);
useEffect(() => {
  const filtered = wallets?.map(item => item?.assetId?.symbol);
  setFilteredData(filtered);
}, [ wallets]); 

// handle api 
useEffect(() => {

  const requestData = {
    limit: limit,
    page: 1,
    ...(status !== undefined && status !== "" && { status }),
    ...(dateFrom !== undefined && dateFrom !== "" && { fromDate: dateFrom }),
    ...(dateTo !== undefined && dateTo !== "" && { toDate: dateTo }),
    ...(assetWalletFrom !== undefined && assetWalletFrom !== "" && { toAsset: assetWalletFrom }),
    ...(assetWalletTo !== undefined && assetWalletTo !== "" && { fromAsset: assetWalletTo }),
  
  };

  
  dispatch(getConvertsStart(requestData));


  }, [ limit, dateTo, dateFrom, assetWalletTo, assetWalletFrom, status  ]);


  return (
<>
<div style={customStyles}>
<h4 style={{ marginTop:'30px', marginLeft:'10px' }}>Filter by</h4>
<div className={styles.card}>

<div className={styles.field}   style={{ width:'31%' }}>
              <Dropdown   
                className={styles.dropdown}
                label="From Asset"
                value={assetWalletFrom}
                setValue={setAssetWalletFrom}
                options={filteredData}
              />
            </div>
            <div className={styles.field}  style={{ width:'31%' }}>
              <Dropdown
                className={styles.dropdown}
                label="To Asset"
                value={assetWalletTo}
                setValue={setAssetWalletTo}
                options={filteredData}
              />
            </div>


            <div className={styles.field}  style={{ width:'31%' }}>
              <Dropdown
                className={styles.dropdown}
                label="Status"
                value={status}
                setValue={setStatus}
                options={Status}
              />
            </div>   

            <div className={styles.field}  style={{ width:'48%' }}>
            <TextInput
              className={styles.field}
              label="From Date"
              onChange={(e)=>setDateFrom(e.target.value)}
              value={dateFrom}
              name="dateFrom"
              type="date"
              required
                />
                </div>
                <div className={styles.field}  style={{ width:'48%' }}>
            <TextInput
              className={styles.field}
              label="To Date"
              onChange={(e)=>setDateTo(e.target.value)}
              value={dateTo}
              name="dateTo"
              type="date"
              required
                />
                </div>
</div>
</div>

<div className={styles.table}>
      <div className={styles.row}>
      <div className={styles.col}>
          <div className="sorting">From asset</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">To asset</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Date</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">From Amount</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">To Amount</div>
        </div>
        <div className={styles.col}>
          <div className="sorting">Status</div>
        </div>
      </div>
      {converts?.map((x, index) => (
        <Link className={styles.row} key={x._id} to='/buy-crypto'>
 
 <div className={styles.col}>
                <div className={styles.asset}>
                  <div className={styles.icon}>
                    {/* if not crypto show white background */}
                    <img
                      src={`/images/content/crypto/${x.fromAsset}.svg`}
                      alt={x.asset}
                      style={
                        x.isCrypto
                          ? {
                              backgroundColor: '#fff',
                            }
                          : {}
                      }
                    />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.info}>{x.fromAsset}</div>
                  </div>
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.asset}>
                  <div className={styles.icon}>
                    {/* if not crypto show white background */}
                    <img
                      src={`/images/content/crypto/${x.toAsset}.svg`}
                      alt={x.asset}
                      style={
                        x.isCrypto
                          ? {
                              backgroundColor: '#fff',
                            }
                          : {}
                      }
                    />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.info}>{x.toAsset}</div>
                  </div>
                </div>
              </div>
          <div className={styles.col}>
            {x?.createdAt}
          </div>
          <div className={styles.col}>
            {x?.amount}
          </div>
          <div className={styles.col}>
            {x?.toAmount}
          </div>
          <div className={styles.col}>
          {x?.status}
          </div>
        </Link>
      ))}

    </div>

    <div style={{marginTop:'10px'}}>
<button
  style={{width:'100%'}}
  className={cn(
            'button-stroke button-small',
            // { [styles.active]: visible },
            styles.button,
          )}
          // eslint-disable-next-line react/jsx-indent-props
  // eslint-disable-next-line react/jsx-no-duplicate-props
  onClick={() => {
              setLimit(preValue => preValue + 5)
          }}
        >
          <span>Load More</span>
        </button>
</div>
</>
  )
}

export default Converts
